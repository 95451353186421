import React, { useEffect, useState } from 'react'
import { htmlParseOptions } from '../../../constants';
import { useLastUrlSegment } from '../../../hooks/useQuery';
import useStories from '../../../hooks/useStories';
import Card from '../../../parts/Card';
import CardContentWrapper from '../../global/CardContentWrapper';
import CardHeaderWithBottomBorder from '../../global/CardHeaderWithBottomBorder';
import { EllipsisLoader } from '../../global/MyLoaders';
import parse from "html-react-parser";
import MyModal from '../../global/MyModal';
import EditStory from './EditStory';


const View = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }
  const id = useLastUrlSegment();
  const { loading, getselectedStory, selectedStory } = useStories();
  useEffect(() => {
    return getselectedStory(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return loading ? (<EllipsisLoader />) : (
    <>
      <MyModal modalOpen={isOpen} setModalOpen={setIsOpen} id={id} title="Edit story">
        <EditStory closeModal={handleModalClick} id={id} />
      </MyModal>
      <div className='py-4 row align-items-end'>
        <button className='btn btn-info ml-auto mr-3' onClick={() => setIsOpen(true)}> <i className='fa fa-edit'></i> Edit</button>
      </div>
      <div className='row m-0'>
        <div className='col-xs-12 mb-30'>
          <Card>
            <>
              <CardHeaderWithBottomBorder title={selectedStory?.title} />
              <CardContentWrapper>
                <div className='row m-0 flex-column'>
                  <div className="col-xs-12">
                    <p className='mb-3 font-bold'>Story by {selectedStory?.name},{' '} {selectedStory?.age},{" "} {selectedStory?.gender} from {selectedStory?.address}</p>
                  </div>
                  <div className='col-xs-12'>
                    {parse(selectedStory?.story, htmlParseOptions)}
                  </div>
                </div>
              </CardContentWrapper>

            </>
          </Card>
        </div>
      </div>
    </>
  )
}

export default View