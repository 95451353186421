import React from "react";
import ListBalancesheet from "../../components/accounting/balanceSheet/ListBalancesheet";
import DashboardWrapper from "../../components/DashboardWrapper";
import Breadcrumbs from "../../parts/Breadcrumbs";
import NewBalanceSheet from "../../components/accounting/balanceSheet/NewBalanceSheet";

const Balancesheet = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title="Balance sheet" />
        {/* body */}
        {/* <ListBalancesheet /> */}
        <NewBalanceSheet />
      </>
    </DashboardWrapper>
  );
};

export default Balancesheet;
