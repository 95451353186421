import React from 'react'
import ListStock from '../../components/stocks/stock/ListStock'
import StocksWrapper from '../../components/StocksWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Stocks = () => {
  return (
    <StocksWrapper>
      <>
        <Breadcrumbs title='Stock Management' />
        <ListStock />
      </>
    </StocksWrapper>
  )
}

export default Stocks