import React from 'react'
import ClinicWrapper from '../../components/ClinicWrapper'
import ListPatients from '../../components/doctor/patients/ListPatients'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const ClinicPatients = () => {
  return (
    <ClinicWrapper>
      <>
        <Breadcrumbs title='Clinic Patients' parents={[{ link: ROUTES.CLINICS, title: 'Clinics' }]} />
        <ListPatients />
      </>
    </ClinicWrapper>
  )
}

export default ClinicPatients