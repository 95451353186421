import React, { useEffect } from 'react'
import { useLastUrlSegment } from '../../../hooks/useQuery';
import useTreatments from '../../../hooks/useTreatments';
import Card from '../../../parts/Card';
import CardContentWrapper from '../../global/CardContentWrapper';
import CardHeaderWithBottomBorder from '../../global/CardHeaderWithBottomBorder';
import { EllipsisLoader } from '../../global/MyLoaders';

const ViewTreatment = () => {
  const id = useLastUrlSegment();
  const { getSelectedTreatment, selectedTreatment, loading } = useTreatments();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedTreatment(id), [id]);

  return loading ? <EllipsisLoader /> : (
    <div>
      <div className="row">
        <div className="col-sm-12 col-md-7">
          <Card>
            <>
              {/* <pre>{JSON.stringify(selectedTreatment, null, 2)}</pre> */}
              <CardHeaderWithBottomBorder title={`Treatment details for ${selectedTreatment?.patient?.first_name} ${selectedTreatment?.patient?.last_name}`} />
              <div className='row'>
                <div className='col-xs-12 col-md-6'>
                  <CardContentWrapper>
                    <div className='row'>
                      <div className="col-sm-6">
                        <h6>ARV Name</h6>
                        <p>{selectedTreatment.ARVS_name}</p>
                        <h6>Frequency</h6>
                        <p>{selectedTreatment.frequency}</p>

                      </div>

                      <div className="col-sm-6">
                        <h6>Treatment Form</h6>
                        <p>{selectedTreatment.treatment_form}</p>

                        <h6>Start Date</h6>
                        <p>{selectedTreatment.start_date}</p>
                      </div>
                    </div>
                  </CardContentWrapper>
                </div>
                <div className='col-xs-12 col-md-6'>
                  <CardContentWrapper>
                    <div className='row'>
                      <div className="col-sm-6">
                        <h6>Instructions</h6>
                        <p>{selectedTreatment.instructions}</p>
                        <h6>Has End Date</h6>
                        <p>{selectedTreatment.has_end_date ? 'Yes' : 'No'}</p>

                      </div>

                      <div className="col-sm-6">
                        <h6>Period</h6>
                        <p>{selectedTreatment.period}</p>

                        <h6>End Date</h6>
                        <p>{selectedTreatment.end_date}</p>
                      </div>
                    </div>
                  </CardContentWrapper>
                </div>
                <div className='col-xs-12 col-md-6'>
                  <CardContentWrapper>
                    <div className='row'>
                      <div className="col-sm-6">
                        <h6>Dosage</h6>
                        <p>{selectedTreatment.dosage}</p>

                      </div>
                    </div>
                  </CardContentWrapper>
                </div>
              </div>
            </>
          </Card>

        </div>
        <div className="col-sm-12 col-md-5">
          <Card>
            <>
              <CardHeaderWithBottomBorder title={`Treatment Reminders`} />
              <CardContentWrapper>
                <div className='row m-0'>
                  <div className="col-sm-12">
                    {selectedTreatment?.medication_time?.map(({ id, reminder }: any) => (
                      <div key={id} className="row align-items-center">
                        <h6 className='mr-4'>Reminder Time</h6>
                        <p className='border-bottom'>{reminder}</p>
                      </div>

                    ))}

                  </div>
                </div>
              </CardContentWrapper>
            </>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default ViewTreatment