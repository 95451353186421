import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { doCreate } from '../../../../api'
import { employeePersonalDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../../constants'
import { formatErrorMessage } from '../../../../helpers'
import useStaff from '../../../../hooks/useStaff'
import Validator from '../../../../utils/Validator'
import Input from '../../../global/Input'
import { EllipsisLoader } from '../../../global/MyLoaders'
import RadioButton from '../../../global/RadioButton'
import Textarea from '../../../global/Textarea'

interface IEditPersonalDetailsProps {
  onCancel: () => void
  id: number
}

const EditPersonalDetails = ({ onCancel, id }: IEditPersonalDetailsProps) => {
  const [personalDetails, setPersonalDetails] = useState(employeePersonalDefaultData);
  const [convicted, setConvicated] = useState("No");
  const [disabled, setDisabled] = useState("No");
  const [discharged, setDischarged] = useState("No");
  const [hospitalized, setHospitalized] = useState("No");
  const validator = new Validator();
  const { selectedEmployee, getStaff, loading } = useStaff();
  const [formLoading, setLoading] = useState(false);

  // edit
  const formNames = ['account_no', 'bank', 'national_id_no', 'spouse_name', 'highest_qualification', 'place_of_birth', 'convicted', 'disabled', 'discharged_from_duty', 'hospitalized', 'hospitalized_details', 'conviction_details', 'disability_details', 'discharge_reason'];

  useEffect(() => {
    let personalData = employeePersonalDefaultData;
    if (!loading && id) {
      for (let x of formNames) {
        personalData = { ...personalData, ...{ [x]: { ...personalData[x], ...{ error: false, data: selectedEmployee[x] } } } };
      }
      setPersonalDetails(personalData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box

    if (value !== '') {
      // update the state
      setPersonalDetails({ ...personalDetails, ...{ [name]: { ...personalDetails[name], error: false, data: value } } });
    } else {
      // indicate an error
      setPersonalDetails({ ...personalDetails, ...{ [name]: { ...personalDetails[name], error: true, data: value } } });
    }
  };

  const handleRadioButtonChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    if (name === 'convicted') {
      setConvicated(value);
      if (value === 'Yes') {
        setPersonalDetails({ ...personalDetails, ...{ 'conviction_details': { ...personalDetails['conviction_details'], required: true } } });
      } else {
        setPersonalDetails({ ...personalDetails, ...{ 'conviction_details': { ...personalDetails['conviction_details'], required: false } } });
      }

    }
    if (name === 'disabled') {
      setDisabled(value);
      if (value === 'Yes') {
        setPersonalDetails({ ...personalDetails, ...{ 'disability_details': { ...personalDetails['disability_details'], required: true } } });
      } else {
        setPersonalDetails({ ...personalDetails, ...{ 'disability_details': { ...personalDetails['disability_details'], required: false } } });
      }
    }
    if (name === 'discharged_from_duty') {
      setDischarged(value);
      if (value === 'Yes') {
        setPersonalDetails({ ...personalDetails, ...{ 'discharge_reason': { ...personalDetails['discharge_reason'], required: true } } });
      } else {
        setPersonalDetails({ ...personalDetails, ...{ 'discharge_reason': { ...personalDetails['discharge_reason'], required: false } } });
      }
    }
    if (name === 'hospitalized') {
      setHospitalized(value);
      if (value === 'Yes') {
        setPersonalDetails({ ...personalDetails, ...{ 'hospitalized_details': { ...personalDetails['hospitalized_details'], required: true } } });
      } else {
        setPersonalDetails({ ...personalDetails, ...{ 'hospitalized_details': { ...personalDetails['hospitalized_details'], required: false } } });
      }
    }

  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (validator.checkForEmptyFields(personalDetails, setPersonalDetails)) {
      // continue
      const data = {
        bank: personalDetails.bank.data,
        account_no: personalDetails.account_no.data,
        national_id_no: personalDetails.national_id_no.data,
        spouse_name: personalDetails.spouse_name.data,
        highest_qualification: personalDetails.highest_qualification.data,
        place_of_birth: personalDetails.place_of_birth.data,
        convicted: convicted === 'Yes',
        disabled: disabled === 'Yes',
        discharged_from_duty: discharged === 'Yes',
        hospitalized: hospitalized === 'Yes',
        hospitalized_details: personalDetails.hospitalized_details.data,
        conviction_details: personalDetails.conviction_details.data,
        disability_details: personalDetails.disability_details.data,
        discharge_reason: personalDetails.discharge_reason.data,
        date_of_first_appointment: personalDetails.date_of_first_appointment.data,
        date_of_present_appointment: personalDetails.date_of_present_appointment.data
      }

      setLoading(true);
      doCreate('/staff/' + id + '/update', data).then((response) => {
        if (response.data.error) {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        } else {
          toast.success("Information updated succcessfully", successToastOptions);
          getStaff(Number(id));
          setTimeout(() => {
            onCancel();
          }, 1000);
        }
      }).catch(() => {
        toast.error(ERRORMESSAGE, errorToastOptions);
      }).finally(() => {
        setLoading(false);
      })
    }
  }


  return loading ? <EllipsisLoader /> : (
    <fieldset>
      <div className="form-row">
        <div className="col-sm-6">
          <Input label='Bank Name' name='bank' handleChange={handleChange} value={personalDetails.bank.data} error={personalDetails.bank.error} errorMessage="Please provide the bank name" required />
        </div>
        <div className="col-sm-6">
          <Input label='Account Number' name='account_no' handleChange={handleChange} value={personalDetails.account_no.data} error={personalDetails.account_no.error} errorMessage="Please provide the account number" required />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input label='NIN Number' name='national_id_no' handleChange={handleChange} value={personalDetails.national_id_no.data} error={personalDetails.national_id_no.error} errorMessage="Please provide the national id number" required />
        </div>
        <div className="col-sm-6">
          <Input label='Spouse name' name='spouse_name' handleChange={handleChange} value={personalDetails.spouse_name.data} error={personalDetails.spouse_name.error} errorMessage="Please provide the spouse name" />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input label='Highest Qualification' name='highest_qualification' handleChange={handleChange} value={personalDetails.highest_qualification.data} error={personalDetails.highest_qualification.error} errorMessage="Please provide the highest qualification" required />
        </div>
        <div className="col-sm-6">
          <Input label='Place of birth' name='place_of_birth' handleChange={handleChange} value={personalDetails.place_of_birth.data} error={personalDetails.place_of_birth.error} errorMessage="Please provide the place of birth" required />
        </div>
      </div>



      <div className="form-row mb-3">
        <div className="col-sm-6">
          <div className="col-sm-6">
            <label>Convicted?</label>
            <div>
              <RadioButton handleChange={handleRadioButtonChange} name='convicted' label='Yes' checked={convicted === 'Yes'} value={'Yes'} id="convicted_yes" />
              <RadioButton handleChange={handleRadioButtonChange} name='convicted' label='No' checked={convicted === 'No'} value={'No'} id="convicted_no" />
            </div>
          </div>
          <div className="col-sm-6">
            <Textarea name='conviction_details' label='Conviction details' handleChange={handleChange} required={convicted === 'Yes'} error={personalDetails.conviction_details.error} value={personalDetails.conviction_details.data} errorMessage="Please provide the conviction details" />
          </div>
        </div>

        <div className="col-sm-6">
          <div className="col-sm-6">
            <label>Ever discharged from duty?</label>
            <div>
              <RadioButton handleChange={handleRadioButtonChange} name='discharged_from_duty' label='Yes' checked={discharged === 'Yes'} value={'Yes'} id="discharged_from_duty_yes" />
              <RadioButton handleChange={handleRadioButtonChange} name='discharged_from_duty' label='No' checked={discharged === 'No'} value={'No'} id="discharged_from_duty_no" />
            </div>
          </div>

          <div className="col-sm-6">
            <Textarea name='discharge_reason' label='Discharge reason' handleChange={handleChange} required={discharged === 'Yes'} error={personalDetails.discharge_reason.error} value={personalDetails.discharge_reason.data} errorMessage="Please provide the reason for duty discharge" />
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <div className="col-sm-6">
            <label>Disabled?</label>
            <div>
              <RadioButton handleChange={handleRadioButtonChange} name='disabled' label='Yes' checked={disabled === 'Yes'} value={'Yes'} id="disabled_yes" />
              <RadioButton handleChange={handleRadioButtonChange} name='disabled' label='No' checked={disabled === 'No'} value={'No'} id="disabled_no" />
            </div>
          </div>
          <div className="col-sm-6">
            <Textarea name='disability_details' label='Disability details' handleChange={handleChange} required={disabled === 'Yes'} error={personalDetails.disability_details.error} value={personalDetails.disability_details.data} errorMessage="Please provide the details about the disability" />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="col-sm-6">
            <label>Hospitalized before?</label>
            <div>
              <RadioButton handleChange={handleRadioButtonChange} name='hospitalized' label='Yes' checked={hospitalized === 'Yes'} value={'Yes'} id="hospitalized_yes" />
              <RadioButton handleChange={handleRadioButtonChange} name='hospitalized' label='No' checked={hospitalized === 'No'} value={'No'} id="hospitalized_no" />
            </div>
          </div>
          <div className="col-sm-6">
            <Textarea name='hospitalized_details' label='Hosptilalized details' handleChange={handleChange} required={hospitalized === 'Yes'} error={personalDetails.hospitalized_details.error} value={personalDetails.hospitalized_details.data} errorMessage="Please provide the hospitalized details" />
          </div>

        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='button' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='button' className='btn  btn-info' onClick={handleSubmit}>
          {formLoading ? "Updating..." : "Update"}
        </button>
      </div>

    </fieldset>
  )
}

export default EditPersonalDetails