import React from 'react'

interface IProgressProps {
  progress: string;
}

const ProgressBar = ({ progress }: IProgressProps) => {
  return (
    <div className="progress" style={{ height: '0.65rem' }}>
      <div className="progress-bar bg-success" role="progressbar" style={{ width: progress }} />
    </div>
  )
}

export default ProgressBar