import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllRoles } from '../api/settings';
import { RootState } from '../redux/app/store';
import { setFormattedRoles, setRoles, setUsers } from '../redux/reducer/settings';
import { formatDataToSelectFormData } from "../helpers/index";

const useRoles = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    roles, formattedRoles
  } = useSelector(
    (state: RootState) => state.settings,
  );

  useEffect(() => {

    if (Object.keys(roles).length > 0) {
      setLoading(false);
      return;
    }

    getAllRoles().then((response) => {
      if (response.status === 200) {
        dispatch(setRoles(response.data.data));
        dispatch(setFormattedRoles(formatDataToSelectFormData(response.data.data)));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  const refresh = () => {
    getAllRoles().then((response) => {
      if (response.status === 200) {
        dispatch(setUsers(response.data.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }




  return { formattedRoles, loading, refresh, roles }
}

export default useRoles