/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-nested-ternary */
import { ChangeEvent, ReactElement } from 'react';
import classNames from 'classnames';

interface textareaProps {
  label?: string,
  id?: any,
  name?: string,
  placeholder?: string,
  value?: string | number,
  error?: boolean,
  errorMessage?: string,
  sm?: boolean,
  light?: boolean,
  required?: boolean,
  inputCustomClass?: string,
  withNoLabel?: boolean,
  rows?: number
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const Textarea = ({
  label, id, name, value, placeholder, handleChange, error, errorMessage, sm, light, required, inputCustomClass, withNoLabel, rows = 5,
}: textareaProps): ReactElement => {
  const input = classNames(`${inputCustomClass} form-control`, { 'border-danger': error }, { 'input-sm': sm }, { 'input-white': light });
  return (
    <div className="form-group w-100">
      {label && (
        <label htmlFor={id} >
          {label}
          {!withNoLabel ? required ? <span className="text-danger text-sm font-medium mb-3">*</span> : <span className="text-gray-500 ml-3 text-xs">(Optional)</span> : null}
        </label>
      )}
      <textarea
        placeholder={placeholder}
        name={name}
        id={id}
        onChange={handleChange}
        className={input}
        autoFocus={error}
        rows={rows}
        value={value === null ? '' : value}
      />
      {error && <small className="text-danger">{errorMessage}</small>}
    </div>
  );
};

export default Textarea;
