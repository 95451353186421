
import { createSlice } from '@reduxjs/toolkit';

export interface IAppointment {
  appointments: any;
  appointmentTypes: any;
  patients: any;
  selectedAppointment: any;
  arvs: any;
}

const initialState: IAppointment = {
  appointments: {},
  appointmentTypes: [],
  patients: [],
  selectedAppointment: {},
  arvs: []
};

export const appointmentReducer = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    setAppointments: (state, { payload }) => {
      state.appointments = payload;
    },
    setAppointmentTypes: (state, { payload }) => {
      state.appointmentTypes = payload;
    },
    setPatientsSelectData: (state, { payload }) => {
      state.patients = payload;
    },
    setSelectedAppointment: (state, { payload }) => {
      state.selectedAppointment = payload;
    },
    setArvs: (state, { payload }) => {
      state.arvs = payload;
    }
  },
});

const { reducer, actions } = appointmentReducer;

export const {
  setAppointments, setAppointmentTypes, setPatientsSelectData, setSelectedAppointment, setArvs
} = actions;

export default reducer;