import React, { useState } from 'react'
import { useLastUrlSegment } from '../../../hooks/useQuery';
import useStaff from '../../../hooks/useStaff';
import Card from '../../../parts/Card';
import CardContentWrapper from '../../global/CardContentWrapper';
import CardHeaderWithBottomBorder from '../../global/CardHeaderWithBottomBorder';
import CardListItem from '../../global/CardListItem';
import { EllipsisLoader } from '../../global/MyLoaders';
import MyModal from '../../global/MyModal';
import AddOtherTraining from './addEmployee/modals/AddOtherTraining';

const EmployeeOtherTrainings = () => {
  const { selectedEmployee, loading } = useStaff();
  const [isOpen, setIsOpen] = useState(false);
  const id = useLastUrlSegment();
  return loading && Object.keys(selectedEmployee).length > 0 ? <EllipsisLoader /> : (
    <>
      <MyModal title={`Add ${selectedEmployee?.user?.first_name} ${selectedEmployee?.user?.last_name} other training`} id='edit-employee-address' modalOpen={isOpen} setModalOpen={setIsOpen}>
        <AddOtherTraining onCancel={() => setIsOpen(false)} isSubmit id={id} />
      </MyModal>
      <Card>
        <>
          <CardHeaderWithBottomBorder title="Other Trainings" isButton >
            <button type='button' className='btn btn-sm btn-primary' onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
            }} > <i className='fa fa-plus'></i> Add</button>
          </CardHeaderWithBottomBorder>
          <CardContentWrapper>
            <div className='row'>
              {selectedEmployee?.other_training?.length > 0 ? (
                selectedEmployee.other_training.map(({ institution, id, award, date_attained, nature_of_training }: any, key: number) => (
                  <React.Fragment key={id}>
                    <h5 className='m-0 ml-3 pb-2 border-bottom'>{`${key + 1}. ${institution}`}</h5>
                    <CardListItem value={`${award}`} title="Award" />
                    <CardListItem value={`${nature_of_training}`} title="Nature of training" />
                    <CardListItem value={`${date_attained}`} title="Date Attained" />
                    <hr />
                  </React.Fragment>

                ))
              ) : (
                <p className='row align-items-center justify-content-center text-muted w-100 py-4'>There is no training history for {`${selectedEmployee?.user?.first_name} ${selectedEmployee?.user?.last_name}`}</p>
              )}
            </div>
          </CardContentWrapper>
        </>
      </Card>
    </>
  )
}

export default EmployeeOtherTrainings