import React from 'react'
import DashboardWrapper from '../../../components/DashboardWrapper'
import Breadcrumbs from '../../../parts/Breadcrumbs'
import { ROUTES } from '../../../routes/routes'
import Form from '../../../components/hr/employees/addEmployee/Form'

const AddEmployee = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Add new employee' parents={[{ link: ROUTES.EMPLOYEES, title: 'Employees' }]} />
        {/* body */}
        <Form />
      </>
    </DashboardWrapper>
  )
}

export default AddEmployee