import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  accountChartDefaultData,
  ERRORMESSAGE,
  errorToastOptions,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useAccountCategories from "../../../hooks/useAccountCategories";
import useAccounts from "../../../hooks/useAccounts";
import Validator from "../../../utils/Validator";
import Input from "../../global/Input";
import InputSelect from "../../global/InputSelect";
import { EllipsisLoader } from "../../global/MyLoaders";
import Textarea from "../../global/Textarea";

interface IEditAccountChartProps {
  id: number;
}

const EditAccountChartNew = ({ id }: IEditAccountChartProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [account, setAccount] = useState(accountChartDefaultData);
  const { formattedAccountingCategories } = useAccountCategories();
  const { getSelectedAccount, selectedAccount, loading } = useAccounts();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedAccount(id), [id]);

  const formNames = [
    "account_sub_category_id",
    "account_name",
    "account_no",
    "description",
    "opening_balance",
    "balance",
    "account_type",
  ];

  useEffect(() => {
    let accountData = accountChartDefaultData;
    if (!loading) {
      for (let x of formNames) {
        accountData = {
          ...accountData,
          ...{
            [x]: {
              ...accountData[x],
              ...{ error: false, data: selectedAccount[x] },
            },
          },
        };
      }
      setAccount(accountData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const validator = new Validator();

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;

    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setAccount({
        ...account,
        ...{ [name]: { ...account[name], ...{ error: false, data: value } } },
      });
    } else {
      // indicate an error
      setAccount({
        ...account,
        ...{ [name]: { ...account[name], ...{ error: true, data: value } } },
      });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setAccount({
        ...account,
        ...{ [name]: { ...account[name], ...{ error: false, data: value } } },
      });
    } else {
      // indicate an error
      setAccount({
        ...account,
        ...{ [name]: { ...account[name], ...{ error: true, data: value } } },
      });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(account, setAccount)) {
      setFormLoading(true);

      const data = {
        account_name: account.account_name.data,
        // accode_code: account.account_code.data,
        account_no: account.account_no.data,
        description: account.description.data,
        account_sub_category_id: account.account_sub_category_id.data,
        opening_balance: account.opening_balance.data,
      };

      doCreate(`account-charts/${id}/update`, data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success(
              "Account details updated successfully",
              successToastOptions
            );
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .catch(() => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        })
        .finally(() => {
          setFormLoading(false);
        });
    } else {
      console.log(account);
    }
  };

  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      <div className="form-row">
        <div className="col-sm-12">
          <InputSelect
            data={formattedAccountingCategories}
            label="Category"
            id="category"
            name="account_sub_category_id"
            required
            handleChange={handleSelectChange}
            value={[
              {
                value: account.account_sub_category_id.data,
                label: selectedAccount?.account_sub_category.sub_cat_name,
              },
            ]}
            error={account.account_sub_category_id.error}
            errorMessage="Please select the account category"
            disabled
          />
        </div>
        <div className="col-sm-12">
          <Input
            handleChange={handleChange}
            name="account_name"
            label="Account name"
            value={account.account_name.data}
            error={account.account_name.error}
            errorMessage="Please provide the account name"
            required
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-12">
          <Input
            handleChange={handleChange}
            name="account_no"
            label="Account no"
            required
            value={account.account_no.data}
            error={account.account_no.error}
            errorMessage="Please provide the account number"
          />
        </div>
        <div className="col-sm-12">
          <div className="form-row">
            <div className="col-5">
              <InputSelect
                data={[
                  { label: "Debit", value: "Dr" },
                  { label: "Credit", value: "Cr" },
                ]}
                label="Credit/Debit?"
                id="parent_account"
                name="account_type"
                required
                handleChange={handleSelectChange}
                value={
                  account.account_type.data === "Dr"
                    ? [{ label: "Debit", value: "Dr" }]
                    : [{ label: "Credit", value: "Cr" }]
                }
                error={account.account_type.error}
                errorMessage="Please select the account type"
                disabled
              />
            </div>
            <div className="col-7">
              <Input
                handleChange={handleChange}
                name="opening_balance"
                label="Opening balance"
                value={account.opening_balance.data}
                type="number"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-12">
          <Textarea
            handleChange={handleChange}
            name="description"
            label="Description"
            required
            value={account.description.data}
            error={account.description.error}
            errorMessage="Please provide the account description"
          />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type="reset" className="btn btn-outline-danger mr-3">
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn  btn-primary"
          onClick={handleSubmit}
        >
          {formLoading ? "Updating..." : "Update"}
        </button>
      </div>
    </form>
  );
};

export default EditAccountChartNew;
