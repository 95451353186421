import { useLastUrlSegment } from "../../../hooks/useQuery";
import Card from "../../../parts/Card";
import CardContentWrapper from "../../global/CardContentWrapper";
import CardHeaderWithBottomBorder from "../../global/CardHeaderWithBottomBorder";
import { EllipsisLoader } from "../../global/MyLoaders";
import MyModal from "../../global/MyModal";
import EditFunFact from "./EditFunFact";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { htmlParseOptions } from "../../../constants";
import useFunFacts from "../../../hooks/useFunFacts";


const ViewFact = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }
  const id = useLastUrlSegment();
  const { loading, getSelectedFunFact, selectedFunFact } = useFunFacts();
  useEffect(() => {
    return getSelectedFunFact(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return loading ? (<EllipsisLoader />) : (
    <>
      <MyModal modalOpen={isOpen} setModalOpen={setIsOpen} id={id} title="Edit story">
        <EditFunFact closeModal={handleModalClick} id={id} />
      </MyModal>
      <div className='py-4 row align-items-end'>
        <button className='btn btn-info ml-auto mr-3' onClick={() => setIsOpen(true)}> <i className='fa fa-edit'></i> Edit</button>
      </div>
      <div className='row m-0'>
        <div className='col-xs-12 mb-30'>
          <Card>
            <>
              <CardHeaderWithBottomBorder title={selectedFunFact.title} />
              <CardContentWrapper>
                <div className='row m-0 flex-column'>
                  <div className="col-xs-12 mb-3">
                    <span>Source: </span>
                    <a href={selectedFunFact.url} target="_blank" rel="noreferrer" className=' font-bold text-info'>{selectedFunFact.url}</a>
                  </div>
                  <div className='col-xs-12'>
                    {parse(selectedFunFact.description, htmlParseOptions)}
                  </div>
                </div>
              </CardContentWrapper>

            </>
          </Card>
        </div>
      </div>
    </>
  )
}

export default ViewFact