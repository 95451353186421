import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import usePrograms from '../../../hooks/usePrograms';
import { useLastUrlSegment } from '../../../hooks/useQuery';
import Card from '../../../parts/Card';
import CardContentWrapper from '../../global/CardContentWrapper';
import CardHeaderWithBottomBorder from '../../global/CardHeaderWithBottomBorder';
import { EllipsisLoader } from '../../global/MyLoaders';

const OtherPrograms = () => {
  const id = useLastUrlSegment();
  const { loading, getOtherPrograms, otherPrograms } = usePrograms();
  useEffect(() => {
    return getOtherPrograms(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return loading ? <EllipsisLoader /> : (
    <Card>
      <>
        <CardHeaderWithBottomBorder title="Other Programs" />
        <CardContentWrapper>
          {otherPrograms?.map((prog: any) => (
            <div key={prog.id} className='row py-1 justify-content-between m-0'>
              <Link className=' border-bottom-dashed-link' to={`/projects/programs/view/${prog.id}`} >{prog?.category_name}</Link>
            </div>
          ))}
        </CardContentWrapper>
      </>
    </Card>
  )
}

export default OtherPrograms