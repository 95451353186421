import { useState } from "react"
import toast from "react-hot-toast";
import { createStory } from "../../../api/mobile_app";
import { errorToastOptions, storyDefaultData, successToastOptions } from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useStories from "../../../hooks/useStories";
import Validator from "../../../utils/Validator";
import Input from "../../global/Input"
import TextEditor from "../../global/TextEditor";

interface IAppStoryProps {
  closeModal: () => void
  id?: number
}

const AddStory = ({ closeModal }: IAppStoryProps) => {
  const [story, setStory] = useState(storyDefaultData);
  const [male, setMale] = useState(true);
  const [female, setFemale] = useState(false);
  const [loading, setLoading] = useState(false);
  const validator = new Validator();

  const { refresh } = useStories();


  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement;

    if (name === 'gender') {
      if (value === 'Male') {
        setMale(true);
      }

      if (value === 'Female') {
        setFemale(true);
      }

    }

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setStory({ ...story, ...{ [name]: { ...story[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setStory({ ...story, ...{ [name]: { ...story[name], ...{ error: true, data: value } } } });
    }

  };

  const handleEditorChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setStory({ ...story, ...{ [name]: { ...story[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setStory({ ...story, ...{ [name]: { ...story[name], ...{ error: true, data: value } } } });
    }
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(story, setStory)) {
      setLoading(true);
      const data = {
        name: story.name.data,
        age: story.age.data,
        address: story.address.data,
        gender: story?.gender.data,
        story: story.story.data,
        title: story.title.data
      }

      createStory(data).then((response) => {
        if (response.data.success === true) {
          toast.success('New Story created successfully', successToastOptions);
          refresh();

          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).finally(() => {
        setLoading(false);
      })
    }
  }


  return (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input label="Full Names" name="name" handleChange={handleChange} value={story.name.data} error={story.name.error} errorMessage="Please provide the full names of the story teller" required />
        </div>

        <div className="col-sm-6">
          <Input label="Address" name="address" handleChange={handleChange} value={story.address.data} error={story.address.error} errorMessage="Please provide the address" required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input label="Age" name="age" handleChange={handleChange} value={story.age.data} error={story.age.error} errorMessage="Please provide the age of the story teller" required />
        </div>

        <div className="col form-group">

          <label htmlFor="gender">Gender</label>
          <div>
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id="male" name="gender" className="custom-control-input" value="Male" onChange={handleChange} checked={male} />
              <label className="custom-control-label" htmlFor="male">Male</label>
            </div>

            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id="female" name="gender" className="custom-control-input" value="Female" onChange={handleChange} checked={female} />
              <label className="custom-control-label" htmlFor="female">Female</label>
            </div>
          </div>
        </div>
      </div>


      <Input label="Title" name="title" handleChange={handleChange} value={story.title.data} error={story.title.error} errorMessage="Please provide the title of the story" required />

      <div className="form-group">
        <label htmlFor="title">Story</label>
        <TextEditor handleChange={handleEditorChange} name="story" id="story" required />
        <small id="title" className="form-text text-muted">Please enter the story here</small>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={loading} className='btn  btn-info' onClick={handleSubmit}>
          {loading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  )
}

export default AddStory