import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import ComingSoon from '../../components/global/ComingSoon'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Training = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Training' />
        <ComingSoon />
      </>
    </DashboardWrapper>
  )
}

export default Training