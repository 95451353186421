import React, { Suspense } from "react";
// import logo from './logo.svg';
// import { Counter } from './redux/features/counter/Counter';
import "./App.css";
import { Helmet } from "react-helmet";
import AppRouter from "./routes/AppRouter";
import "./assets/css/style.css";
import "./assets/css/custom.css";
import { Toaster } from "react-hot-toast";
import { PageLoader } from "./components/global/MyLoaders";

function App() {
  return (
    <Suspense fallback={<PageLoader />}>
      <Helmet>
        <title>TFC - Admin system</title>
      </Helmet>
      <AppRouter />
      <Toaster position="top-right" reverseOrder={false} />
    </Suspense>
  );
}

export default App;
