import React, { useState } from "react";
import toast from "react-hot-toast";
import { createUser } from "../../../api/settings";
import {
  ERRORMESSAGE,
  errorToastOptions,
  languagesData,
  successToastOptions,
  userDefaultData,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useRoles from "../../../hooks/useRoles";
import useUsers from "../../../hooks/useUsers";
import Validator from "../../../utils/Validator";
import ImagePicker from "../../global/ImagePicker";
import Input from "../../global/Input";
import InputDate from "../../global/InputDate";
import InputSelect from "../../global/InputSelect";

interface IAddUserProps {
  closeModal: () => void;
}

const AddUser = ({ closeModal }: IAddUserProps) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(userDefaultData);
  const { refresh } = useUsers();

  const validator = new Validator();
  const { formattedRoles } = useRoles();

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setUser({ ...user, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setUser({ ...user, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setUser({ ...user, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setUser({ ...user, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleImageChange = (e: any) => {
    setUser({ ...user, ...{ profile_pic: { error: false, data: e } } });
  };

  const handleLanguageChange = (obj: unknown) => {
    const { name, selectedOptions } = obj as HTMLSelectElement;
    if (name === "languages") {
      const selected = Array.from(selectedOptions).map((item) => item.label);
      const data = { [name]: { error: false, data: selected.join() } };
      setUser({ ...user, ...data });
    }
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(user, setUser)) {
      setLoading(true);

      const data = {
        first_name: user.first_name.data,
        last_name: user.last_name.data,
        middle_name: user.middle_name.data,
        gender: user.gender.data,
        mobile_phone: user.mobile_phone.data,
        profile_pic: user.profile_pic.data,
        role_id: user.role_id.data,
        languages: user.languages.data,
        // languages: 'English',
        date_of_birth: user.date_of_birth.data,
        email: user.email.data,
        password: user.password.data,
        username: user.username.data,

        org_id: 1,
        clinic_id: 1,
      };
      try {
        const response = await createUser(data);
        if (response.data.success === true) {
          toast.success(
            "New user registered successfully",
            successToastOptions
          );
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(
            formatErrorMessage(response.data.data, response.data.message),
            errorToastOptions
          );
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input
            name="first_name"
            handleChange={handleChange}
            label="First Name"
            value={user.first_name.data}
            error={user.first_name.error}
            errorMessage="Please provide the first name"
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            name="last_name"
            handleChange={handleChange}
            label="Last Name"
            value={user.last_name.data}
            error={user.last_name.error}
            errorMessage="Please provide the last name"
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            name="middle_name"
            handleChange={handleChange}
            label="Middle Name"
            value={user.middle_name.data}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input
            name="username"
            handleChange={handleChange}
            label="Username"
            value={user.username.data}
            error={user.username.error}
            errorMessage="Please provide a valid username"
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            name="password"
            type="password"
            handleChange={handleChange}
            label="Password"
            value={user.password.data}
            error={user.password.error}
            errorMessage="Please provide the user's password"
            required
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input
            name="email"
            handleChange={handleChange}
            label="Email Address"
            type="email"
            value={user.email.data}
            error={user.email.error}
            errorMessage="Please provide a valid email address"
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            name="mobile_phone"
            type="tel"
            handleChange={handleChange}
            label="mobile_phone Number"
            value={user.mobile_phone.data}
            error={user.mobile_phone.error}
            errorMessage="Please provide the user's mobile_phone number"
            required
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect
            name="role_id"
            handleChange={handleSelectChange}
            label="Role"
            data={formattedRoles}
            id="role"
            value={user.role_id.data}
            error={user.role_id.error}
            errorMessage="Please provide the user's role"
            required
          />
        </div>{" "}
        <div className="col-sm-6">
          <InputSelect
            name="languages"
            handleChange={handleLanguageChange}
            label="Language(s) spoken"
            data={languagesData}
            id="role"
            multiple
            value={user.languages.data}
            error={user.languages.error}
            errorMessage="Please provide the user's language(s)"
            required
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputDate
            name="date_of_birth"
            handleChange={handleSelectChange}
            label="Date of birth"
            id="date_of_birth"
            value={user.date_of_birth.data}
            error={user.date_of_birth.error}
            errorMessage="Please provide the user's date of birth"
            required
            maxData={18}
          />
        </div>{" "}
        <div className="col-sm-6">
          <InputSelect
            name="gender"
            handleChange={handleSelectChange}
            label="Gender"
            data={[
              { value: "Male", label: "Male" },
              { label: "Female", value: "Female" },
            ]}
            id="gender"
            value={user.gender.data}
            error={user.gender.error}
            errorMessage="Please provide the user's gender"
            required
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <div className="row flex-column m-0 mb-4">
            <label className="font-semibold text-sm">Profile pic</label>
            <ImagePicker handleChange={handleImageChange} />
          </div>
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {loading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddUser;
