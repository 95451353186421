
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doGet } from '../api';
import { RootState } from '../redux/app/store';
import { setInstitutions } from '../redux/reducer/generalReducers';
import { moveBackward, moveForward, resetFormData, seEmployeeAddressDetails, setEmployeeEducationDetails, setEmployeeEmploymentDetails, setEmployeeOtherTrainingDetails, setEmployeePersonalDetails, setEmployeeRefereeDetails } from '../redux/reducer/hr';

const useEmployees = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    employees, addEmployeeFormId, addEmployeeEducationDetails, addEmployeeEmploymentDetails, addEmployeeRefereeDetails, addEmployeeOtherTrainingDetails, employeePersonalDetails, employeeAddressDetails
  } = useSelector(
    (state: RootState) => state.hr,
  );
  const { institutions } = useSelector((state: RootState) => state.general)

  const prev = () => {
    dispatch(moveBackward());
  }

  const next = () => {
    dispatch(moveForward());
  }

  const storeEmployeeEducationDetails = (data: any) => {
    dispatch(setEmployeeEducationDetails(data));
  }

  const storeEmployeeEmploymentDetails = (data: any) => {
    dispatch(setEmployeeEmploymentDetails(data));
  }
  const storeEmployeeOtherTrainingDetails = (data: any) => {
    dispatch(setEmployeeOtherTrainingDetails(data));
  }

  const storeEmployeeRefereeDetails = (data: any) => {
    dispatch(setEmployeeRefereeDetails(data));
  }

  const storeEmployeePersonalDetails = (data: any) => {
    dispatch(setEmployeePersonalDetails(data));
  }

  const storeEmployeeAddressDetails = (data: any) => {
    dispatch(seEmployeeAddressDetails(data));
  }

  const reset = () => {
    dispatch(resetFormData());
  }

  const getInstitutions = () => {
    if (institutions.length > 0) return;
    setLoading(true);
    doGet('location/sub_counties').then((response) => {
      dispatch(setInstitutions(response?.data?.data));
    }).catch((e) => {

    }).finally(() => {
      setLoading(false);
    });

  }


  return {
    employees, prev, next, addEmployeeFormId, storeEmployeeEducationDetails, addEmployeeEducationDetails, addEmployeeEmploymentDetails, storeEmployeeEmploymentDetails, storeEmployeeRefereeDetails, addEmployeeRefereeDetails, storeEmployeeOtherTrainingDetails, addEmployeeOtherTrainingDetails, storeEmployeePersonalDetails, employeePersonalDetails, storeEmployeeAddressDetails, employeeAddressDetails, reset, getInstitutions, institutions, loading
  }
}

export default useEmployees