/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */

import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from '../helpers/isAuthenticated';
import { ROUTES } from './routes';

const MainPrivateRoute = ({ component: Component, ...rest }: any): any => (
  <Route
    {...rest}
    render={(props) => (isAuthenticated() ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{ pathname: ROUTES.LOGIN, state: { from: props.location } }}
      />
    ))}
  />
);

export default MainPrivateRoute;
