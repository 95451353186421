import React, { useEffect } from 'react'
import { EllipsisLoader } from '../../components/global/MyLoaders';
import ViewPrograms from '../../components/project/programs/ViewPrograms';
import SettingsWrapper from '../../components/SettingsWrapper';
import usePrograms from '../../hooks/usePrograms';
import { useLastUrlSegment } from '../../hooks/useQuery';
import Breadcrumbs from '../../parts/Breadcrumbs';
import { ROUTES } from '../../routes/routes';

const ViewProgram = () => {
  const id = useLastUrlSegment();
  const { getSelectedProgram, loading } = usePrograms();
  useEffect(() => {
    return getSelectedProgram(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <SettingsWrapper >
      <>
        <Breadcrumbs title='View program' parents={[{ link: ROUTES.PROJECTS, title: 'Projects' }, { link: ROUTES.PROGRAMS, title: 'Programs' }]} />
        {loading ? <EllipsisLoader /> : <ViewPrograms />}


      </>
    </SettingsWrapper >
  )
}

export default ViewProgram