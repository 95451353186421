import React from 'react'
import usePayrolls from '../../../hooks/usePayrolls';
import { EllipsisLoader } from '../../global/MyLoaders';
import PayrollInfoCard from '../../global/PayrollInfoCard'
import PersonalPayrollDetailsTable from './PersonalPayrollDetailsTable'

const PersonalPayrollDetails = () => {
  const { selectedStaffPayrollStats, loading } = usePayrolls();
  return loading ? <EllipsisLoader /> : (
    <div>
      <div className="row justify-content-between m-0">
        <PayrollInfoCard title='Total Gross' amount={selectedStaffPayrollStats[0]?.total_pay} />
        <PayrollInfoCard title='Total DEDUCATIONS' amount={selectedStaffPayrollStats[0]?.deductions} icon="fa-minus" bg='danger' />
        <PayrollInfoCard title='Total Net' amount={selectedStaffPayrollStats[0]?.net_pay} icon="fa-balance-scale" bg="primary" />
        <PayrollInfoCard title='Total Paid' amount={selectedStaffPayrollStats[0]?.paid_amount} icon=" fa-dollar" bg="success" />
      </div>
      <hr />
      <div className='mb-3'>
        <PersonalPayrollDetailsTable />
      </div>
    </div>
  )
}

export default PersonalPayrollDetails