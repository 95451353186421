/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable indent */
/* eslint-disable camelcase */
import { createSlice } from "@reduxjs/toolkit";

export interface IGeneral {
  appUserStat: any;
  ageGroupStates: any;
  projectStats: any;
  reportsStats: any;
  clinicStats: any;
  recentAssets: any;
  recentProjects: any;
  recentStock: any;
  toggleSideNav: boolean;
  recentActivities: any;
  dailyActivity: any;
  clinicReports: any;
  clinicReportDetails: any;
  projectReports: any;
  projectReportDetails: any;
  payrollReport: any;
  allDashboard: any;
  institutions: any;
  approvingCommitte: any;
  reviewingCommittee: any;
  activeAnnouncement: any;
  anouncements: any;
  selectedAnnouncement: any;
  projectActivityReports: any;
  reportStartDate: any;
  reportEndDate: any;
}

const initialState: IGeneral = {
  appUserStat: [0, 0],
  ageGroupStates: [],
  projectStats: {},
  reportsStats: {},
  clinicStats: {},
  recentAssets: [],
  recentProjects: [],
  recentStock: [],
  toggleSideNav: false,
  recentActivities: [],
  dailyActivity: [],
  clinicReports: [],
  clinicReportDetails: {},
  projectReports: [],
  projectReportDetails: {},
  payrollReport: [],
  allDashboard: [],
  institutions: [],
  approvingCommitte: [],
  reviewingCommittee: [],
  activeAnnouncement: {},
  anouncements: [],
  selectedAnnouncement: {},
  projectActivityReports: [],
  reportStartDate: "",
  reportEndDate: "",
};

export const generalReducer = createSlice({
  name: "general",
  initialState,
  reducers: {
    setAppUserStats: (state, { payload }) => {
      state.appUserStat = payload;
    },
    setAgeGroupStates: (state, { payload }) => {
      state.ageGroupStates = payload;
    },
    setProjectStats: (state, { payload }) => {
      state.projectStats = payload;
    },
    setReportsStats: (state, { payload }) => {
      state.reportsStats = payload;
    },
    setClinicStats: (state, { payload }) => {
      state.clinicStats = payload;
    },
    setRecentStock: (state, { payload }) => {
      state.recentStock = payload;
    },
    setRecentAsset: (state, { payload }) => {
      state.recentAssets = payload;
    },
    setRecentProject: (state, { payload }) => {
      state.recentProjects = payload;
    },
    setToggleSideNav: (state) => {
      state.toggleSideNav = !state.toggleSideNav;
    },
    setRecentActivities: (state, { payload }) => {
      state.recentActivities = payload;
    },
    setDailyActivities: (state, { payload }) => {
      state.dailyActivity = payload;
    },
    setClinicReports: (state, { payload }) => {
      state.clinicReports = payload;
    },
    setClinicReportDetails: (state, { payload }) => {
      state.clinicReportDetails = payload;
    },
    setPrjoectReports: (state, { payload }) => {
      state.projectReports = payload;
    },
    setProjectReportDetails: (state, { payload }) => {
      state.projectReportDetails = payload;
    },
    setPayrollReport: (state, { payload }) => {
      state.payrollReport = payload;
    },
    setAllDashboard: (state, { payload }) => {
      state.allDashboard = payload;
    },
    setInstitutions: (state, { payload }) => {
      state.institutions = payload;
    },
    setApprovingCommittee: (state, { payload }) => {
      state.approvingCommitte = payload;
    },
    setReviewingCommittee: (state, { payload }) => {
      state.reviewingCommittee = payload;
    },
    setAnnouncements: (state, { payload }) => {
      state.anouncements = payload;
    },
    setActiveAnnouncements: (state, { payload }) => {
      state.activeAnnouncement = payload;
    },
    setSelectedAnnouncemt: (state, { payload }) => {
      state.selectedAnnouncement = payload;
    },
    setProjectActivityReport: (state, { payload }) => {
      state.projectActivityReports = payload;
    },
    setStartDate: (state, { payload }) => {
      state.reportStartDate = payload;
    },
    setEndDate: (state, { payload }) => {
      state.reportEndDate = payload;
    },
  },
});

const { reducer, actions } = generalReducer;

export const {
  setAppUserStats,
  setAgeGroupStates,
  setProjectStats,
  setReportsStats,
  setClinicStats,
  setRecentAsset,
  setRecentProject,
  setRecentStock,
  setToggleSideNav,
  setRecentActivities,
  setDailyActivities,
  setClinicReports,
  setClinicReportDetails,
  setProjectReportDetails,
  setPrjoectReports,
  setPayrollReport,
  setAllDashboard,
  setInstitutions,
  setApprovingCommittee,
  setReviewingCommittee,
  setActiveAnnouncements,
  setAnnouncements,
  setSelectedAnnouncemt,
  setProjectActivityReport,
  setStartDate,
  setEndDate,
} = actions;

export default reducer;
