import { createSlice } from '@reduxjs/toolkit';

export interface IClinics {
  clinics: any;
  formattedClinics: any;
  selectedClinic: any;
  doctors: any;

}

const initialState: IClinics = {
  clinics: [],
  formattedClinics: [],
  selectedClinic: {},
  doctors: [],
}

export const stockReducer = createSlice({
  name: 'clinics',
  initialState,
  reducers: {
    setClinics: (state, { payload }) => {
      state.clinics = payload;
    },
    setformattedClinics: (state, { payload }) => {
      state.formattedClinics = payload;
    },
    setSelectedClinic: (state, { payload }) => {
      state.selectedClinic = payload;
    },
    setClinicDoctor: (state, { payload }) => {
      state.doctors = payload;
    }

  }
});

const { reducer, actions } = stockReducer;

export const { setClinics, setformattedClinics, setSelectedClinic, setClinicDoctor } = actions;

export default reducer;