import React from 'react'
import ListFunders from '../../components/settings/funders/ListFunders'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Funders = () => {
  return (
    <SettingsWrapper>
      <>
        <Breadcrumbs title='Funders' />
        {/* body */}
        <ListFunders />
      </>
    </SettingsWrapper>
  )
}

export default Funders