/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement } from 'react';
import ClinicSideBar from '../parts/ClinicSideBar';
import Footer from '../parts/Footer';
import Nav from '../parts/Nav';
import SideBar from '../parts/SideBar';

interface ISettingsWrapperProps {
  children: ReactElement
}

const ClinicWrapper = ({ children }: ISettingsWrapperProps) => {
  return (
    <div className='wrapper'>
      <Nav />
      <div className="container-fluid">
        <div className="row m-0">
          <SideBar />

          <div className="content-wrapper">
            <div className="row m-0">
              <ClinicSideBar />
              <div className="col-xs-12 col-md-9">
                {children}

              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClinicWrapper