import { useState } from "react";
import ReactTooltip from "react-tooltip";
import { TableColumn } from "react-data-table-component";
import { formatCurrency, formatState } from "../../../utils/helpers";
import ConfirmDelete from "../../global/ConfirmDelete";
import ModalSwitcher from "../../global/ModalSwitcher";
import MyDataTable from "../../global/MyDataTable";
import { EllipsisLoader } from "../../global/MyLoaders";
import AcceptRequisition from "./AcceptCashRequisition";
import AddCashRequisition from "./AddCashRequisition";
import DeclineCashRequisition from "./DeclineCashRequisition";
import ReviewCashRequisition from "./ReviewCashRequisition";
import useCashRequisition from "../../../hooks/useCashRequisition";

const ListCashRequisitions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);

  const { loading, cashRequisition, refresh } = useCashRequisition();

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      reorder: true,
      sortable: true,
      width: "40px",
    },
    {
      id: 2,
      name: "Amount",
      selector: (row) => formatCurrency(row.amount),
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 3,
      name: "Reason",
      selector: (row) => row.reason,
      reorder: true,
      grow: 3,
      wrap: true,
    },
    {
      id: 4,
      name: "Staff",
      selector: (row) => row.user?.first_name + " " + row.user?.last_name,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 5,
      name: "Request Date",
      selector: (row) => row.request_date,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 6,
      name: "Delivery Date",
      selector: (row) => row.delivery_date,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 7,
      name: "Status",
      selector: (row) => (
        <span className={`badge ${formatState(row.state_id).className}`}>
          {formatState(row.state_id).title}
        </span>
      ),
      reorder: true,
      sortable: true,
      grow: 1,
      wrap: true,
    },
    {
      id: 8,
      name: "Comment",
      selector: (row) => (row.comment === null ? "" : row.comment),
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      button: true,
      cell: (row) =>
        [3, 4].includes(row.state_id) ? (
          <button
            className="btn btn-sm btn-danger mx-1"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(row.id);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        ) : row.state_id === 1 ? (
          <div className="row">
            <button
              data-tip="hello world"
              className="btn btn-sm btn-primary mx-1"
              onClick={(e) => {
                e.stopPropagation();
                reviewRequisition(row.id);
              }}
            >
              <i className="fa fa-forward"></i>
            </button>
            <button className="btn btn-sm btn-outline-danger">
              <i
                className="fa fa-exclamation-triangle"
                onClick={(e) => {
                  e.stopPropagation();
                  declineRequisition(row.id);
                }}
              ></i>
            </button>

            <button
              className="btn btn-sm btn-danger mx-1"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(row.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        ) : (
          <div className="row">
            <button
              className="btn btn-sm btn-info mx-1"
              onClick={(e) => {
                e.stopPropagation();
                acceptRequisition(row.id);
              }}
            >
              <i className="fa fa-check"></i>
            </button>
            <button className="btn btn-sm btn-outline-danger">
              <i
                className="fa fa-exclamation-triangle"
                onClick={(e) => {
                  e.stopPropagation();
                  declineRequisition(row.id);
                }}
              ></i>
            </button>

            <button
              className="btn btn-sm btn-danger mx-1"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(row.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        ),
    },
  ];

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  };

  const reviewRequisition = (id: number) => {
    setOpenModal(1); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const acceptRequisition = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const declineRequisition = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const handleDeleteClick = (id: number) => {
    setOpenModal(4); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 1:
      modalId = "review-cash-requisition";
      modalTitle = "Submit your review";
      ModalToRender = ReviewCashRequisition;
      break;
    case 2:
      modalId = "accept-cash-requisition";
      modalTitle = "Type comment to Approve requisition";
      ModalToRender = AcceptRequisition;

      break;
    case 3:
      modalId = "decline-cash-requisition";
      modalTitle = "Decline Cash Requisition";
      ModalToRender = DeclineCashRequisition;

      break;
    case 4:
      modalId = "delete-cash-requisition";
      modalTitle = "";
      ModalToRender = ConfirmDelete;
      break;
    default:
      modalId = "add-new-cash-requisition";
      modalTitle = "Add New Cash Requisition";
      ModalToRender = AddCashRequisition;
      break;
  }

  return (
    <>
      <ModalSwitcher
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={modalId}
        title={modalTitle}
        component={
          <ModalToRender
            id={selectedId}
            closeModal={handleModalClick}
            path={`cash-requisitions/${selectedId}/delete`}
            refresh={refresh}
            onCancel={handleModalClick}
          />
        }
      />
      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className="pl-3 row align-items-end">
                  <button
                    className="btn btn-primary ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal(0);
                      setIsOpen(true);
                    }}
                  >
                    <i className="fa fa-plus"></i>Add Requisition
                  </button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search...."
                  />
                </div>
              </div>
            </div>
            {/* table */}
            {loading ? (
              <EllipsisLoader />
            ) : (
              <MyDataTable columns={columns} data={cashRequisition} title="" />
            )}
            {/* end table */}
          </div>
        </div>
      </div>
      <ReactTooltip />
    </>
  );
};

export default ListCashRequisitions;
