import React from 'react'
import ListSuppliers from '../../components/stocks/supplier/ListSuppliers'
import StocksWrapper from '../../components/StocksWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const Suppliers = () => {
  return (
    <StocksWrapper>
      <>
        <Breadcrumbs title='Suppliers' parents={[{ link: ROUTES.STOCKS, title: 'Stock Management' }]} />

        <ListSuppliers />
      </>
    </StocksWrapper>
  )

}

export default Suppliers