import React, { useState } from "react";
import toast from "react-hot-toast";
import { createFunFact } from "../../../api/mobile_app";
import {
  errorToastOptions,
  funFactDefaultData,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useFunFacts from "../../../hooks/useFunFacts";
import Validator from "../../../utils/Validator";
import Input from "../../global/Input";
import TextEditor from "../../global/TextEditor";

interface IAddFunFactProps {
  closeModal: () => void;
}

const AddFunFact = ({ closeModal }: IAddFunFactProps) => {
  const [funFact, setFunFact] = useState(funFactDefaultData);
  const [loading, setLoading] = useState(false);

  const validator = new Validator();
  const { refresh } = useFunFacts();

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement;

    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setFunFact({
        ...funFact,
        ...{ [name]: { ...funFact[name], ...{ error: false, data: value } } },
      });
    } else {
      // indicate an error
      setFunFact({
        ...funFact,
        ...{ [name]: { ...funFact[name], ...{ error: true, data: value } } },
      });
    }
  };

  const handleEditorChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setFunFact({
        ...funFact,
        ...{ [name]: { ...funFact[name], ...{ error: false, data: value } } },
      });
    } else {
      // indicate an error
      setFunFact({
        ...funFact,
        ...{ [name]: { ...funFact[name], ...{ error: true, data: value } } },
      });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(funFact, setFunFact)) {
      setLoading(true);
      const data = {
        title: funFact.title.data,
        url: funFact.url.data,
        description: funFact.description.data,
      };

      createFunFact(data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success("Fun Fact successfully created", successToastOptions);
            refresh();

            setTimeout(() => {
              closeModal();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <form>
      <Input
        label="Title"
        name="title"
        handleChange={handleChange}
        value={funFact.title.data}
        error={funFact.title.error}
        required
      />
      <Input
        label="Link to Fun Fact"
        name="url"
        handleChange={handleChange}
        value={funFact.url.data}
        error={funFact.url.error}
        required
      />
      <div className="form-group">
        <label htmlFor="title">Description</label>
        <TextEditor
          handleChange={handleEditorChange}
          name="description"
          id="description"
        />
        <small id="description" className="form-text text-muted">
          Please enter the description here
        </small>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {loading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddFunFact;
