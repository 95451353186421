import React, { useEffect, useState } from 'react'
import { TableColumn } from 'react-data-table-component';
import useAccounts from '../../../hooks/useAccounts';
import useRequisition from '../../../hooks/useRequisition';
import useUsers from '../../../hooks/useUsers';
import ModalSwitcher from '../../global/ModalSwitcher';
import MyDataTable from '../../global/MyDataTable';
import { EllipsisLoader } from '../../global/MyLoaders';
import AddGoodsRecieved from './AddGoodsRecieved';
import ViewGoodsRecieved from './ViewGoodsRecieved';

const ListGoodsRecieved = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const { loading, goodsRecieved, getGoodsRecieved } = useRequisition();
  const { getUserNamesById } = useUsers();
  const { getAccountNameById } = useAccounts();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getGoodsRecieved(), []);

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      reorder: true,
      sortable: true,
    }, {
      id: 2,
      name: "Recieved on",
      selector: (row) => row.date,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 3,
      name: "Cash account",
      selector: (row) => getAccountNameById(row.cash_account_id),
      reorder: true,
      grow: 2,
      wrap: true,

    }, {
      id: 4,
      name: "Debit account",
      selector: (row) => getAccountNameById(row.expense_account_id),
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 5,
      name: "Recieved by",
      selector: (row) => getUserNamesById(row.received_by),
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      button: true,
      cell: (row) => (
        <div className='row'>
          <button className='btn btn-sm btn-info mx-1' onClick={(e) => {
            e.stopPropagation();
            handleEditClick(row.id);
          }}><i className='fa fa-eye'></i> View</button>

        </div>
      )
    }
  ];
  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }



  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = 'view-goods';
      modalTitle = 'Recieved goods';
      ModalToRender = ViewGoodsRecieved

      break;
    default:
      modalId = 'add-new-purchase-order';
      modalTitle = 'Add New Recieved Goods';
      ModalToRender = AddGoodsRecieved
      break;
  }

  return loading ? <EllipsisLoader /> : (
    <>
      <ModalSwitcher isOpen={isOpen} setIsOpen={setIsOpen} id={modalId} title={modalTitle} component={<ModalToRender id={selectedId} closeModal={handleModalClick} refresh={() => ""} />} />

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className='pl-3 row align-items-end'>
                  <button className='btn btn-primary ml-auto mr-3'
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                      setOpenModal(0);
                    }}><i className='fa fa-plus'></i>Add new </button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input type="search" className="form-control" placeholder="Search...." />
                </div>
              </div>
            </div>
            {/* table */}
            <MyDataTable columns={columns} data={goodsRecieved} title="" />
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ListGoodsRecieved