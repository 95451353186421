import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { doCreate } from '../../../../api';
import { assetIncomeDefaultData, errorToastOptions, successToastOptions } from '../../../../constants';
import { formatErrorMessage } from '../../../../helpers';
import useAccounts from '../../../../hooks/useAccounts';
import { getFormData } from '../../../../utils/helpers';
import Validator from '../../../../utils/Validator';
import Input from '../../../global/Input';
import InputDate from '../../../global/InputDate';
import InputSelect from '../../../global/InputSelect';
import { EllipsisLoader } from '../../../global/MyLoaders';
import Textarea from '../../../global/Textarea';

interface IAddIncomeProps {
  closeModal: () => void;
  assetId: number;
}

const AddIncome = ({ assetId, closeModal }: IAddIncomeProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [income, setIncome] = useState(assetIncomeDefaultData);
  const { formattedIncomeAccounts, formattedIncomeTypes, getAssetIncomeTypes, loading, getIncomeAccount } = useAccounts();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAssetIncomeTypes(), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getIncomeAccount(), []);


  const validator = new Validator();

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setIncome({ ...income, ...{ [name]: { ...income[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setIncome({ ...income, ...{ [name]: { ...income[name], ...{ error: true, data: value } } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setIncome({ ...income, ...{ [name]: { ...income[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setIncome({ ...income, ...{ [name]: { ...income[name], ...{ error: true, data: value } } } });
    }
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(income, setIncome)) {
      setFormLoading(true);
      const data = getFormData(income);

      doCreate(`assets/${assetId}/income/create`, data).then((response) => {
        if (response.data.success === true) {
          toast.success('New asset income has been recorded', successToastOptions);


          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).finally(() => {
        setFormLoading(false);
      })
    } else {
      console.log(income);
    }
  }


  return loading ? <EllipsisLoader /> : (
    <form>
      <div className="form-row">
        <div className="col-sm-12 col-md-6">
          <InputDate label="Transaction date" id='transaction_date' name='transaction_date' required handleChange={handleSelectChange} />
        </div>
        <div className="col-sm-12 col-md-6">
          <Input handleChange={handleChange} name="amount" label='Amount' type='number' required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-12 col-md-6">
          <InputSelect data={formattedIncomeAccounts} label="Income account" id='account_chart_id' name='account_chart_id' required handleChange={handleSelectChange} />
        </div>
        <div className="col-sm-12 col-md-6">
          <InputSelect data={formattedIncomeTypes} label="Income Type" id='asset_income_type_id' name='asset_income_type_id' required handleChange={handleSelectChange} />

        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Textarea name="narrative" handleChange={handleChange} label="Narrative" required />
        </div>

      </div>


      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>{formLoading ? "Creating..." : "Create"}</button>
      </div>
    </form>
  )
}

export default AddIncome