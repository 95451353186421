/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
import { validatePhoneNumber } from './helpers';
import { iFormDefaultData } from '../constants/interfaces/interfaces';

export default class Validator {
  private message = [];

  isThereEmptyFields = (state: iFormDefaultData): boolean => {
    let response = false;
    const stateArrayValues = Object.values(state);
    for (let i = 0; i < stateArrayValues.length; i += 1) {
      if (stateArrayValues[i].error) {
        response = false;
        break;
      } else {
        response = true;
      }
    }
    return response;
  };

  checkForEmptyFields = (
    stateData: iFormDefaultData,
    stateHook: React.Dispatch<React.SetStateAction<iFormDefaultData>>,
  ): boolean => {
    const stateDataCopy = { ...stateData };

    for (const x in stateDataCopy) {
      const valuesxx = stateDataCopy[x];
      const values = { ...valuesxx };
      Object.defineProperty(values, 'data', {
        writable: true,
        configurable: true,
      });
      Object.defineProperty(values, 'error', {
        writable: true,
        configurable: true,
      });
      Object.preventExtensions(values);
      if (values) {
        if (values?.data?.trim) {
          // if empty
          try {
            if (values.required) {
              if (values?.data?.trim() === '') {
                stateDataCopy[x] = { ...stateDataCopy[x], ...{ data: stateDataCopy[x].data, error: true } };
                stateHook({ ...stateDataCopy });
              }
            }
            if (values.phone) {
              if (!validatePhoneNumber(values?.data?.trim()) && (values.required || values.data.trim() !== '')) {
                stateDataCopy[x] = { ...stateDataCopy[x], ...{ data: stateDataCopy[x].data, error: true } };
                stateHook({ ...stateDataCopy });
              }
            }
          } catch (e) {
            return false;
          }
        }
      }
    }
    return this.isThereEmptyFields(stateDataCopy);
  };
}
