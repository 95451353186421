import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { updateCategory } from '../../../api/projects';
import { categoryDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useCategories from '../../../hooks/useCategories';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input'
import { EllipsisLoader } from '../../global/MyLoaders';
import TextEditor from '../../global/TextEditor';

interface ICreateCategoryProps {
  closeModal: () => void
  id: number
}

const EditCategory = ({ closeModal: onCancel, id }: ICreateCategoryProps) => {
  const [category, setCategory] = useState(categoryDefaultData);
  const [formLoading, setLoading] = useState(false);
  const { refresh, loading, selectedCategory, getSelectedCategory } = useCategories();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedCategory(Number(id)), [id]);

  const formNames = ['description', 'name'];

  useEffect(() => {
    let categoryData = categoryDefaultData;
    if (!loading) {
      for (let x of formNames) {
        categoryData = { ...categoryData, ...{ [x]: { ...categoryData[x], ...{ error: false, data: selectedCategory[x] } } } };
      }
      setCategory(categoryData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  const validator = new Validator();
  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setCategory({ ...category, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setCategory({ ...category, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleEditorChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setCategory({ ...category, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setCategory({ ...category, ...{ [name]: { error: true, data: value } } });
    }
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(category, setCategory)) {
      setLoading(true);

      const data = {
        name: category.name.data,
        description: category.description.data,
      }

      // console.log(data); return;

      try {
        const response = await updateCategory(data, id);
        if (response.data.success === true) {
          toast.success('Successfully updated category details', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            onCancel();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }

    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>
      <div className="form-row">
        <div className="col-sm-12">
          <Input required name='name' label='Category Name' handleChange={handleChange} value={category.name.data} error={category.name.error} errorMessage="Please provide the activity name" />
        </div>

      </div>



      <div className="form-row">
        <div className="col-sm-12">
          <TextEditor name='description' label='Category Description' handleChange={handleEditorChange} required value={category.description.data} error={category.description.error} errorMessage="Please provide the activity description" />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>
          {formLoading ? "Updating..." : "Update"}
        </button>
      </div>
    </form>
  )
}

export default EditCategory