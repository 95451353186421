import React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { downloadCSV } from "../../utils/helpers";

interface IMyDataTableProps {
  columns: TableColumn<unknown>[];
  title: string;
  data: Array<any>;
  [rest: string]: any;
}

const MyDataTable = ({ columns, title, data, rest }: IMyDataTableProps) => {
  const Export = ({ onExport }: any) => (
    <button
      type="button"
      className="btn btn-sm btn-primary"
      onClick={(e: any) => onExport(e.target.value)}
    >
      Export
    </button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(data, data)} />,
    []
  );
  return (
    <DataTable
      title={title}
      columns={columns}
      data={data}
      pagination
      fixedHeader
      // actions={actionsMemo}
      {...rest}
      // selectableRows
    />
  );
};

export default MyDataTable;
