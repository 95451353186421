import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllHealthTips, getHealthTip } from '../api/mobile_app';
import { RootState } from '../redux/app/store';
import { setHealthTips, setSelectedTip } from '../redux/reducer/mobile_app';

const useHealthTips = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    healthTips, selectedTip
  } = useSelector(
    (state: RootState) => state.mobleApp,
  );

  useEffect(() => {

    if (Object.keys(healthTips).length > 0) {
      setLoading(false);
      return;
    }

    getAllHealthTips().then((response) => {
      if (response.status === 200) {
        dispatch(setHealthTips(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthTips]);

  const refresh = () => {
    getAllHealthTips().then((response) => {
      if (response.status === 200) {
        dispatch(setHealthTips(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getSelectedTip = (id: number) => {
    setLoading(true);
    getHealthTip(id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedTip(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return {
    healthTips, loading, refresh, getSelectedTip, selectedTip
  }
}

export default useHealthTips