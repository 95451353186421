import React, { useState } from 'react'
import { useLastUrlSegment } from '../../../hooks/useQuery';
import useStaff from '../../../hooks/useStaff';
import Card from '../../../parts/Card';
import CardContentWrapper from '../../global/CardContentWrapper';
import CardHeaderWithBottomBorder from '../../global/CardHeaderWithBottomBorder';
import CardListItem from '../../global/CardListItem';
import { EllipsisLoader } from '../../global/MyLoaders';
import MyModal from '../../global/MyModal';
import AddReferees from './addEmployee/modals/AddReferees';

const EmployeeReferees = () => {
  const { selectedEmployee, loading } = useStaff();
  const [isOpen, setIsOpen] = useState(false);
  const id = useLastUrlSegment();
  return loading && Object.keys(selectedEmployee).length > 0 ? <EllipsisLoader /> : (
    <>
      <MyModal title={`Add ${selectedEmployee?.user?.first_name} ${selectedEmployee?.user?.last_name} referee details`} id='edit-employee-address' modalOpen={isOpen} setModalOpen={setIsOpen}>
        <AddReferees onCancel={() => setIsOpen(false)} shouldSubmit id={id} />
      </MyModal>
      <Card>
        <>
          <CardHeaderWithBottomBorder title="Referees" isButton >
            <button type='button' className='btn btn-sm btn-primary' onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
            }} > <i className='fa fa-plus'></i> Add</button>
          </CardHeaderWithBottomBorder>
          <CardContentWrapper>
            <div className='row'>
              {selectedEmployee?.referees?.length > 0 ? (
                selectedEmployee.referees.map(({ email, id, names, occupation, telephone }: any, key: number) => (
                  <React.Fragment key={id}>
                    <h5 className='m-0 ml-3 pb-2 border-bottom'>{`${key + 1}. ${names}`}</h5>
                    <CardListItem value={`${names}`} title="Names" />
                    <CardListItem value={`${occupation}`} title="Occupation" />
                    <CardListItem value={`${telephone}`} title="Telephone" />
                    <CardListItem value={`${email}`} title="Email Address" />
                    <hr />
                  </React.Fragment>

                ))
              ) : (
                <p className='row align-items-center justify-content-center text-muted w-100 py-4'>There is no referee for {`${selectedEmployee?.user?.first_name} ${selectedEmployee?.user?.last_name}`}</p>
              )}
            </div>
          </CardContentWrapper>
        </>
      </Card>
    </>
  )
}

export default EmployeeReferees