import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doGet } from "../api";
import { stockGet } from "../api/stocks";
import { RootState } from "../redux/app/store";
import {
  setClinicReportDetails,
  setClinicReports,
  setEndDate,
  setPayrollReport,
  setPrjoectReports,
  setProjectActivityReport,
  setProjectReportDetails,
  setStartDate,
} from "../redux/reducer/generalReducers";

const useReports = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    clinicReports,
    clinicReportDetails,
    projectReports,
    projectReportDetails,
    payrollReport,
    projectActivityReports,
  } = useSelector((state: RootState) => state.general);

  const getClinicReport = () => {
    if (clinicReports.length > 0) {
      setLoading(false);
      return;
    }

    stockGet("reports/clinics")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setClinicReports(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getClinicReportDetails = (id: number) => {
    stockGet("reports/clinics/" + id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setClinicReportDetails(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getProjectReport = () => {
    if (projectReports.length > 0) {
      setLoading(false);
      return;
    }

    stockGet("reports/projects")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setPrjoectReports(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getProjectReportDetails = (id: number) => {
    stockGet("reports/projects/" + id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setProjectReportDetails(response.data.data));
        }
      })
      .catch(() => {
        dispatch(setProjectReportDetails([]));
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getProjectReports = (activityId: number) => {
    doGet(`activities/${activityId}/reports`)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setProjectActivityReport(response.data.data));
        }
      })
      .catch(() => {
        //
        dispatch(setProjectActivityReport([]));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPayrollReport = () => {
    if (payrollReport.length > 0) {
      setLoading(false);
      return;
    }

    stockGet("reports/payroll")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setPayrollReport(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getPayrollReportSearch = (startDate: string, endDate: string) => {
    setLoading(true);
    const url = `payrolls?start_date=${startDate}&end_date=${endDate}`;
    stockGet(url)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setPayrollReport(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const setReportStartDate = (date: any) => {
    dispatch(setStartDate(date));
  };

  const setReportEndDate = (date: any) => {
    dispatch(setEndDate(date));
  };

  return {
    loading,
    getClinicReport,
    clinicReports,
    getClinicReportDetails,
    clinicReportDetails,
    getProjectReport,
    getProjectReportDetails,
    projectReportDetails,
    projectReports,
    getPayrollReportSearch,
    getPayrollReport,
    payrollReport,
    getProjectReports,
    projectActivityReports,
    setReportStartDate,
    setReportEndDate,
  };
};

export default useReports;
