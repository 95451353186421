import { AuthenticatedAPIInstance } from "../../utils/axios";

export const createAppointment = async (data: any, clinicId = 0) => {
  if (clinicId) {
    const response = await AuthenticatedAPIInstance.post(
      "appointments/create?clinic_id=" + clinicId,
      data
    )
      .then((response) => response)
      .catch((error) => error);
    return response;
  }
  const response = await AuthenticatedAPIInstance.post(
    "appointments/create",
    data
  )
    .then((response) => response)
    .catch((error) => error);
  return response;
};

export const getAllAppointments = async (id?: number) => {
  if (id) {
    const allStories = await AuthenticatedAPIInstance.get(
      "appointments?clinic_id=" + id
    )
      .then((response) => response)
      .catch((error) => error);
    return allStories;
  }
  const allStories = await AuthenticatedAPIInstance.get("appointments")
    .then((response) => response)
    .catch((error) => error);
  return allStories;
};

export const getAppointment = async (id: number) => {
  const response = await AuthenticatedAPIInstance.get(`appointments/${id}`)
    .then((response) => response)
    .catch((error) => error);
  return response;
};

export const getAllAppointmentTypes = async () => {
  const allStories = await AuthenticatedAPIInstance.get("/appointment-types")
    .then((response) => response)
    .catch((error) => error);
  return allStories;
};

export const getAllPatients = async (id?: number) => {
  if (id) {
    const allPatients = await AuthenticatedAPIInstance.get(
      "patients?clinic_id=" + id
    )
      .then((response) => response)
      .catch((error) => error);
    return allPatients;
  }
  const allPatients = await AuthenticatedAPIInstance.get("patients?all=true")
    .then((response) => response)
    .catch((error) => error);
  return allPatients;
};

export const getAllTreatmentReminders = async (id?: number) => {
  if (id) {
    return await AuthenticatedAPIInstance.get(
      "treatment-reminders-web?clinic_id=" + id
    )
      .then((response) => response)
      .catch((error) => error);
  }
  return await AuthenticatedAPIInstance.get("treatment-reminders-web")
    .then((response) => response)
    .catch((error) => error);
};

export const getTreatmentReminder = async (id: number) => {
  return await AuthenticatedAPIInstance.get(`treatment-reminders/${id}`)
    .then((response) => response)
    .catch((error) => error);
};
