import React, { useRef, useEffect, useContext, ReactElement } from 'react';
import { CSSTransition as ReactCSSTransition } from 'react-transition-group';

interface ICSSTransitionProps {
  show: boolean
  enter?: string,
  enterStart?: string,
  enterEnd?: string,
  leave?: string,
  leaveStart?: string,
  leaveEnd?: string,
  appear?: boolean,
  unmountOnExit?: boolean,
  tag?: string,
  children?: ReactElement,
  [x: string]: any
}

interface ITransitionContext {
  parent: any;
}

interface ITransition {
  show?: boolean;
  appear?: boolean;
  [x: string]: any

}

const TransitionContext = React.createContext<ITransitionContext>({
  parent: {},
})

function useIsInitialRender() {
  const isInitialRender = useRef(true);
  useEffect(() => {
    isInitialRender.current = false;
  }, [])
  return isInitialRender.current;
}

function CSSTransition({
  show = true,
  enter = '',
  enterStart = '',
  enterEnd = '',
  leave = '',
  leaveStart = '',
  leaveEnd = '',
  appear,
  unmountOnExit,
  tag = 'div',
  children,
  ...rest
}: ICSSTransitionProps) {
  const enterClasses = enter.split(' ').filter((s) => s.length);
  const enterStartClasses = enterStart.split(' ').filter((s) => s.length);
  const enterEndClasses = enterEnd.split(' ').filter((s) => s.length);
  const leaveClasses = leave.split(' ').filter((s) => s.length);
  const leaveStartClasses = leaveStart.split(' ').filter((s) => s.length);
  const leaveEndClasses = leaveEnd.split(' ').filter((s) => s.length);
  const removeFromDom = unmountOnExit;

  function addClasses(node: HTMLElement, classes: Array<string>) {
    classes.length && node.classList.add(...classes);
  }

  function removeClasses(node: HTMLElement, classes: Array<string>) {
    classes.length && node.classList.remove(...classes);
  }

  const nodeRef = React.useRef<HTMLDivElement>(null);
  // const Component = tag;

  return (
    <ReactCSSTransition
      appear={appear}
      nodeRef={nodeRef}
      timeout={100}
      classNames="alert"
      unmountOnExit={removeFromDom}
      in={show}
      addEndListener={(done) => {
        nodeRef && nodeRef.current && nodeRef.current.addEventListener('transitionend', done, false)
      }}
      onEnter={() => {
        if (!removeFromDom) nodeRef && nodeRef.current && nodeRef.current.setAttribute('style', 'display:none;');
        addClasses(nodeRef.current!, [...enterClasses, ...enterStartClasses])
      }}
      onEntering={() => {
        removeClasses(nodeRef.current!, enterStartClasses)
        addClasses(nodeRef.current!, enterEndClasses)
      }}
      onEntered={() => {
        removeClasses(nodeRef.current!, [...enterEndClasses, ...enterClasses])
      }}
      onExit={() => {
        addClasses(nodeRef.current!, [...leaveClasses, ...leaveStartClasses])
      }}
      onExiting={() => {
        removeClasses(nodeRef.current!, leaveStartClasses)
        addClasses(nodeRef.current!, leaveEndClasses)
      }}
      onExited={() => {
        removeClasses(nodeRef.current!, [...leaveEndClasses, ...leaveClasses])
        if (!removeFromDom) nodeRef.current!.style.display = 'none';
      }}
    >
      <div ref={nodeRef} {...rest} style={{ display: !removeFromDom ? 'none' : 'flex' }} >{children}</div>
    </ReactCSSTransition>
  )
}

// 0705 017 619

function Transition({ show, appear, ...rest }: ITransition) {
  const { parent } = useContext(TransitionContext);
  const isInitialRender = useIsInitialRender();
  const isChild = show === undefined;

  if (isChild) {
    return (
      <CSSTransition
        appear={parent.appear || !parent.isInitialRender}
        show={parent.show}
        {...rest}
      />
    )
  }

  return (
    <TransitionContext.Provider
      value={{
        parent: {
          show,
          isInitialRender,
          appear,
        },
      }}
    >
      <CSSTransition appear={appear} show={show} {...rest} />
    </TransitionContext.Provider>
  )
}

export default Transition;