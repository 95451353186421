import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doGet } from "../api";
import { RootState } from "../redux/app/store";
import {
  setBills,
  setSelectedBill,
  setSelectedBills,
} from "../redux/reducer/accounting";

const useBills = () => {
  const [loading, setLoading] = useState(false);
  const { bills, selectedBills, selectedBill } = useSelector(
    (state: RootState) => state.accounting
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (bills.length > 0) return;
    setLoading(true);
    doGet("bills")
      .then((response) => {
        dispatch(setBills(response.data.data));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    doGet("bills")
      .then((response) => {
        dispatch(setBills(response.data.data));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getBudget = (id: number) => {
    setLoading(true);
    doGet("budget/" + id)
      .then((response) => {
        dispatch(setSelectedBills(response.data.data));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getBill = (id: number) => {
    doGet("bills/" + id)
      .then((response) => {
        dispatch(setSelectedBill(response.data.data));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    selectedBills,
    bills,
    selectedBill,
    refresh,
    getBudget,
    getBill,
  };
};

export default useBills;
