import { ReactElement } from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    overflow: 'auto',
    zIndex: 999,
  },
  // overlay: {
  //   position: "fixed",
  //   display: "flex",
  //   justifyContent: "center",
  //   top: "0",
  //   left: "0",
  //   width: "100%",
  //   height: "100%",
  //   backgroundColor: "rgba(0,0,0, .8)",
  //   zIndex: "1000",
  //   overflowY: "auto"
  // }
};



Modal.setAppElement('#root');

interface IAppModalProps {
  isOpen: boolean;
  title: string;
  closeModal: () => void;
  children: ReactElement;
}

const AppModal = ({ isOpen, closeModal, children, title }: IAppModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"

    >
      <>

        <div className="row align-items-center pb-3">
          <div className="col-8">
            <h5>{title}</h5>
          </div>
          <div className="col-4 row justify-content-end">
            <span className='fa fa-2x fa-window-close' style={{ cursor: 'pointer' }} onClick={closeModal} />
          </div>
        </div>
        <hr />
        {children}
      </>
    </Modal>
  )
}

export default AppModal