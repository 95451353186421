import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { ERRORMESSAGE, errorToastOptions, invoiceDefaultData, paymentStatuses, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useSuppliers from '../../../hooks/useSuppliers';
import { formatToObject, getFormData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import DynamicInvoiceForm from '../../global/DynamicInvoiceForm';
import Input from '../../global/Input';
import InputDate from '../../global/InputDate';
import InputSelect from '../../global/InputSelect';
import { EllipsisLoader } from '../../global/MyLoaders';
import TextEditor from '../../global/TextEditor';

interface IAddInvoiceProps {
  refresh: () => void;
  closeModal: () => void;
}

const AddInvoice = ({ closeModal, refresh }: IAddInvoiceProps) => {
  const [formLoading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState(invoiceDefaultData);
  const defaultData = [{ product_name: "", amount: "", sub_total: "", quantity: "" }];
  const [values, setValues] = useState<Array<any>>(defaultData);

  const { loading, formattedSupplier } = useSuppliers();

  const validator = new Validator();

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(invoice, setInvoice)) {
      setLoading(true);
      const formData = getFormData(invoice);
      const data = {
        ...formData, ...{ items: values }, ...formatToObject(values, invoice)
      }

      doCreate('invoices/create', data).then((response) => {
        if (response.data.success === true) {
          toast.success('Invoice details have been successfully submitted', successToastOptions);
          refresh();

          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).catch(() => {
        toast.error(ERRORMESSAGE, errorToastOptions);
      }).finally(() => {
        setLoading(false);
      })
    } else {
      console.log(invoice);
    }
  }



  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setInvoice({ ...invoice, ...{ [name]: { ...invoice[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setInvoice({ ...invoice, ...{ [name]: { ...invoice[name], ...{ error: true, data: value } } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setInvoice({ ...invoice, ...{ [name]: { ...invoice[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setInvoice({ ...invoice, ...{ [name]: { ...invoice[name], ...{ error: true, data: value } } } });
    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>

      <div className="row">
        <div className="col-sm-8">
          <div className="form-row">
            {/* <div className="col-sm-6">
              <InputSelect data={formattedStockProducts} id='product' name='product' label='Product' handleChange={() => ""} required error={false} errorMessage="Please select the department to handle this invoice" />
            </div> */}

            <div className="col-sm-12">
              <label>Products</label>
              <DynamicInvoiceForm defaultData={defaultData} setValues={setValues} values={values} />
            </div>
          </div>

          <div className="form-row">
            <div className="col-sm-12">
              <TextEditor handleChange={handleSelectChange} name='note' error={false} errorMessage='Please provide a note' value={invoice.note.data} label='Note' id='note' required />
            </div>
            <div className="col-sm-12">
              <TextEditor handleChange={handleSelectChange} name='terms_and_conditions' error={false} errorMessage='Please enter the terms and conditions' label='Terms & conditions' id='terms_and_conditions' required value={invoice.terms_and_conditions.data} />
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-row">
            <div className="col-sm-12">
              <InputSelect data={formattedSupplier} id='client_id' name='client_id' label='Client' handleChange={handleSelectChange} required error={false} errorMessage="Please select the client" />
            </div>
            <div className="col-sm-12">
              <Input id='serial_no' name='serial_no' label='Serial no.' handleChange={handleChange} required error={false} errorMessage="Please enter the Serial number" value={invoice.serial_no.data} />
            </div>
            <div className="col-sm-12">
              <InputSelect data={paymentStatuses} id='payment_status' name='payment_status' label='Payment status' handleChange={handleSelectChange} required error={false} errorMessage="Please select the payment status" />
            </div>

            <div className="col-sm-12">
              <Input type='number' id='discount' name='discount' label='Discount' handleChange={handleChange} error={false} errorMessage="Please enter the Serial number" value={invoice.discount.data} />
            </div>

            <div className="col-sm-12">
              <InputDate id='invoiced_on' name='invoiced_on' label='Date created' handleChange={handleSelectChange} error={false} errorMessage="Please enter the date created" value={invoice.invoiced_on.data} />
            </div>

            <div className="col-sm-12">
              <InputDate id='due_date' name='due_date' label='Due date' handleChange={handleSelectChange} error={false} errorMessage="Please enter the due date" value={invoice.due_date.data} />
            </div>
          </div>
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  )
}

export default AddInvoice