import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { doGet } from '../api';
import { RootState } from '../redux/app/store';
import { setSelectedUnit, setUnits } from '../redux/reducer/settings';

const useUnits = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const {
    units, selectedUnit
  } = useSelector(
    (state: RootState) => state.settings,
  );
  useEffect(() => {
    setLoading(true);
    if (units.length > 0) {
      setLoading(false);
      return;
    }

    doGet('units').then((response) => {
      if (response.status === 200) {
        dispatch(setUnits(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getSelectedUnit = (id: number) => {
    setLoading(true);
    doGet('units/' + id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedUnit(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }


  const refresh = () => {
    doGet('units').then((response) => {
      if (response.status === 200) {
        dispatch(setUnits(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return { units, loading, refresh, getSelectedUnit, selectedUnit }
}

export default useUnits