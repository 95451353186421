import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import ComingSoon from '../../components/global/ComingSoon'
// import ListStaffPerformance from '../../components/hr/staff_performance/ListStaffPerformance'
import Breadcrumbs from '../../parts/Breadcrumbs'

const StaffPerformance = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Staff Performance' />
        {/* <ListStaffPerformance /> */}
        <ComingSoon />
      </>
    </DashboardWrapper>
  )
}

export default StaffPerformance