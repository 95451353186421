import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import ViewProjectDetails from '../../components/project/dashboard/ViewProjectDetails'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const ProjectDetails = () => {
  return (
    <DashboardWrapper >
      <>
        <Breadcrumbs title='Project details' parents={[{ link: ROUTES.PROJECTS, title: 'Projects' }]} />
        <ViewProjectDetails />

      </>
    </DashboardWrapper >
  )
}

export default ProjectDetails