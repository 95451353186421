import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import ProjectsDashboard from '../../components/project/dashboard'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Projects = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Projects' />
        <ProjectsDashboard />

      </>
    </DashboardWrapper>
  )
}

export default Projects