import React from 'react'
import ListPayrolls from '../../../components/hr/payrollManagement/ListPayrolls'
import PayrollWrapper from '../../../components/PayrollWrapper'
import Breadcrumbs from '../../../parts/Breadcrumbs'

const PayrollManagement = () => {
  return (
    <PayrollWrapper>
      <>
        <Breadcrumbs title='Payroll Management' />
        <ListPayrolls />
      </>
    </PayrollWrapper>
  )
}

export default PayrollManagement