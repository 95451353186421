import React, { useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../../api";
import {
  ERRORMESSAGE,
  errorToastOptions,
  successToastOptions,
} from "../../../../constants";
import { formatErrorMessage } from "../../../../helpers";
import useStaff from "../../../../hooks/useStaff";
import { EllipsisLoader } from "../../../global/MyLoaders";
import useUsers from "../../../../hooks/useUsers";

interface IAddMemberProps {
  closeModal: () => void;
  refresh: () => void;
  id: number;
}

const AddMember = ({ closeModal, refresh, id }: IAddMemberProps) => {
  const [formLoading, setLoading] = useState(false);
  const [assign, setAssign] = useState<Array<any>>([]);
  // const { employees, loading } = useStaff();

  const { loading, users } = useUsers();

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, checked } = e.target as HTMLInputElement;
    if (checked) {
      let newArray = [...assign, value];
      setAssign(newArray);
    } else {
      let newArray = assign.filter(
        (item: any) => Number(item) !== Number(value)
      );
      setAssign(newArray);
    }
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.preventDefault();
    if (assign.length === 0) {
      toast.error("Please select atleast one member", errorToastOptions);
      return;
    }

    const data = { user_id: assign };

    setLoading(true);
    try {
      const response = await doCreate("reviewing-commitee/create", data);
      if (response.data.success === true) {
        toast.success(
          "New reviewing member added successfully",
          successToastOptions
        );
        refresh(); // refresh the hook store
        setTimeout(() => {
          closeModal();
        }, 1000);
      } else {
        toast.error(
          formatErrorMessage(response.data.data, response.data.message),
          errorToastOptions
        );
      }
    } catch (err) {
      toast.error(ERRORMESSAGE, errorToastOptions);
    } finally {
      setLoading(false);
    }
  };
  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      <div className="form-row">
        {users.map((user: any) => (
          <div className="col-sm-4" key={user?.id}>
            <div key={user.id} className="remember-checkbox p-10">
              <input
                type="checkbox"
                className="form-control"
                name="assign"
                id={user?.id}
                value={user?.id}
                onChange={handleChange}
              />
              <label htmlFor={user?.id} className="m-0">
                {" "}
                {`${user?.first_name} ${user?.last_name}`}
              </label>
            </div>
          </div>
        ))}
      </div>
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddMember;
