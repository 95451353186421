import React from "react";
import DashboardWrapper from "../../components/DashboardWrapper";
import ReportsDashboard from "../../components/reports/ReportsDashboard";
import Breadcrumbs from "../../parts/Breadcrumbs";
import { ROUTES } from "../../routes/routes";
import GenerateReportsViewHeader from "../../components/reports/GenerateReportsViewHeader";

const Reports = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs
          title="Reports"
          parents={[{ link: ROUTES.PROJECTS, title: "Reports" }]}
        />
        <div className="py-3">
          <GenerateReportsViewHeader />
        </div>
        <ReportsDashboard />
      </>
    </DashboardWrapper>
  );
};

export default Reports;
