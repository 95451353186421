import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/app/store";
import { doGet } from "../api";
import { setCashFlow } from "../redux/reducer/accounting";

const useCashFlow = () => {
  const [loading, setLoading] = useState(false);
  const { cashFlow } = useSelector((state: RootState) => state.accounting);

  const dispatch = useDispatch();

  const getCashFlow = () => {
    setLoading(true);
    doGet("reports/cash_flow")
      .then((response) => {
        dispatch(setCashFlow(response.data.data));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  return {
    cashFlow,
    loading,
    getCashFlow,
  };
};

export default useCashFlow;
