import React from "react";
import { Helmet } from "react-helmet";
import DashboardWrapper from "../../components/DashboardWrapper";
import AppointmentTable from "../../components/doctor/appointments/AppointmentTable";

import Breadcrumbs from "../../parts/Breadcrumbs";

const Appointments = () => {
  return (
    <>
      <Helmet>
        <title>TFC</title>
      </Helmet>
      <DashboardWrapper>
        <>
          <Breadcrumbs title="Appointments" />
          <AppointmentTable />
        </>
      </DashboardWrapper>
    </>
  );
};

export default Appointments;
