import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { budgetDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useBudget from '../../../hooks/useBudget';
import { getFormData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputDate from '../../global/InputDate';
import { EllipsisLoader } from '../../global/MyLoaders';

interface IEditBudgetProps {
  closeModal: () => void;
  refresh: () => void;
  id: number;
}

const EditBudget = ({ closeModal, refresh, id }: IEditBudgetProps) => {
  const [budget, setBudget] = useState(budgetDefaultData);
  const [formLoading, setLoading] = useState(false);
  const validator = new Validator();

  const { loading, getBudget, selectedBudget } = useBudget();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getBudget(id), [id]);

  const formNames = ['start_date', 'end_date', 'exp_amount', 'name'];

  useEffect(() => {
    let budgetData = budgetDefaultData;
    if (!loading) {
      for (let x of formNames) {
        budgetData = { ...budgetData, ...{ [x]: { ...budgetData[x], ...{ error: false, data: selectedBudget[x] } } } };
      }
      setBudget(budgetData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setBudget({ ...budget, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setBudget({ ...budget, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleDateChange = (e: any) => {
    const { name, value } = e;
    if (value !== '') {
      // update the state
      setBudget({ ...budget, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setBudget({ ...budget, ...{ [name]: { error: true, data: value } } });
    }
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(budget, setBudget)) {
      setLoading(true);

      const data = getFormData(budget);

      try {
        const response = await doCreate('budget/' + id + '/update', data);
        if (response.data.success === true) {
          toast.success('Budget details updated successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }
    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>

      <div className="form-row">

        <div className="col-sm-6">
          <Input name='name' label='Name' handleChange={handleChange} error={budget?.name.error} errorMessage="Please provide the name" value={budget?.name.data} required />
        </div>

        <div className="col-sm-6">
          <Input type='number' name='exp_amount' label='Estimated Amount' handleChange={handleChange} error={budget?.exp_amount.error} errorMessage="Please provide the estimated amount" value={budget?.exp_amount.data} required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputDate name='start_date' label='Start Date' handleChange={handleDateChange} error={budget?.start_date.error} errorMessage="Please select a start date" value={budget?.start_date.data} required />
        </div>
        <div className="col-sm-6">
          <InputDate name='end_date' label='End Date' handleChange={handleDateChange} error={budget?.end_date.error} errorMessage="Please select an end date" value={budget?.end_date.data} required noLimit />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn btn-primary' onClick={handleSubmit}>{formLoading ? "Updating..." : "Update"}</button>
      </div>
    </form>
  )
}

export default EditBudget