import React from "react";
import DashboardWrapper from "../../components/DashboardWrapper";
import Breadcrumbs from "../../parts/Breadcrumbs";
import ListEquity from "../../components/accounting/equity/ListEquity";

const Equity = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title="Owner's Equity" />
        {/* body */}
        <ListEquity />
      </>
    </DashboardWrapper>
  );
};

export default Equity;
