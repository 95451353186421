import React, { useState } from 'react'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormInput } from '../../../global/Input';

interface ICreateProjectProps {
  setAddName: (id: boolean) => void;
  setColumns: (items: any) => void;
}

const AddServiceName = ({ setAddName, setColumns }: ICreateProjectProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <Formik initialValues={{
      name: '',
    }}
      validationSchema={Yup.object({
        name: Yup.string().required('The service name is required'),
      })}
      onSubmit={async values => {

        setLoading(true);
        setColumns(values.name);
        setAddName(false);
        setLoading(false);
      }} >
      <Form >

        <FormInput
          label="Service name"
          name="name" />
        <div className="form-row m-0 pb-4 pt-3 justify-content-end">

          <button type='reset' className='btn btn-outline-danger mr-3' onClick={() => setAddName(false)}>Cancel</button>
          <button type='submit' disabled={loading} className='btn  btn-info' >
            {loading ? "Creating..." : "Create"}
          </button>
        </div>

      </Form>
    </Formik>
  )
}

export default AddServiceName