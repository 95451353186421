import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { stockGet } from '../api/stocks';
import { RootState } from '../redux/app/store';
import { setClinicStats } from '../redux/reducer/generalReducers';



const useClinicStats = (clinicId: number) => {
  console.log(clinicId);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    clinicStats
  } = useSelector(
    (state: RootState) => state.general,
  );

  useEffect(() => {


    stockGet('stats/clinic?clinic_id=' + clinicId).then((response) => {
      if (response.status === 200) {
        dispatch(setClinicStats(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    setLoading(true);
    stockGet('stats/clinic').then((response) => {
      if (response.status === 200) {
        dispatch(setClinicStats(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return { loading, refresh, clinicStats }
}

export default useClinicStats