
import { createSlice } from '@reduxjs/toolkit';

export interface IMobileApp {
  stories: any;
  funFacts: any;
  healthTips: any;
  selectedStory: any;
  selectedFunFact: any;
  selectedTip: any;
}

const initialState: IMobileApp = {
  stories: {},
  funFacts: {},
  healthTips: {},
  selectedStory: {},
  selectedFunFact: {},
  selectedTip: {},
};

export const mobileAppReducer = createSlice({
  name: 'mobileApp',
  initialState,
  reducers: {
    setStories: (state, { payload }) => {
      state.stories = payload;
    },
    setFunFacts: (state, { payload }) => {
      state.funFacts = payload
    },
    setHealthTips: (state, { payload }) => {
      state.healthTips = payload
    },
    setSelectedStory: (state, { payload }) => {
      state.selectedStory = payload
    },
    setSelectedFunFact: (state, { payload }) => {
      state.selectedFunFact = payload
    },
    setSelectedTip: (state, { payload }) => {
      state.selectedTip = payload
    },
  },
});

const { reducer, actions } = mobileAppReducer;

export const {
  setStories, setFunFacts, setHealthTips, setSelectedStory, setSelectedFunFact, setSelectedTip
} = actions;

export default reducer;