import React from "react";
import useSessionStorage from "../../hooks/useSessionStorage";
import ListBalancesheet from "../accounting/balanceSheet/ListBalancesheet";
import ListIncomeStatement from "../accounting/incomeStatement/ListIncomeStatement";
import { MyMultipleTabs, IMultipleTabsData } from "../global/MyTab";
import ListStockReports from "../stocks/reports/ListStockReports";
import ClinicReport from "./ClinicReport";
import ConsolidatedMonthlyReport from "./ConsolidatedMonthlyReport";
import DailyReport from "./DailyReport";
// import EmployeeReport from './EmployeeReport';
import PayrollReport from "./PayrollReport";
import ProjectReport from "./ProjectReport";
import ListTrailBalance from "../accounting/trail_balance/ListTrailBalance";
import BudgetComparisionReport from "../accounting/budget_comparision/BudgetComparisionReport";
import ListDailySummations from "../accounting/daily_summations/ListDailySummations";
import ListSchedules from "../accounting/schedules/listSchedules";
import NewBalanceSheet from "../accounting/balanceSheet/NewBalanceSheet";
import NewIncomeStatement from "../accounting/incomeStatement/NewIncomeStatement";
import ListCashFlow from "../accounting/cashFlow/ListCashFlow";

const ReportsDashboard = () => {
  const { isValidRole } = useSessionStorage();

  const ApplicationsTabsData: IMultipleTabsData[] = [
    // isValidRole([1, 3, 4]) && {
    //   name: "Accounting reports",
    //   tabId: 1,
    //   data: [
    //     {
    //       tabId: 1,
    //       title: "Trial Balance",
    //       component: <ListTrailBalance />,
    //       large: true,
    //     },
    //     {
    //       tabId: 2,
    //       title: "Income statement",
    //       component: <NewIncomeStatement />,
    //       large: true,
    //     },
    //     {
    //       tabId: 3,
    //       title: "Balance sheet",
    //       component: <NewBalanceSheet />,
    //       large: true,
    //     },
    //     {
    //       tabId: 4,
    //       title: "Schedules",
    //       component: <ListSchedules />,
    //       large: true,
    //     },
    //     {
    //       tabId: 5,
    //       title: "Cash Flow",
    //       component: <ListCashFlow />,
    //       large: true,
    //     },
    //     {
    //       tabId: 6,
    //       title: "Budget Comparision",
    //       component: <BudgetComparisionReport />,
    //       large: true,
    //     },
    //     {
    //       tabId: 7,
    //       title: "Payroll Report",
    //       component: <PayrollReport />,
    //       large: true,
    //     },
    //   ],
    // },

    isValidRole([1, 3, 4]) && {
      name: "Stock report",
      tabId: 1,
      data: [
        {
          tabId: 1,
          title: "General",
          component: <ListStockReports />,
        },
      ],
    },

    // isValidRole([1, 3, 4, 9, 8]) && {
    //   name: "Clinic reports",
    //   tabId: 5,
    //   data: [
    //     {
    //       tabId: 1,
    //       title: "General",
    //       component: <ClinicReport />,
    //     },
    //     {
    //       tabId: 2,
    //       title: "Monthly report",
    //       component: <ConsolidatedMonthlyReport />,
    //     },
    //   ],
    // },
    {
      name: "General Reports",
      tabId: 4,
      data: [
        {
          tabId: 1,
          title: "Daily Log(s)",
          component: <DailyReport />,
          large: true,
        },
        // {
        //   tabId: 5,
        //   title: 'Employees Report',
        //   component: <EmployeeReport />,
        // }
      ],
    },
  ];

  return (
    <div style={{ background: "#e7ebf4" }}>
      {/* <MyTabs
        TabsData={ApplicationsTabsData}
      /> */}
      <MyMultipleTabs multipleData={ApplicationsTabsData} />
    </div>
  );
};

export default ReportsDashboard;
