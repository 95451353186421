import React from 'react'
import ClinicWrapper from '../../components/ClinicWrapper'
import ListTreatments from '../../components/doctor/treatments/ListTreatments'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const ClinicTreatments = () => {
  return (
    <ClinicWrapper>
      <>
        <Breadcrumbs title='Clinic treatments' parents={[{ link: ROUTES.CLINICS, title: 'Clinics' }]} />

        <ListTreatments />

      </>
    </ClinicWrapper>
  )
}

export default ClinicTreatments