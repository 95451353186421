import React, { useEffect, useState } from "react";
import { doGet } from "../../../api";
import useBudget from "../../../hooks/useBudget";
import Card from "../../../parts/Card";
import ConfirmDelete from "../../global/ConfirmDelete";
import ModalSwitcher from "../../global/ModalSwitcher";
import { EllipsisLoader } from "../../global/MyLoaders";
import AddBudgetItems from "./AddBudgetItems";
import EditBudget from "./EditBudget";
import { formatCurrency } from "../../../utils/helpers";

interface IViewBudgetItemsProps {
  id: number;
}

const ViewBudgetItems = ({ id }: IViewBudgetItemsProps) => {
  const [items, setItems] = useState([]);
  const [incomeAccounts, setincomeAccounts] = useState<any>([]);
  const [expenseAccounts, setExpenseAccounts] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const { getBudget, selectedBudget } = useBudget();

  useEffect(() => {
    doGet(`budget/${id}/get_budget_items`)
      .then((response) => {
        setItems(response.data.data);
        // console.log(response.data.data?.filter((item: any) => item?.item_type === 1));
        setincomeAccounts(
          response.data.data
            ?.filter((item: any) => item?.item_type === 1)
            .map((acc: any) => acc.account_chart_id)
        );
        setExpenseAccounts(
          response.data.data
            ?.filter((item: any) => item?.item_type === 2)
            .map((acc: any) => acc.account_chart_id)
        );
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getBudget(id), [id]);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleEditClick = () => {
    setOpenModal(2); // this opens the edit modal
    setIsOpen(true);
  };

  // const handleDeleteClick = () => {
  //   setOpenModal(3);
  //   setIsOpen(true);
  // }

  const handleAddItemClick = () => {
    setOpenModal(4);
    setIsOpen(true);
  };

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = "edit-budget";
      modalTitle = "Edit Budget";
      ModalToRender = EditBudget;

      break;
    case 3:
      modalId = "delete-budget";
      modalTitle = "";
      ModalToRender = ConfirmDelete;
      break;
    default:
      modalId = "add-budget-items";
      modalTitle = "Add Budget items";
      ModalToRender = AddBudgetItems;
      break;
  }
  return loading ? (
    <EllipsisLoader />
  ) : (
    <>
      <ModalSwitcher
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={modalId}
        title={modalTitle}
        component={
          <ModalToRender
            id={id}
            closeModal={handleModalClick}
            path={`budget/${id}/delete`}
            refresh={() => getBudget(id)}
            expenseAccount={expenseAccounts}
            incomeAccount={incomeAccounts}
          />
        }
      />
      <div className="row">
        <div className="col-sm-6">
          <Card>
            <div className="row">
              <div className="col-sm-12 row">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditClick();
                  }}
                  className="btn btn-sm btn-primary ml-auto order-2 "
                >
                  <i className="fa fa-edit"></i> Edit
                </button>
              </div>
              <div className="col-sm-12">
                <p>Budget Name</p>
                <p>
                  <strong>{selectedBudget?.name}</strong>
                </p>
              </div>
              <div className="col-sm-12 py-3">
                <p>Estimated Budget Amount</p>
                <p>
                  <strong>{formatCurrency(selectedBudget?.exp_amount)}</strong>
                </p>
              </div>
              <div className="col-sm-12">
                <p>Start date</p>
                <p>
                  <strong>{selectedBudget?.start_date}</strong>
                </p>
              </div>
              <div className="col-sm-12 py-3">
                <p>End Date</p>
                <p>
                  <strong>{selectedBudget?.end_date}</strong>
                </p>
              </div>
            </div>
          </Card>
        </div>

        <div className="col-sm-6">
          <Card>
            <>
              <div className="col-sm-12 row">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddItemClick();
                  }}
                  className="btn btn-sm btn-primary ml-auto order-2 "
                >
                  <i className="fa fa-plus"></i> Add More
                </button>
              </div>
              <h6>Income Accounts</h6>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Account name</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {items
                    .filter((item: any) => item?.item_type === 1)
                    .map((res: any) => (
                      <tr key={res?.id}>
                        <td>{`${res?.account?.account_code} ${res?.account?.account_name}`}</td>
                        <td>{res?.amount}</td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <h6>Expense Accounts</h6>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Account name</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {items
                    .filter((item: any) => item?.item_type === 2)
                    .map((res: any) => (
                      <tr key={res?.id}>
                        <td>{`${res?.account?.account_code} ${res?.account?.account_name}`}</td>
                        <td>{res?.amount}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ViewBudgetItems;
