import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { ERRORMESSAGE, errorToastOptions, funderDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import { getFormData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
interface IAddFunder {
  closeModal: () => void;
  refresh: () => void;
}
const AddFunder = ({ closeModal, refresh }: IAddFunder) => {
  const [funder, setFunder] = useState(funderDefaultData);
  const [loading, setLoading] = useState(false);
  const validator = new Validator();

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setFunder({ ...funder, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setFunder({ ...funder, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(funder, setFunder)) {
      setLoading(true);

      const data = getFormData(funder);
      try {
        const response = await doCreate('funders/create', data);
        if (response.data.success === true) {
          toast.success('New funder created successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <form>

      <div className="form-row">
        <div className="col-sm-6">
          <Input name='name' label='Funder Name' handleChange={handleChange} error={funder?.name.error} errorMessage="Please provide a funder name" value={funder?.name.data} required />
        </div>
        <div className="col-sm-6">
          <Input name='amount' type='number' label='Amount' handleChange={handleChange} error={funder?.amount.error} errorMessage="Please enter the amount" value={funder?.amount.data} required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input name='telephone' type='tel' label='Contact Number' handleChange={handleChange} error={funder?.telephone.error} errorMessage="Please provide a contact number" value={funder?.telephone.data} required />
        </div>
        <div className="col-sm-6">
          <Input name='email' type='email' label='Funder email' handleChange={handleChange} error={funder?.email.error} errorMessage="Please provide the funder email" value={funder?.email.data} />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input name='telephone_2' type='tel' label='Contact Number 2' handleChange={handleChange} error={funder?.telephone_2.error} errorMessage="Please provide a contact number" value={funder?.telephone_2.data} />
        </div>
      </div>


      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={loading} className='btn  btn-info' onClick={handleSubmit}>{loading ? "Creating..." : "Create"}</button>
      </div>
    </form>
  )
}

export default AddFunder