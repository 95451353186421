import React, { useEffect, useState } from 'react'
import useAppointments from '../../../hooks/useAppointments';
import InputDate from '../../global/InputDate';
import InputSelect from '../../global/InputSelect';
import MySwitch from '../../global/MySwitch';
import { appointmentDefaultData, ERRORMESSAGE, errorToastOptions, reminderUnits, successToastOptions } from "../../../constants";
import Validator from '../../../utils/Validator';
import toast from 'react-hot-toast';
import { formatErrorMessage } from '../../../helpers';
import { createAppointment } from '../../../api/doctor';
import { iFormDefaultData } from '../../../utils/interfaces';
import { useLastUrlSegment } from '../../../hooks/useQuery';
interface IAddAppointmentProps {
  closeModal: () => void;
  appointment_id: number
}

const EditAppointment = ({ closeModal, appointment_id }: IAddAppointmentProps) => {
  const id = useLastUrlSegment();
  const [loading, setLoading] = useState(false);
  const [appointment, setAppointment] = useState(appointmentDefaultData);
  const [isChecked, setIsChecked] = useState(false);
  const { getAppointmentTypes, appointmentTypes, getPatientsSelectData, refresh, selectedAppointment, getSelectedAppointment
  } = useAppointments(id);


  const validator = new Validator();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedAppointment(Number(appointment_id)), [appointment_id]);

  const formNames = ['appointment_type', 'patient_id', 'appointment_datetime', 'has_reminder', 'reminder'];

  useEffect(() => {
    let appointmentData = appointmentDefaultData;
    if (!loading) {
      for (let x of formNames) {
        appointmentData = { ...appointmentData, ...{ [x]: { ...appointmentData[x], ...{ error: false, data: selectedAppointment[x] } } } } as iFormDefaultData;
      }
      setAppointment(appointmentData);
      setIsChecked(selectedAppointment['has_reminder']);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    return getAppointmentTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    return getPatientsSelectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setAppointment({ ...appointment, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setAppointment({ ...appointment, ...{ [name]: { error: true, data: value } } });
    }
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(appointment, setAppointment)) {
      setLoading(true);

      const data = {
        appointment_type: appointment.appointment_type.data,
        patient_id: appointment.patient_id.data,
        appointment_datetime: appointment.appointment_datetime.data,
        has_reminder: isChecked
      }
      try {
        const newProject = await createAppointment(data);
        if (newProject.data.success === true) {
          toast.success('New appointment created successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(newProject.data.data), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }


    }
  }

  return (
    <form>
      <div className='form-row'>
        <div className="col-sm-6">
          <InputSelect id='appointment_type' label='Appointment type' name='appointment_type' data={appointmentTypes} handleChange={handleSelectChange} required value={{ label: appointment.appointment_type.data, value: appointment.appointment_type.data }} />
        </div>

      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputDate label='Appointment date' name='appointment_datetime' withTime handleChange={handleSelectChange} required value={appointment.appointment_datetime.data} />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <MySwitch checked={isChecked} handleChange={() => setIsChecked((prev) => !prev)} label="Set reminder" />
        </div>

        <div className="col-sm-6">
          {isChecked && (<InputSelect id='reminder' label='Reminder' name='reminder' handleChange={handleSelectChange} data={reminderUnits} required={isChecked} value={{ label: appointment.reminder.data, value: appointment.reminder.data }} />)}
        </div>
      </div>


      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={loading} className='btn  btn-info' onClick={handleSubmit}>{loading ? "Creating..." : "Create"}</button>
      </div>

    </form>
  )
}

export default EditAppointment