import React from 'react'
import ListAssetTypes from '../../components/assetManagement/ListAssetTypes'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const AssetTypes = () => {
  return (
    <SettingsWrapper>
      <>
        <Breadcrumbs title='Asset types' parents={[{ link: ROUTES.ASSETS, title: 'Asset Management' }]} />
        {/* body */}
        <ListAssetTypes />
      </>
    </SettingsWrapper>

  )
}

export default AssetTypes