import React from 'react';
import DocViewer from "react-doc-viewer";

interface IDocProps {
  uri: string;
}

interface IMyDocViewerProps {
  docs: Array<IDocProps>
}

const MyDocViewer = ({ docs }: IMyDocViewerProps) => {
  return (
    <DocViewer documents={docs} config={{
      header: {
        disableHeader: false,
        disableFileName: false,
        retainURLParams: false,
      },
    }} />
  )
}

export default MyDocViewer