import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stockGet } from "../api/stocks";
import { RootState } from "../redux/app/store";
import {
  setAccountingCategories,
  setFormattedAccountingCategories,
  setLedgerReportCategory,
} from "../redux/reducer/accounting";
import { formatAccountSubCategorySelectData } from "../utils/helpers";
import { doGet } from "../api";

const useAccountCategories = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    accountCategories,
    formattedAccountingCategories,
    ledgerReportCategory,
  } = useSelector((state: RootState) => state.accounting);

  useEffect(() => {
    if (accountCategories.length > 0) {
      setLoading(false);
      return;
    }

    stockGet("account-subcategories")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setAccountingCategories(response.data.data));
          dispatch(
            setFormattedAccountingCategories(
              formatAccountSubCategorySelectData(response.data.data)
            )
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLedgerReportCategory = () => {
    setLoading(true);
    doGet("ledger_report_categories")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setLedgerReportCategory(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    accountCategories,
    loading,
    formattedAccountingCategories,
    getLedgerReportCategory,
    ledgerReportCategory,
  };
};

export default useAccountCategories;
