import React from 'react'
import ListTransactions from '../../components/accounting/transactions/ListTransactions'
import DashboardWrapper from '../../components/DashboardWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Transactions = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Transactions' />
        {/* body */}
        <ListTransactions />
      </>
    </DashboardWrapper>
  )
}

export default Transactions