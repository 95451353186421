import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useAccounts from '../../../hooks/useAccounts';
import DynamicFormBudget from '../../global/DynamicFormBudget';

interface IAddBudgetItemsProps {
  closeModal: () => void;
  refresh: () => void;
  id: number;
  incomeAccount: Array<any>;
  expenseAccount: Array<any>;
}


const AddBudgetItems = ({ closeModal, id, refresh, expenseAccount, incomeAccount }: IAddBudgetItemsProps) => {
  const defaultDataIncome = [{ account_chart_id: '', amount: '', item_type: 1 }];
  const defaultDataExpense = [{ account_chart_id: '', amount: '', item_type: 2 }];
  const [formLoading, setLoading] = useState(false);
  const [incomeValues, setIncomeValues] = useState(defaultDataIncome);
  const [expenseValues, setExpenseValues] = useState(defaultDataExpense);
  const { loading, accounts, expenseAccounts, getExpenseAccount, incomeAccounts, getIncomeAccount } = useAccounts();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getExpenseAccount(), [accounts]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getIncomeAccount(), [accounts]);

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    if (incomeValues.length === 0 || expenseValues.length === 0) {
      toast.error("Please add atleast one item to the budget", errorToastOptions);
    }

    setLoading(true);

    const filteredIncomeValues = incomeValues.filter((v) => v.amount !== '' && v.account_chart_id !== '');
    const filteredExpenseValues = expenseValues.filter((v) => v.amount !== '' && v.account_chart_id !== '');

    const items = [...filteredIncomeValues, ...filteredExpenseValues];
    const data = {
      items: items,
      budget_id: id
    }

    try {
      const response = await doCreate('budget/' + id + '/budget_items', data);
      if (response.data.success === true) {
        toast.success('Successfully attached budget items', successToastOptions);
        refresh(); // refresh the hook store
        setTimeout(() => {
          closeModal();
        }, 1000);
      } else {
        toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
      }
    } catch (err) {
      toast.error(ERRORMESSAGE, errorToastOptions);
    } finally {
      setLoading(false)
    }
  }

  return (
    <form>
      <div className="form-row">
        <div className="col">
          <label className='font-medium'>Income</label>
        </div>
        <div className="col-sm-12">
          <DynamicFormBudget defaultData={defaultDataIncome} values={incomeValues} setValues={setIncomeValues} accounts={incomeAccounts} loading={loading} availableAccount={incomeAccount} />
        </div>
      </div>

      <div className="form-row">
        <div className="col">
          <label className='font-medium'>Expense</label>
        </div>
        <div className="col-sm-12">
          <DynamicFormBudget defaultData={defaultDataExpense} values={expenseValues} setValues={setExpenseValues} accounts={expenseAccounts} loading={loading} availableAccount={expenseAccount} />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn btn-primary' onClick={handleSubmit}>{formLoading ? "Adding..." : "Add"}</button>
      </div>

    </form>
  )
}

export default AddBudgetItems