import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useActivities from '../../../hooks/useActivities'
import Card from '../../../parts/Card'
import CardContentWrapper from '../../global/CardContentWrapper'
import CardHeaderWithBottomBorder from '../../global/CardHeaderWithBottomBorder'
import { EllipsisLoader } from '../../global/MyLoaders'
import AddTeamMember from './AddTeamMember'
import { TeamMember } from './TeamMember'
import parse from "html-react-parser";
import { htmlParseOptions } from '../../../constants'
import ProgressBar from '../../global/ProgressBar'
import ModalSwitcher from '../../global/ModalSwitcher'
import AddActivityPlan from './AddActivityPlan'
import AddActivityParameters from './AddActivityParameters'
import ActivityParameters from './parameters/ActivityParameters'
import AddConsolidatedReport from './reports/AddConsolidatedReport'
import SendCompletion from './parameters/SendCompletion'
import ViewCompletion from './parameters/ViewCompletion'
// import useReports from '../../../hooks/useReports'

interface IActivityProps {
  id: number
}

const Activity = ({ id }: IActivityProps) => {
  const { selectedActivity, loading, getActivity } = useActivities();
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedParameter, setSelectedParameter] = useState<any>({});
  // const { getProjectReports, projectReports } = useReports();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => getProjectReports(id), [id]);

  useEffect(() => {
    return getActivity(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  const handleParameterClick = (parameter: any, num: number) => {
    setIsOpen(true);
    setOpenModal(num);
    setSelectedParameter(parameter);
  }
  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = 'add-new-plan';
      modalTitle = 'Add Activity Plan';
      ModalToRender = AddActivityPlan

      break;
    case 3:
      modalId = 'add-activity-parameters';
      modalTitle = 'Add Activity Parameters';
      ModalToRender = AddActivityParameters
      break;
    case 4:
      modalId = 'add-report';
      modalTitle = 'Add Activity Report';
      ModalToRender = AddConsolidatedReport
      break;

    case 5:
      modalId = 'send-completion';
      modalTitle = 'Submit details';
      ModalToRender = SendCompletion
      break;
    case 6:
      modalId = 'view-completion';
      modalTitle = 'View details';
      ModalToRender = ViewCompletion
      break;
    default:
      modalId = 'add-new-department';
      modalTitle = 'Add new team member';
      ModalToRender = AddTeamMember
      break;
  }

  return loading ? (<EllipsisLoader />) : (
    <>
      <ModalSwitcher isOpen={isOpen} setIsOpen={setIsOpen} id={modalId} title={modalTitle} component={<ModalToRender id={id} onCancel={handleModalClick} closeModal={handleModalClick} refresh={() => getActivity(id)} files={selectedActivity.files} selectedParameter={selectedParameter} />} />
      <div className='row'>
        <div className='col-xl-8 mb-30'>
          <div className="row">
            {Number(selectedActivity.steps.current) > 3 && (
              <div className="col-sm-12">
                <Card>
                  <>
                    <CardHeaderWithBottomBorder title="Completion parameters" />
                    <ActivityParameters parameters={selectedActivity.parameters} handleParameterClick={handleParameterClick} />
                  </>
                </Card>
              </div>
            )}
            <div className="col-sm-12">
              <Card>
                <>
                  <CardHeaderWithBottomBorder title={selectedActivity.activity_name} categories={[{ title: selectedActivity.status, className: 'badge-info' }]} />

                  <CardContentWrapper>
                    <>
                      <div className='row'>
                        <div className="col-sm-6">
                          <h6>Description</h6>
                          <p>{selectedActivity?.description ? parse(selectedActivity?.description, htmlParseOptions) : null}</p>
                        </div>

                        <div className="col-sm-6">
                          <h6>Objectives</h6>
                          <p>{selectedActivity?.objectives ? parse(selectedActivity?.objectives, htmlParseOptions) : null}</p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className="col-sm-12">
                          <h6>Methodolgy</h6>
                          <p>{selectedActivity.activity_methodology}</p>
                        </div>

                        <div className="col-sm-6">
                          <h6>Cost</h6>
                          <p>{selectedActivity.cost}</p>
                        </div>

                        <div className="col-sm-6">
                          <h6>Status</h6>
                          <p>{selectedActivity.status}</p>
                        </div>

                        <div className="col-sm-12">
                          <h6>Project</h6>
                          <Link className='border-bottom-dashed-link' to={`/projects/view/${selectedActivity?.project?.id}`}>{selectedActivity?.project?.project_name}</Link>
                        </div>
                      </div>
                    </>
                  </CardContentWrapper>

                </>
              </Card>
            </div>
          </div>

        </div>

        <div className='col-xl-4 mb-30'>
          {Number(selectedActivity.steps.current) !== 5 ? (
            <Card>
              <>
                <CardHeaderWithBottomBorder title="Getting started" isButton >
                  <p className='text-sm text-muted'>{selectedActivity.steps.progress} complete</p>
                </CardHeaderWithBottomBorder>
                <CardContentWrapper>
                  <div className='row'>

                    {Number(selectedActivity.steps.current) === 2 && (
                      <>
                        <div className="col-sm-12 py-3">
                          <ProgressBar progress={selectedActivity.steps.progress} />
                        </div>
                        <div className="col-sm-12">
                          <div className='py-2'>
                            <p>Create a plan</p>
                            <p>Do it by clicking <span onClick={(e) => {
                              e.stopPropagation();
                              setIsOpen(true);
                              setOpenModal(2);
                            }} className='text-info text-underline pointer'>here to get started</span> </p>

                          </div>
                          <p className='text-sm'>
                            With activity plan, we shall be able to track the project activities according to the given details
                          </p>
                        </div>
                      </>
                    )}
                    {Number(selectedActivity.steps.current) === 3 && (
                      <>
                        <div className="col-sm-12 py-3">
                          <ProgressBar progress={selectedActivity.steps.progress} />
                        </div>
                        <div className="col-sm-12">
                          <div className='py-2'>
                            <p>Add completion parameters</p>
                            <p>Do it by clicking <span onClick={(e) => {
                              e.stopPropagation();
                              setIsOpen(true);
                              setOpenModal(3);
                            }} className='text-info pointer text-underline'>here to get started</span> </p>

                          </div>
                          <p className='text-sm'>
                            We use the activity parameters to determine if the activity was successfully accomplished or its still on going
                          </p>
                        </div>
                      </>
                    )}
                    {Number(selectedActivity.steps.current) === 4 && (
                      <>
                        <div className="col-sm-12 py-3">
                          <ProgressBar progress={selectedActivity.steps.progress} />
                        </div>
                        <div className="col-sm-12">
                          <div className='py-2'>
                            <p>Add team members</p>
                            <p>Do it by clicking <span className='text-info text-underline pointer' onClick={(e) => {
                              e.stopPropagation();
                              setIsOpen(true);
                              setOpenModal(0);
                            }}>here to get started</span> </p>

                          </div>
                          <p className='text-sm'>
                            This is the team which will ensure smooth completion of the activity by executiing required tasks
                          </p>
                        </div>
                      </>
                    )}

                  </div>
                </CardContentWrapper>

              </>
            </Card>
          ) : (
            <>
              {/* reports */}
              {/* <Card>
                <>
                  <CardHeaderWithBottomBorder title="Reports" isButton>
                    <span className='ml-3 text-info pointer' onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                      setOpenModal(4);
                    }}>Add new</span>
                  </CardHeaderWithBottomBorder>
                  <CardContentWrapper>
                    <div className='row'>
                      <div className="col-sm-12">
                        <ul className="list-unstyled">
                        </ul>
                      </div>

                    </div>
                  </CardContentWrapper>

                </>
              </Card> */}
              {/* end reports */}

              {/* team */}
              <Card>
                <>
                  <CardHeaderWithBottomBorder title="Team" isButton={selectedActivity.status === 'On-going'}>
                    <span className='ml-3 text-info pointer' onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                      setOpenModal(0);
                    }}>Add new</span>
                  </CardHeaderWithBottomBorder>
                  <CardContentWrapper>
                    <div className='row'>
                      <div className="col-sm-12">
                        <ul className="list-unstyled">
                          {selectedActivity?.activity_teams?.length > 0 ? selectedActivity.activity_teams?.map((teamMember: any) => (
                            <TeamMember key={teamMember.id} role={teamMember.activity_role?.name} firstname={teamMember.staff.user.first_name} lastname={teamMember.staff.user.last_name} teamMemberid={teamMember?.id} image={teamMember.staff.user.profile_pic} status={selectedActivity.status === 'On-going'} />
                          )) : selectedActivity.status === 'On-going' && (
                            <p className='text-dribbble font-xs'>No Team Member <span className='ml-3 text-info pointer' onClick={(e) => {
                              e.stopPropagation();
                              setIsOpen(true);
                            }}>Add new</span></p>
                          )}
                        </ul>
                      </div>

                    </div>
                  </CardContentWrapper>

                </>
              </Card>
              {/* end team */}
            </>
          )}

        </div>
      </div>

    </>
  )
}

export default Activity