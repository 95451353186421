import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { ERRORMESSAGE, errorToastOptions, invoicePaymentDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useAccounts from '../../../hooks/useAccounts';
import useInvoices from '../../../hooks/useInvoices';
import { formatAcountSelectData, formatDepartmentSelectData, getFormData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputDate from '../../global/InputDate';
import InputSelect from '../../global/InputSelect';
import { EllipsisLoader } from '../../global/MyLoaders';

interface IMakePaymentProps {
  id: number;
  closeModal: () => void;
  refresh: () => void;
}

const MakePayment = ({ id, closeModal, refresh }: IMakePaymentProps) => {
  const [formLoading, setLoading] = useState(false);
  const [payment, setPayment] = useState(invoicePaymentDefaultData);
  const { loading, getPaymentTypes, paymentTypes, getSelectedInvoice, selectedInvoice } = useInvoices();

  console.log(selectedInvoice);

  const { expenseAccounts, getExpenseAccount, accounts } = useAccounts();


  // const formNames = ['grand_total'];

  useEffect(() => {
    let invoiceData = invoicePaymentDefaultData;
    if (!loading) {
      invoiceData = { ...invoiceData, ...{ grand_total: { ...invoiceData.grand_total, ...{ error: false, data: Number(selectedInvoice.sub_total).toString() } } } };

      if (selectedInvoice?.payments?.length === 0) {
        invoiceData = { ...invoiceData, ...{ due_amount: { ...invoiceData.due_amount, ...{ error: false, data: Number(selectedInvoice.sub_total).toString() } } } };
      } else {
        const totalPaid = selectedInvoice?.payments?.map((item: any) => item.total_paid).reduce((accumulator: any, element: any) => Number(accumulator) + Number(element), 0);
        invoiceData = { ...invoiceData, ...{ due_amount: { ...invoiceData.due_amount, ...{ error: false, data: Number(Number(selectedInvoice.sub_total) - totalPaid).toString() } } } };
        invoiceData = { ...invoiceData, ...{ total_paid: { ...invoiceData.total_paid, ...{ error: false, data: totalPaid } } } };
      }

      setPayment(invoiceData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getExpenseAccount(), [accounts]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getPaymentTypes(), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedInvoice(id), [id]);

  const validator = new Validator();

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setPayment({ ...payment, ...{ [name]: { ...payment[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setPayment({ ...payment, ...{ [name]: { ...payment[name], ...{ error: true, data: value } } } });
    }
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setPayment({ ...payment, ...{ [name]: { ...payment[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setPayment({ ...payment, ...{ [name]: { ...payment[name], ...{ error: true, data: value } } } });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(payment, setPayment)) {
      setLoading(true);
      const formData = getFormData(payment);
      const data = {
        ...formData, ...{ invoice_id: id }
      }

      doCreate('invoice-payments/create', data).then((response) => {
        if (response.data.success === true) {
          toast.success('Invoice payment details have been successfully submitted', successToastOptions);
          refresh();

          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).catch(() => {
        toast.error(ERRORMESSAGE, errorToastOptions);
      }).finally(() => {
        setLoading(false);
      })
    } else {
      // console.log(invoice);
    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input handleChange={handleChange} label='Grand Total' name='grand_total' required type='number' value={payment?.grand_total.data} error={payment.grand_total.error} errorMessage="Please provide the grand total" readonly />
        </div>
        <div className="col-sm-6">
          <Input handleChange={handleChange} label='Total Paid' name='total_paid' required type='number' value={payment?.total_paid.data} error={payment.total_paid.error} errorMessage="Please provide the total total" readonly />
        </div>
        <div className="col-sm-6">
          <Input handleChange={handleChange} label='Due Amount' name='due_amount' required type='number' value={payment?.due_amount.data} error={payment.due_amount.error} errorMessage="Please provide the due total" />
        </div>
        <div className="col-sm-6">
          <InputDate handleChange={handleSelectChange} label='Payment Date' name='date_paid' required value={payment?.date_paid.data} error={payment.date_paid.error} errorMessage="Please provide the date paid" />
        </div>
        <div className="col-sm-6">
          <InputSelect id='payment_type_id' data={formatDepartmentSelectData(paymentTypes)} handleChange={handleSelectChange} label='Payment type' name='payment_type_id' required value={payment?.payment_type_id.data} error={payment.payment_type_id.error} errorMessage="Please select the payment type" />
        </div>
        <div className="col-sm-6">
          <InputSelect id='expense_account_id' data={formatAcountSelectData(expenseAccounts)} handleChange={handleSelectChange} label='Expense Account' name='expense_account_id' required value={payment?.expense_account_id.data} error={payment.expense_account_id.error} errorMessage="Please select the expense account" />
        </div>
      </div>
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  )
}

export default MakePayment