import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { executeDelete } from '../../api';
import { errorToastOptions, successToastOptions } from '../../constants';

interface IConfirmActionProps {
  path: string;
  closeModal: () => void;
  refresh: () => void;
  url?: string;
}

const ConfirmAction = ({ path, closeModal, refresh, url }: IConfirmActionProps) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    executeDelete(url ? url : path).then((response) => {
      if (response.data.success === true) {
        toast.success('Operation successfully executed', successToastOptions);
        refresh();
        setTimeout(() => {
          closeModal();
        }, 1000);
      } else {
        toast.error(response.data.message, errorToastOptions);
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <div className='row justify-content-center align-items-center flex-column py-4 overflow-hidden'>
      <div className='text-center'>
        <h3 className='py-2'>Are you sure you want to proceed?</h3>
        {/* <p className='text-sm text-muted'>Please note this action is irriversable</p> */}
      </div>
      <div>
        <div className='row justify-content-center align-items-center py-5'>
          <button className='btn btn-lg btn-outline-info mr-10' onClick={closeModal}>No</button>
          <button className='btn btn-lg btn-danger' disabled={loading} onClick={handleDelete}>{loading ? 'Processing...' : 'Yes'}</button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmAction