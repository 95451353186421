import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import ListLeaves from '../../components/hr/leave_management/ListLeaves'
import Breadcrumbs from '../../parts/Breadcrumbs'

const LeaveManagement = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Leave Management' />
        <ListLeaves />
      </>
    </DashboardWrapper>
  )
}

export default LeaveManagement