
import DashboardWrapper from '../../components/DashboardWrapper'
import ListHealthTips from '../../components/mobile_app/healthTips/ListHealthTips'

import Breadcrumbs from '../../parts/Breadcrumbs'

const Tips = () => {

  return (
    <>

      <DashboardWrapper>
        <>
          <Breadcrumbs title='Health Tips' />
          {/* <!-- main body --> */}
          <ListHealthTips />
        </>

      </DashboardWrapper>
    </>
  )
}

export default Tips