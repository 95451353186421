import React from 'react'
import DashboardWrapper from '../../../components/DashboardWrapper'
import Breadcrumbs from '../../../parts/Breadcrumbs'
import { ROUTES } from '../../../routes/routes'

const AccountTransactions = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title={"Transactions"} parents={[{ link: ROUTES.REPORTS, title: 'Reports' }, { link: ROUTES.REPORTS, title: 'Transactions' }]} />
      </>
    </DashboardWrapper>
  )
}

export default AccountTransactions