import React from 'react'

interface IViewGoodsRecievedProps {
  id: number;
  closeModal: () => void;
}

const ViewGoodsRecieved = ({ closeModal, id }: IViewGoodsRecievedProps) => {
  return (
    <div>ViewGoodsRecieved</div>
  )
}

export default ViewGoodsRecieved