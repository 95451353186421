/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { TableColumn } from "react-data-table-component";
import { personalDetails } from "../../../constants";
import usePayrolls from "../../../hooks/usePayrolls";
import MyDataTable from "../../global/MyDataTable";
import { EllipsisLoader } from "../../global/MyLoaders";
import { formatCurrency } from "../../../utils/helpers";

const PersonalPayrollDetailsTable = () => {
  const { selectedStaffPayroll, loading } = usePayrolls();
  const columnData: TableColumn<any>[] = [
    {
      id: 1,
      name: "Pay Date",
      selector: (row) => row.pay_date,
      reorder: true,
      sortable: true,
    },
    {
      id: 2,
      name: "Gross",
      selector: (row) => formatCurrency(row.total_pay),
      reorder: true,
      sortable: true,
    },
    {
      id: 3,
      name: "Deductions",
      selector: (row) => formatCurrency(row.deductions),
      reorder: true,
      sortable: true,
    },
    {
      id: 4,
      name: "Net Pay",
      selector: (row) => formatCurrency(row.net_pay),
      reorder: true,
      sortable: true,
    },
    {
      id: 5,
      name: "Total Paid",
      selector: (row) => formatCurrency(row.paid_amount),
      reorder: true,
      sortable: true,
    },
    {
      id: 6,
      name: "Recurring",
      selector: (row) => (row.is_recurring ? row.recurring_info : "No"),
      reorder: true,
      sortable: true,
    },
    // {
    //   id: 7,
    //   name: "Pay slip",
    //   cell: (row) => (
    //     <button className='btn btn-sm btn-secondary'><i className='fa fa-file-pdf-o'></i></button>
    //   ),
    //   reorder: true,
    //   sortable: true
    // },
    {
      button: true,
      cell: (row) => (
        <div className="row">
          <button className="btn btn-sm btn-primary">
            <i className="fa fa-edit"></i>
          </button>
          <button className="btn btn-sm btn-danger mx-1">
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];
  return loading && selectedStaffPayroll ? (
    <EllipsisLoader />
  ) : (
    <MyDataTable data={selectedStaffPayroll} columns={columnData} title="" />
  );
};

export default PersonalPayrollDetailsTable;
