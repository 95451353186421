import React, { useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  ERRORMESSAGE,
  errorToastOptions,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import Textarea from "../../global/Textarea";

interface IAcceptRequisitionProps {
  onCancel: () => void;
  id: number;
  refresh: () => void;
}

const AcceptRequisition = ({
  onCancel,
  id,
  refresh,
}: IAcceptRequisitionProps) => {
  const [comment, setComment] = useState({ error: false, data: "" });
  const [loading, setLoading] = useState(false);
  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target as HTMLInputElement;
    if (value !== "") {
      console.log(value);
      setComment({ error: false, data: value });
    } else {
      setComment({ error: true, data: "" });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (comment.data.length > 6) {
      const data = {
        comment: comment.data,
        state_id: 3,
        requisition_id: id,
      };

      setLoading(true);

      doCreate("requisitions/" + id + "/approve", data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success(
              "Your requisition successfully accepted",
              successToastOptions
            );
            refresh();

            setTimeout(() => {
              onCancel();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .catch(() => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setComment({ error: true, data: comment.data });
      return;
    }
  };

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-12">
          <Textarea
            withNoLabel
            name="comment"
            label=""
            handleChange={handleChange}
            required
            errorMessage="Please provide a comment"
            value={comment.data}
            error={comment.error}
          />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {loading ? "Processing..." : "Accept"}
        </button>
      </div>
    </form>
  );
};

export default AcceptRequisition;
