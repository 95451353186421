import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { ERRORMESSAGE, errorToastOptions, projectBudgetDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useBudget from '../../../hooks/useBudget';
import useFunders from '../../../hooks/useFunders';
import { formatDepartmentSelectData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputSelect from '../../global/InputSelect';
import { EllipsisLoader } from '../../global/MyLoaders';

interface IAddBudget {
  onCancel: () => void;
  id: number;
  refresh: () => void;
}

const AddBudget = ({ onCancel, id, refresh }: IAddBudget) => {
  const [projectBudget, setProjectBudget] = useState(projectBudgetDefaultData);
  const [formLoading, setLoading] = useState(false);
  const { funders, loading } = useFunders();
  const validator = new Validator();

  const { budgets } = useBudget();

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setProjectBudget({ ...projectBudget, ...{ [name]: { ...projectBudget[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setProjectBudget({ ...projectBudget, ...{ [name]: { ...projectBudget[name], ...{ error: true, data: value } } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setProjectBudget({ ...projectBudget, ...{ [name]: { ...projectBudget[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setProjectBudget({ ...projectBudget, ...{ [name]: { ...projectBudget[name], ...{ error: true, data: value } } } });
    }
  }

  const handleItemsChange = (obj: unknown) => {
    const { name, selectedOptions } = obj as HTMLSelectElement;
    if (name === 'funders') {
      const selected = Array.from(selectedOptions).map((item) => item.value);
      const data = { [name]: { error: false, data: selected.join() } };
      setProjectBudget({ ...projectBudget, ...data });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(projectBudget, setProjectBudget)) {
      setLoading(true);
      const data = {
        budget_id: projectBudget.budget_id.data,
        est_amount: projectBudget.est_amount.data,
        funders: projectBudget.funders.data.split('').map((item) => Number(item)),
        project_id: id
      }

      doCreate('project-budget/create', data).then((response) => {
        if (response.data.success === true) {
          toast.success('Project budget has been recorded', successToastOptions);
          refresh();

          setTimeout(() => {
            onCancel();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).catch(() => {
        toast.error(ERRORMESSAGE, errorToastOptions);
      }).finally(() => {
        setLoading(false);
      })
    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>

      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect data={formatDepartmentSelectData(budgets)} handleChange={handleSelectChange} label="General Bugdet" name='budget_id' required id='budget_id' />
        </div>
        <div className="col-sm-6">
          <Input type='number' handleChange={handleChange} label="Estimated amount" name='est_amount' required />
        </div>

      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect data={formatDepartmentSelectData(funders)} handleChange={handleItemsChange} label="Project Funder(s)" name='funders' id='funders' multiple />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>
          {formLoading ? "Adding..." : "Add"}
        </button>
      </div>
    </form>
  )
}

export default AddBudget