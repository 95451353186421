import React from 'react';
import Logo from "../../assets/images/logo.svg";


const ComingSoon = () => {
  return (
    <div className='row align-items-center justify-content-center h-100 relative'>
      <div className='text-center'>
        <img src={Logo} alt="" />
        <h3 className='text-info py-4'>Coming soon</h3>
      </div>
    </div>
  )
}

export default ComingSoon