/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { MouseEvent } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Logo from "../assets/images/logo.svg";
import Pic from "../assets/images/male.jpg";
import { setToggleSideNav } from '../redux/reducer/generalReducers';
import { ROUTES } from '../routes/routes';
import { isImage } from '../utils/helpers';

const Nav = () => {

  const user = sessionStorage.user;
  const names = JSON.parse(user).user.first_name + " " + JSON.parse(user).user.last_name;
  const email = JSON.parse(user).user.email;
  const profilePic = JSON.parse(user).user.profile_pic;

  const router = useHistory();

  const handleLogin = (e: MouseEvent) => {
    e.preventDefault();
    sessionStorage.clear();
    router.go(0);

  }

  const dispatch = useDispatch();

  const handleToggleButtonClick = () => {
    dispatch(setToggleSideNav());
  }

  return (
    <nav
      className="admin-header navbar navbar-default col-lg-12 col-12 p-0 fixed-top d-flex flex-row"
    >
      {/* <!-- logo --> */}
      <div className="text-left navbar-brand-wrapper">
        <Link to={ROUTES.DASHBOARD} className="navbar-brand brand-logo"
        ><img src={Logo} alt=""
          /></Link>
        <Link to={ROUTES.DASHBOARD} className="navbar-brand brand-logo-mini" href="index.html"
        ><img src={Logo} alt=""
          /></Link>
      </div>
      {/* <!-- Top bar left --> */}
      <ul className="nav navbar-nav mr-auto">
        <li className="nav-item">
          <a
            id="button-toggle"
            className="button-toggle-nav inline-block ml-20 pull-left"
            href="#"
            onClick={handleToggleButtonClick}
          ><i className="zmdi zmdi-menu ti-align-right"></i
          ></a>
        </li>

      </ul>
      {/* <!-- top bar right --> */}
      <ul className="nav navbar-nav ml-auto">

        <li className="nav-item dropdown mr-30">
          <a
            className="nav-link nav-pill user-avatar"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src={isImage(profilePic) ? profilePic : Pic} alt="avatar" />
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <div className="dropdown-header">
              <div className="media">
                <div className="media-body text-dark">
                  <h5 className="mt-0 mb-0">{names}</h5>
                  <span>{email}</span>
                </div>
              </div>
            </div>

            <div className="dropdown-divider"></div>
            <Link className="dropdown-item" to={ROUTES.MYPROFILE}
            ><i className="text-info ti-settings"></i>Settings</Link>
            <a className="dropdown-item" href="#" onClick={handleLogin}
            ><i className="text-danger ti-unlock"></i>Logout</a
            >
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default Nav