import React from 'react'

interface IEditDoctorProps {
  id: number;
}

const EditDoctor = ({ id }: IEditDoctorProps) => {
  return (
    <div>EditDoctor</div>
  )
}

export default EditDoctor