import React from 'react'
import usePrograms from '../../../hooks/usePrograms';
import CardContentWrapper from '../../global/CardContentWrapper';
import CardHeaderWithBottomBorder from '../../global/CardHeaderWithBottomBorder';
import { EllipsisLoader } from '../../global/MyLoaders';
import parse from 'html-react-parser';
import { htmlParseOptions } from '../../../constants';
import Card from '../../../parts/Card';
import OtherPrograms from './OtherPrograms';

const ViewPrograms = () => {
  const { loading, selectedProgram } = usePrograms();
  return loading ? <EllipsisLoader /> : (
    <div className='row'>
      <div className='col-xl-8 mb-30'>
        <Card>
          <>
            <CardHeaderWithBottomBorder title={selectedProgram.category_name} />
            <CardContentWrapper>
              <div className='row'>
                <div className="col-sm-12">
                  <h6>Name</h6>
                  <p>{selectedProgram.category_name}</p>
                </div>

                <div className="col-sm-12">
                  <h6>Description</h6>
                  {parse(selectedProgram?.description ? selectedProgram?.description : '', htmlParseOptions)}

                </div>
              </div>
            </CardContentWrapper>
          </>
        </Card>
      </div>

      <div className='col-xl-4 mb-30'>
        <OtherPrograms />
      </div>
    </div>
  )
}

export default ViewPrograms