import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { stockCreate } from '../../../api/stocks';
import { ERRORMESSAGE, errorToastOptions, stockCategoryDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useStockCategories from '../../../hooks/useStockCategories';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import { EllipsisLoader } from '../../global/MyLoaders';
import Textarea from '../../global/Textarea';

interface IEditCategoryProps {
  id: number;
  closeModal: () => void;
}

const EditCategory = ({ id, closeModal }: IEditCategoryProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [category, setCategory] = useState(stockCategoryDefaultData);
  const { refresh, getCategory, selectedCategory, loading } = useStockCategories();

  const validator = new Validator();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getCategory(id), [id]);
  const formNames = ['description', 'name'];

  useEffect(() => {
    let categoryData = stockCategoryDefaultData;
    if (!loading) {
      for (let x of formNames) {
        categoryData = { ...categoryData, ...{ [x]: { ...categoryData[x], ...{ error: false, data: selectedCategory[x] } } } };
      }
      setCategory(categoryData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setCategory({ ...category, ...{ [name]: { ...category[name], error: false, data: value } } });
    } else {
      // indicate an error
      setCategory({ ...category, ...{ [name]: { ...category[name], error: true, data: value } } });
    }
  };



  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(category, setCategory)) {
      setFormLoading(true);

      const data = {
        name: category.name.data,
        description: category.description.data,
      }

      try {
        const newProject = await stockCreate(data, 'product_categories/' + id + '/update');
        if (newProject.data.success === true) {
          toast.success('Category updated successfully', successToastOptions);
          refresh(); // refresh the hook store
          setCategory(stockCategoryDefaultData);
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(newProject.data.data, newProject.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setFormLoading(false)
      }

    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>

      <div className="form-row">
        <div className="col-sm-6">
          <Input name='name' label='Category name' handleChange={handleChange} value={category.name.data} error={category.name.error} errorMessage="Please provide the category name" required />
        </div>

      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Textarea handleChange={handleChange} label="Description" required name='description' value={category.description.data} error={category.description.error} errorMessage="Please provide the category description" />
        </div>
      </div>
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-primary' onClick={handleSubmit}>{formLoading ? "Updating..." : "Update"}</button>
      </div>
    </form>
  )
}

export default EditCategory