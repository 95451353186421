import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import ListActivities from '../../components/project/activities/ListActivities'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const Teams = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Teams' parents={[{ link: ROUTES.PROJECTS, title: 'Projects' }]} />
        <ListActivities />

      </>
    </DashboardWrapper>
  )
}

export default Teams