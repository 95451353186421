import React, { useState } from 'react'
import { TableColumn } from 'react-data-table-component';
import useAccounts from '../../../../hooks/useAccounts';
import useAssetManagement from '../../../../hooks/useAssetManagement';
import { useLastUrlSegment } from '../../../../hooks/useQuery';
import ConfirmDelete from '../../../global/ConfirmDelete';
import ModalSwitcher from '../../../global/ModalSwitcher';
import MyDataTable from '../../../global/MyDataTable';
import { EllipsisLoader } from '../../../global/MyLoaders';
import AddExpense from './AddExpense';
import EditExpense from './EditExpense';

const Expenses = () => {

  const { loading, selectedAsset } = useAssetManagement();
  const { getAccountName } = useAccounts();
  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "Expense type",
      selector: (row) => row.asset_expense_type_id,
      reorder: true,
      sortable: true,
      wrap: true,
    }, {
      id: 2,
      name: "Trans. date",
      selector: (row) => row.transaction_date,
      reorder: true,
      sortable: true,
      wrap: true,
      grow: 2,
    }, {
      id: 3,
      name: "Amount",
      selector: (row) => row.amount,
      reorder: true,
      sortable: true,
      wrap: true,
    }, {
      id: 4,
      name: "Expense account ",
      selector: (row) => getAccountName(row.expense_account_id),
      reorder: true,
      sortable: true,
      grow: 1,
      wrap: true,
    }, {
      id: 5,
      name: "Cash Account ",
      selector: (row) => getAccountName(row.fund_source_account_id),
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 6,
      name: "Narrative ",
      selector: (row) => row.narrative,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      button: true,
      cell: (row) => row.is_sys === 1 ? null : (
        <div className='row'>
          {/* <button className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></button> */}
          <button className='btn btn-sm btn-primary'><i className='fa fa-edit' onClick={(e) => {
            e.stopPropagation();
            handleEditClick(row.id);
          }}></i></button>

          <button className='btn btn-sm btn-danger mx-1' onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(row.id);
          }}><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];


  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);

  const assetId = useLastUrlSegment();

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = 'edit-asset-expense';
      modalTitle = 'Edit asset expense';
      ModalToRender = EditExpense

      break;
    case 3:
      modalId = 'delete-asset-expense';
      modalTitle = '';
      ModalToRender = ConfirmDelete
      break;
    default:
      modalId = 'add-asset-expense';
      modalTitle = 'Add new asset expense';
      ModalToRender = AddExpense
      break;
  }
  return (
    <>
      <ModalSwitcher isOpen={isOpen} setIsOpen={setIsOpen} id={modalId} title={modalTitle} component={<ModalToRender assetId={assetId} id={selectedId} closeModal={handleModalClick} path={`departments/${selectedId}/delete`} refresh={() => ""} />} />
      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className='pl-3 row align-items-end'>
                  <button className='btn btn-primary ml-auto mr-3'
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                    }}><i className='fa fa-plus'></i>Add new</button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input type="search" className="form-control" placeholder="Search...." />
                </div>
              </div>
            </div>
            {/* table */}
            {loading ? <EllipsisLoader /> : <MyDataTable columns={columns} data={selectedAsset?.expenses} title="" />}

            {/* end table */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Expenses