import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { doCreate } from '../../../../../api'
import { employeeOtherTrainingDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../../../constants'
import { formatErrorMessage } from '../../../../../helpers'
import useEmployees from '../../../../../hooks/useEmployees'
import useStaff from '../../../../../hooks/useStaff'
import Validator from '../../../../../utils/Validator'
import Input from '../../../../global/Input'
import InputDate from '../../../../global/InputDate'

interface IAddEducationProps {
  onCancel: () => void;
  isSubmit?: boolean;
  id?: number;
}

const AddOtherTraining = ({ onCancel, id, isSubmit }: IAddEducationProps) => {

  const [otherTraining, setOtherTraining] = useState(employeeOtherTrainingDefaultData);
  const [loading, setLoading] = useState(false);
  const validator = new Validator();
  const { storeEmployeeOtherTrainingDetails } = useEmployees();
  const { getStaff } = useStaff();

  const handleSubmit = () => {
    if (validator.checkForEmptyFields(otherTraining, setOtherTraining)) {
      const data = {
        award: otherTraining.award.data,
        institution: otherTraining.institution.data,
        nature_of_training: otherTraining.nature_of_training.data,
        date_attained: otherTraining.date_attained.data,
      }

      if (isSubmit) {
        setLoading(true);
        doCreate('/staff/' + id + '/add_other_training', data).then((response) => {
          if (response.data.error) {
            toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
          } else {
            toast.success("Successfully added employment details", successToastOptions);
            getStaff(Number(id));
            setTimeout(() => {
              onCancel();
            }, 1000);
          }
        }).catch(() => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        }).finally(() => {
          setLoading(false);
        })
      } else {
        storeEmployeeOtherTrainingDetails(data);
        setOtherTraining(employeeOtherTrainingDefaultData);
        onCancel();

      }
    }

  }

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setOtherTraining({ ...otherTraining, ...{ [name]: { ...otherTraining[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setOtherTraining({ ...otherTraining, ...{ [name]: { ...otherTraining[name], ...{ error: true, data: value } } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setOtherTraining({ ...otherTraining, ...{ [name]: { ...otherTraining[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setOtherTraining({ ...otherTraining, ...{ [name]: { ...otherTraining[name], ...{ error: true, data: value } } } });
    }
  }


  return (
    <div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='institution' label='Institution Name' handleChange={handleChange} value={otherTraining.institution.data} error={otherTraining.institution.error} errorMessage="Please enter institution's name" required />
        </div>
        <div className="col-sm-6">
          <Input name='award' label='Award Recieved' handleChange={handleChange} value={otherTraining.award.data} error={otherTraining.award.error} errorMessage="Please enter the award recieved" required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input name='nature_of_training' label='Nature of training' handleChange={handleChange} value={otherTraining.nature_of_training.data} error={otherTraining.nature_of_training.error} errorMessage="Please enter the nature of training" required />
        </div>
        <div className="col-sm-6">
          <InputDate name='date_attained' label='Date Attained' handleChange={handleSelectChange} value={otherTraining.date_attained.data} error={otherTraining.date_attained.error} errorMessage="Please enter the date attained" required />
        </div>
      </div>



      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='button' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='button' className='btn  btn-info' onClick={handleSubmit}>
          Add {loading ? "Adding..." : "Add"}
        </button>
      </div>
    </div>
  )
}

export default AddOtherTraining