/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable indent */
/* eslint-disable camelcase */
import { createSlice, current } from '@reduxjs/toolkit';
import { } from '../../utils/helpers';

export interface IVillageState {
  village_id: string;
  village_name: string,
}

export interface ICountyState {
  county_id: string;
  name: string,
}

export interface ISubCountyState {
  sub_county_id: string;
  name: string,
}

export interface IParishState {
  parish_id: string;
  name: string,
}

export interface IDistrictState {
  district_id: string;
  name: string,
}

export interface IGeoDataState {
  villages: any[],
  countries: any[],
  subCounties: any[],
  counties: any[],
  parishes: any[],
  districts: any[],
  _districts: any[],
  _villages: any[],
  _subCounties: any[],
  _counties: any[],
  _parishes: any[],
}

const initialState: IGeoDataState = {
  villages: [],
  subCounties: [],
  counties: [],
  parishes: [],
  districts: [],
  _villages: [],
  _subCounties: [],
  _counties: [],
  _parishes: [],
  _districts: [],
  countries: []
};

export const geoDataReducer = createSlice({
  name: 'geoData',
  initialState,
  reducers: {
    setVillages: (state, { payload }) => {
      if (!payload) return;
      const newState: any[] = [];
      payload.forEach((element: any) => {
        const obj = { value: (element.village_name), label: element.village_name };
        newState.push(obj);
      });
      state._villages = payload;
    },
    setCountries: (state, { payload }) => {
      state.counties = payload;
    },
    setSubCounties: (state, { payload }) => {
      if (!payload) return;
      const newState: any[] = [];
      payload.forEach((element: any) => {
        const obj = { value: (element.name), label: element.name };
        newState.push(obj);
      });
      state._subCounties = payload;
    },
    setCounties: (state, { payload }) => {
      if (!payload) return;
      state._counties = payload;
    },
    setParishes: (state, { payload }) => {
      if (!payload) return;
      const newState: any[] = [];
      payload.forEach((element: any) => {
        const obj = { value: (element.name), label: element.name };
        newState.push(obj);
      });
      state._parishes = payload;
    },
    setDistricts: (state, { payload }) => {
      const newState: any[] = [];

      if (!payload) return;
      state._districts = payload;
      payload.forEach((element: any) => {
        const obj = { value: (element.name), label: element.name };
        newState.push(obj);
      });
      state.districts = newState;
    },



    handleDistrictChange: (state, { payload }) => {
      const newState: any[] = [];
      const _d = current(state)._districts.find((data) => data.name === payload.value);
      const _c = current(state)._counties.filter((data) => data.district_id === _d.id);
      _c.forEach((element: any) => {
        const obj = { value: (element.name), label: element.name };
        newState.push(obj);
      });
      console.log(newState);
      state.counties = newState;
    },

    handleCountyChange: (state, { payload }) => {
      const newState: any[] = [];
      const _c = current(state)._counties.find((data) => data.name === payload.value);
      const _sc = current(state)._subCounties.filter((data) => data.county_id === _c.id);
      _sc.forEach((element: any) => {
        const obj = { value: (element.name), label: element.name };
        newState.push(obj);
      });
      state.subCounties = newState;
    },

    handleSubCountyChange: (state, { payload }) => {
      const newState: any[] = [];
      const _c = current(state)._subCounties.find((data) => data.name === payload.value);

      const _p = current(state)._parishes.filter((data) => data.sub_county_id === _c.id);
      _p.forEach((element: any) => {
        const obj = { value: (element.name), label: element.name };
        newState.push(obj);
      });
      state.parishes = newState;
    },

    handleParishChange: (state, { payload }) => {
      const newState: any[] = [];
      const _p = current(state)._parishes.find((data) => data.name === payload.value);
      const _v = current(state)._villages.filter((data) => data.parish_id === _p.id);

      if (_v.length > 0) {
        _v.forEach((element: any) => {
          const obj = { value: (element.village_name), label: element.village_name };
          newState.push(obj);
        });
      } else {
        const obj = { value: 'Not Known', label: 'Not Known' };
        newState.push(obj);
      }

      state.villages = newState;
    },

  },
});

const { reducer, actions } = geoDataReducer;

export const {
  setVillages, setSubCounties, setCounties, setParishes, setDistricts, handleDistrictChange, handleCountyChange, handleSubCountyChange, handleParishChange, setCountries
} = actions;

export default reducer;