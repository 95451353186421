import React from 'react'
import ListBudgets from '../../components/accounting/budget/ListBudgets'
import DashboardWrapper from '../../components/DashboardWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Budget = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Budgets' />
        {/* body */}
        <ListBudgets />
      </>
    </DashboardWrapper>
  )
}

export default Budget