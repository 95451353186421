import React, { useEffect } from 'react'
import useProjects from '../../../hooks/useProjects';
import { useLastUrlSegment } from '../../../hooks/useQuery'
import { EllipsisLoader } from '../../global/MyLoaders';
import Left from './Left';
import Right from './Right';

const ViewProjectDetails = () => {
  const id = useLastUrlSegment();
  const { fetchSingleProject, loading } = useProjects();

  useEffect(() => {
    return fetchSingleProject(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return loading ? (<EllipsisLoader />) : (
    <div className='row'>
      <Left />
      <Right />
    </div>
  )
}

export default ViewProjectDetails