import React from 'react'
import ListUnits from '../../components/settings/units/ListUnits'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Units = () => {
  return (
    <SettingsWrapper>
      <>
        <Breadcrumbs title='Units' />
        {/* body */}
        <ListUnits />
      </>
    </SettingsWrapper>
  )
}

export default Units