import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import GenerateReportsView from '../../components/reports/GenerateReportsView'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const GenerateReport = () => {
  return (
    <DashboardWrapper >
      <>
        <Breadcrumbs title='Generate report' parents={[{ link: ROUTES.REPORTS, title: 'Reports' }]} />
        <GenerateReportsView />

      </>
    </DashboardWrapper >
  )
}

export default GenerateReport