import React, { ReactElement } from 'react';

interface ICategories {
  title: string;
  className: string;
}

interface ICardHeaderWithBottomBorderProps {
  title: string;
  categories?: Array<ICategories>;
  isButton?: boolean;
  children?: ReactElement;
}

const CardHeaderWithBottomBorder = ({ title, categories, isButton, children }: ICardHeaderWithBottomBorderProps) => {
  return (
    <div className="d-block d-md-flex justify-content-between border-bottom mb-10">
      <div className="d-block">
        <h5 className="mb-10 card-title border-0 pb-0">{title}</h5>
      </div>
      <div className="d-block d-md-flex sm-mb-15">
        <div className="clearfix">
          {isButton ? children :
            categories && categories.map(({ title, className }) => <span key={title} className={`badge ${className}`}>{title}</span>)
          }
        </div>
      </div>
    </div>
  )
}

export default CardHeaderWithBottomBorder