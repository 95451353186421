import React from 'react'
import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import useActivities from '../../../hooks/useActivities';
import MyDataTable from '../../global/MyDataTable';
import { EllipsisLoader } from '../../global/MyLoaders';

const ActivitiesTable = () => {
  const { activities, loading } = useActivities();

  const applicationColumnData: TableColumn<any>[] = [
    {
      id: 1,
      name: "Activity Name",
      selector: (row) => row.activity_name,
      reorder: true,
      sortable: true,
      grow: 3,
      wrap: true,
    },
    {
      id: 2,
      name: "Activity Methodology",
      selector: (row) => row.activity_methodology,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 3,
      name: "Cost",
      selector: (row) => row.cost,
      reorder: true,
      sortable: true
    }, {
      id: 4,
      name: "Status",
      selector: (row) => row.status === 'Completed' ? <span className='text-success'>{row.status}</span> : <span>{row.status}</span>,
      reorder: true,
      sortable: true
    },

    {
      button: true,
      cell: (row) => (
        <div className='row'>
          <Link to={`/projects/activities/${row.id}`} className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></Link>
          <button className='btn btn-sm btn-primary'><i className='fa fa-edit'></i></button>

          <button className='btn btn-sm btn-danger mx-1'><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];
  return (
    <div className="table-responsive mt-15">

      {loading ? (<EllipsisLoader />) : (
        <MyDataTable data={activities} columns={applicationColumnData} title="" />

      )}
    </div>
  )
}

export default ActivitiesTable