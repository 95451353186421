import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/app/store";
import { doGet } from "../api";
import {
  setCategories,
  setFolderView,
  setSubCategories,
} from "../redux/reducer/accounting";

const useSubCategories = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { subCategories, categories, folderView } = useSelector(
    (state: RootState) => state.accounting
  );

  const getSubCategories = () => {
    setLoading(true);
    doGet("account-subcategpories")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setSubCategories(response.data.data));
        }
      })
      .catch((e: any) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCategories = () => {
    setLoading(true);
    doGet("account-categories")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setCategories(response.data.data));
        }
      })
      .catch((e: any) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getFolderView = () => {
    setLoading(true);
    doGet("account-categories-folder")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setFolderView(response.data.data));
        }
      })
      .catch((e: any) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    getSubCategories,
    loading,
    subCategories,
    getCategories,
    getFolderView,
    categories,
    folderView,
  };
};

export default useSubCategories;
