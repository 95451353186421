import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { doGet } from '../api';
import { RootState } from '../redux/app/store';
import { setApprovingCommittee, setReviewingCommittee } from '../redux/reducer/generalReducers';


const useCommittees = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    approvingCommitte, reviewingCommittee
  } = useSelector(
    (state: RootState) => state.general,
  );

  const getApprovingCommitteeMembers = () => {
    setLoading(true);
    doGet('approving-commitee').then((response) => {
      if (response.status === 200) {
        dispatch(setApprovingCommittee(response.data.data));
      }
    }).catch(() => {
      // log the error
    }).finally(() => {
      setLoading(false);
    })
  }

  const getReviewingCommitteeMembers = () => {
    setLoading(true);
    doGet('reviewing-commitee').then((response) => {
      if (response.status === 200) {
        dispatch(setReviewingCommittee(response.data.data));
      }
    }).catch(() => {
      // log the error
    }).finally(() => {
      setLoading(false);
    })
  }

  const refreshReview = () => {
    doGet('reviewing-commitee').then((response) => {
      if (response.status === 200) {
        dispatch(setReviewingCommittee(response.data.data));
      }
    }).catch(() => {
      // log the error
    }).finally(() => {
      setLoading(false);
    })
  }

  const refreshApproval = () => {
    doGet('approving-commitee').then((response) => {
      if (response.status === 200) {
        dispatch(setApprovingCommittee(response.data.data));
      }
    }).catch(() => {
      // log the error
    }).finally(() => {
      setLoading(false);
    })
  }

  return { getReviewingCommitteeMembers, loading, refreshReview, getApprovingCommitteeMembers, approvingCommitte, reviewingCommittee, refreshApproval }
}

export default useCommittees