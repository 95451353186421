import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { createAssetType } from '../../api/assetManagement';
import { assetTypeDefaultData, errorToastOptions, successToastOptions } from '../../constants';
import { formatErrorMessage } from '../../helpers';
import useAssetManagement from '../../hooks/useAssetManagement';
import Validator from '../../utils/Validator';
import Input from '../global/Input';
import InputSelect from '../global/InputSelect'

interface ICreateAssetTypeProps {
  onCancel: () => void;
}

const CreateAssetType = ({ onCancel }: ICreateAssetTypeProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [assetType, setAssetType] = useState(assetTypeDefaultData);
  const { assetTypeCategories, refreshTypes } = useAssetManagement();
  const validator = new Validator();

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setAssetType({ ...assetType, ...{ [name]: { ...assetType[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setAssetType({ ...assetType, ...{ [name]: { ...assetType[name], ...{ error: true, data: value } } } });
    }
  };

  const handleEditorChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setAssetType({ ...assetType, ...{ [name]: { ...assetType[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setAssetType({ ...assetType, ...{ [name]: { ...assetType[name], ...{ error: true, data: value } } } });
    }
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(assetType, setAssetType)) {
      setFormLoading(true);
      const data = {
        name: assetType.name.data,
        asset_categories_id: assetType.asset_categories_id.data
      }
      createAssetType(data).then((response) => {
        if (response.data.success === true) {
          toast.success('Asset type created successfully', successToastOptions);
          refreshTypes();

          setTimeout(() => {
            onCancel();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).finally(() => {
        setFormLoading(false);
      })
    }
  }

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input handleChange={handleChange} label="Name" name='name' value={assetType.name.data} error={assetType.name.error} errorMessage="Please enter the asset name" required />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect data={assetTypeCategories} id='asset_categories_id' handleChange={handleEditorChange} label="Category" name='asset_categories_id' required value={assetType.asset_categories_id.data} error={assetType.asset_categories_id.error} errorMessage="Please select the category " />
        </div>

      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>

    </form>
  )
}

export default CreateAssetType