import moment from "moment"

// hello there
export const getTimeFromDateTime = (datetime: string) => {
  return moment(datetime).format("hh:mm:ss a");
}

export const getDateFromDateTime = (datetime: string) => {
  return moment(datetime).format("MMMM Do, YYYY");
}

export const formatErrorMessage = (data: any, message = '') => {
  if (!data) {
    return message;
  }
  const response = [];
  const keys = Object.keys(data);
  for (const x of keys) {
    response.push(`${x} - ${data[x][0]}`);
  }
  return response.join(' ');
};

export const formatDataToSelectFormData = (data: any[]) => data.map((res) => ({ value: res?.id, label: res?.name }));

export const formatEmployeeToSelectFormData = (data: any[]) => data.length > 0 ? data.map((res) => ({ value: res?.id, label: `${res?.user?.first_name} ${res?.user?.last_name}` })) : [];

export const formatProjectDataToSelectFormData = (data: any[]) => data ? data.map((res) => ({ value: res?.id, label: res?.project_name })) : [];