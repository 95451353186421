import React from 'react'
import ListPaymentTypes from '../../components/settings/payment_types/ListPaymentTypes'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const PaymentTypes = () => {
  return (
    <SettingsWrapper >
      <>
        <Breadcrumbs title='Payment types' />
        <ListPaymentTypes />

      </>
    </SettingsWrapper >
  )
}

export default PaymentTypes