import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  ERRORMESSAGE,
  errorToastOptions,
  goodsRecievedDefaultData,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useAccounts from "../../../hooks/useAccounts";
import useRequisition from "../../../hooks/useRequisition";
import useStaff from "../../../hooks/useStaff";
import {
  formatAcountSelectData,
  formatbidSelectData,
  formatStaffSelectData,
  getFormData,
} from "../../../utils/helpers";
import Validator from "../../../utils/Validator";
import InputDate from "../../global/InputDate";
import InputSelect from "../../global/InputSelect";
import { EllipsisLoader } from "../../global/MyLoaders";
import useUsers from "../../../hooks/useUsers";

interface IAddGoodsRecievedProps {
  closeModal: () => void;
  refresh: () => void;
}

const AddGoodsRecieved = ({ closeModal, refresh }: IAddGoodsRecievedProps) => {
  const [formLoading, setLoading] = useState(false);
  const [goodRecieved, setGoodRecieved] = useState(goodsRecievedDefaultData);
  const [items, setItems] = useState<any>([]);
  let totalAmount = 0;
  const { loading, getPurchaseOrder, purchaseOrder } = useRequisition();
  const {
    getCashAccount,
    cashAccounts,
    getExpenseAccount,
    expenseAccounts,
    accounts,
  } = useAccounts();
  const { employees } = useStaff();
  const { formattedUsers } = useUsers();

  const validator = new Validator();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getPurchaseOrder(1), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getCashAccount(), [accounts]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getExpenseAccount(), [accounts]);

  const handleSelected = (e: any) => {
    const { value, name } = e;
    if (value !== "") {
      if (name === "purchase_order_id") {
        setItems(
          purchaseOrder.filter((item: any) => item?.id === value)[0].bid?.items
        );
        // update the state
      }
      setGoodRecieved({
        ...goodRecieved,
        ...{
          [name]: { ...goodRecieved[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setGoodRecieved({
        ...goodRecieved,
        ...{
          [name]: { ...goodRecieved[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    // eslint-disable-next-line array-callback-return
    items?.map((item: any) => {
      totalAmount += item?.quantity * item?.price;
    });

    if (validator.checkForEmptyFields(goodRecieved, setGoodRecieved)) {
      setLoading(true);
      const data = {
        ...getFormData(goodRecieved),
        ...{ total_amount: totalAmount },
      };

      doCreate("recieved-goods/create", data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success(
              "New recieved goods have been successfully submitted",
              successToastOptions
            );
            refresh();

            setTimeout(() => {
              closeModal();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .catch((err) => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect
            data={formatbidSelectData(purchaseOrder)}
            id="purchase_order_id"
            label="Purchase Order"
            name="purchase_order_id"
            required
            handleChange={handleSelected}
            error={goodRecieved.purchase_order_id.error}
            errorMessage="Please selected the purchase order recieved"
          />
        </div>
        <div className="col-sm-6">
          <InputDate
            label="Date"
            name="date"
            required
            error={goodRecieved.date.error}
            errorMessage="Please selected the date"
            handleChange={handleSelected}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect
            data={formatAcountSelectData(cashAccounts)}
            id="cash_account_id"
            label="Payment source"
            name="cash_account_id"
            required
            error={goodRecieved.cash_account_id.error}
            errorMessage="Please selected the payment source"
            handleChange={handleSelected}
          />
        </div>

        <div className="col-sm-6">
          <InputSelect
            data={formatAcountSelectData(expenseAccounts)}
            id="expense_account_id"
            label="Credit account"
            name="expense_account_id"
            required
            error={goodRecieved.expense_account_id.error}
            errorMessage="Please selected the account to credit"
            handleChange={handleSelected}
          />
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Supplier</th>
              <th>Description</th>
              <th>Remarks</th>
              <th>Unit</th>
              <th>Quantity</th>
              <th>Price/Unit</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {items?.map((item: any, index: number) => (
              <tr key={item}>
                <td>{item?.supplier_id}</td>
                <td>{item?.description}</td>
                <td>{item?.remarks}</td>
                <td>{item?.units}</td>
                <td>{item?.quantity}</td>
                <td> {item?.price}</td>
                <td>{item?.quantity * item?.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect
            data={formattedUsers}
            id="received_by"
            label="Received by"
            name="received_by"
            required
            error={goodRecieved.received_by.error}
            errorMessage="Please selected the person who received the goods"
            handleChange={handleSelected}
          />
        </div>
        <div className="col-sm-6"></div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddGoodsRecieved;
