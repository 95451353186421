import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { doGet } from '../api';
import { getAllUsers } from '../api/settings';
import { RootState } from '../redux/app/store';
import { setFormattedUsers, setSelectedUser, setUsers } from '../redux/reducer/settings';
import { formatUserSelectData } from '../utils/helpers';

const useUsers = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    users, formattedUsers, selectedUser
  } = useSelector(
    (state: RootState) => state.settings,
  );

  useEffect(() => {

    if (Object.keys(users).length > 0) {
      setLoading(false);
      return;
    }

    getAllUsers().then((response) => {
      if (response.status === 200) {
        dispatch(setUsers(response.data.data.data));
        dispatch(setFormattedUsers(formatUserSelectData(response.data.data.data)));

      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const refresh = () => {
    getAllUsers().then((response) => {
      if (response.status === 200) {
        dispatch(setUsers(response.data.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getSelectedUser = (id: number) => {
    if (id) {
      setLoading(true);
      doGet(`users/${id}`).then((response) => {
        if (response.status === 200) {
          dispatch(setSelectedUser(response.data.data));
        }
      }).finally(() => {
        setLoading(false);
      })
    }
  }

  const getUserNamesById = (id: number) => {
    if (users.length > 0) {
      const user = users.filter((user: any) => user?.id === id);
      if (user) {
        return user[0]?.first_name + " " + user[0]?.last_name
      }
    }
    return '';
  }




  return { users, loading, refresh, formattedUsers, getUserNamesById, getSelectedUser, selectedUser }
}

export default useUsers