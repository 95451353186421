import React from 'react'
import DashboardWrapper from '../DashboardWrapper';

interface IErrorFallBackUIProps {
  error: any;
  resetErrorBoundary: () => void;
}

const ErrorFallBackUI = ({ error, resetErrorBoundary }: IErrorFallBackUIProps) => {
  return (
    <DashboardWrapper>
      <div role="alert" className='row align-items-center justify-content-center h-100 relative text-center'>
        <div className="col">
          <p>Something went wrong:</p>
          <pre>{error.message}</pre>
          <button className='btn btn-outline-info' onClick={resetErrorBoundary}>Try again</button>

        </div>
      </div>
    </DashboardWrapper>
  )
}

export default ErrorFallBackUI