import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { updateHealthTip } from '../../../api/mobile_app';
import { errorToastOptions, healthTipDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useHealthTips from '../../../hooks/useHealthTips';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input'
import { EllipsisLoader } from '../../global/MyLoaders';
import TextEditor from '../../global/TextEditor';

interface IAddHealthTipProp {
  closeModal: () => void,
  id: number
}

const EditHealthTip = ({ closeModal, id }: IAddHealthTipProp) => {
  const [updateLoading, setLoading] = useState(false);

  const [healthTip, setHealthTip] = useState(healthTipDefaultData);
  const validator = new Validator();
  const { refresh, selectedTip, getSelectedTip, loading } = useHealthTips();

  const formNames = ['description', 'title'];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedTip(Number(id)), [id]);

  useEffect(() => {
    let healthTipData = healthTipDefaultData;
    if (!loading) {
      for (let x of formNames) {
        healthTipData = { ...healthTipData, ...{ [x]: { ...healthTipData[x], ...{ error: false, data: selectedTip[x] } } } };
      }
      setHealthTip(healthTipData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setHealthTip({ ...healthTip, ...{ [name]: { ...healthTip[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setHealthTip({ ...healthTip, ...{ [name]: { ...healthTip[name], ...{ error: true, data: value } } } });
    }
  };

  const handleEditorChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setHealthTip({ ...healthTip, ...{ [name]: { ...healthTip[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setHealthTip({ ...healthTip, ...{ [name]: { ...healthTip[name], ...{ error: true, data: value } } } });
    }
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(healthTip, setHealthTip)) {
      setLoading(true);
      const data = {
        title: healthTip.title.data,
        description: healthTip.description.data,
      }

      updateHealthTip(data, id).then((response) => {
        if (response.data.success === true) {
          toast.success('Health tip successfully updated', successToastOptions);
          refresh();

          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).finally(() => {
        setLoading(false);
      })
    }

  }

  return loading ? <EllipsisLoader /> : (
    <form>
      <Input label="Title" name="title" handleChange={handleChange} value={healthTip.title.data} error={healthTip.title.error} errorMessage="Please provide the title" required />

      <div className="form-group">
        <label htmlFor="title">Description</label>
        <TextEditor handleChange={handleEditorChange} name="description" id="description" value={healthTip.description.data} required />
        <small id="description" className="form-text text-muted">Please enter the description here</small>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={updateLoading} className='btn  btn-info' onClick={handleSubmit}>
          {updateLoading ? "Updating..." : "Update"}
        </button>
      </div>

    </form>
  )
}

export default EditHealthTip