import React from 'react'

interface IStatCardProps {
  title: string;
  value: string | number;
  icon: string;
  iconBg: string;
}

const StatCard = ({ icon, title, value, iconBg }: IStatCardProps) => {
  return (
    <div className="col-xl-3 col-lg-6 col-md-6 mb-30">
      <div className="card card-statistics h-100">
        <div className="card-body">
          <div className="clearfix">
            <div className={`float-left icon-box ${iconBg} rounded-circle`}>
              <span className="text-white">
                <i className={`fa ${icon} highlight-icon`} aria-hidden="true"></i>
              </span>
            </div>
            <div className="float-right text-right">
              <p className="card-text text-dark">{title}</p>
              <h4>{value}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatCard