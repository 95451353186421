import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { doGet } from '../api';
import { getActivityDetails, getAllActivities } from '../api/projects';
import { RootState } from '../redux/app/store';
import { setActivities, setActivityParameters, setActivityReports, setActivityStats, setActivityTeamRoles, setAssignedPermissions, setRolePermissions, setSelectedActivity, setSelectedActivityParameter, setSelectedActivityTeamRole, setServices } from '../redux/reducer/projects';

const useActivities = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    activities, selectedActivity, activityTeamRoles, activityParameters, selectedActivityTeamRole, selectedActivityParameter, rolePermission, assignedPermissions, activityStats, activityReports, services
  } = useSelector(
    (state: RootState) => state.projects,
  );

  useEffect(() => {

    if (Object.keys(activities).length > 0) {
      setLoading(false);
      return;
    }

    getAllActivities().then((response) => {
      if (response.status === 200) {
        dispatch(setActivities(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities]);

  const refresh = () => {
    getAllActivities().then((response) => {
      if (response.status === 200) {
        dispatch(setActivities(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getServices = () => {
    setLoading(true);
    doGet('clinic-report-services').then((response) => {
      if (response.status === 200) {
        dispatch(setServices(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getActivity = (id: number) => {
    setLoading(true);
    getActivityDetails(id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedActivity(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }


  const getActivityTeamRoles = () => {
    setLoading(true);
    if (activityTeamRoles.length > 0) {
      setLoading(false);
      return;
    }
    doGet('activity-roles').then((response) => {
      if (response.status === 200) {
        dispatch(setActivityTeamRoles(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const refreshActivityTeamRoles = () => {
    doGet('activity-roles').then((response) => {
      if (response.status === 200) {
        dispatch(setActivityTeamRoles(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getActivityParameters = () => {
    setLoading(true);
    if (activityParameters.length > 0) {
      setLoading(false);
      return;
    }
    doGet('activity-parameters').then((response) => {
      if (response.status === 200) {
        dispatch(setActivityParameters(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const refreshActivityParameters = () => {
    doGet('activity-parameters').then((response) => {
      if (response.status === 200) {
        dispatch(setActivityParameters(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getSelectedActivityParameter = (id: number) => {
    setLoading(true);

    doGet('activity-parameters/' + id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedActivityParameter(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getSelectedActivityTeamRole = (id: number) => {
    setLoading(true);
    doGet('activity-roles/' + id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedActivityTeamRole(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getAssignedPermissions = (id: number) => {
    setLoading(true);
    doGet('activity-roles/' + id + '/assigned_permissions').then((response) => {
      if (response.status === 200) {
        dispatch(setAssignedPermissions(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getAvailablePermissions = (id: number) => {
    setLoading(true);
    doGet('activity-roles/' + id + '/unassigned_permissions').then((response) => {
      if (response.status === 200) {
        dispatch(setRolePermissions(response.data.data));
        getAssignedPermissions(id);
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getActivityStats = (id: number) => {
    setLoading(true);
    doGet('activities/' + id + '/activity_stats').then((response) => {
      if (response.status === 200) {
        dispatch(setActivityStats(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getActivityReports = (activity_id: number) => {
    setLoading(true);
    doGet(`/activities/${activity_id}/reports`).then((response) => {
      if (response.status === 200) {
        dispatch(setActivityReports(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }



  return { activities, loading, refresh, getActivity, selectedActivity, activityTeamRoles, getActivityTeamRoles, activityParameters, getActivityParameters, refreshActivityTeamRoles, refreshActivityParameters, getSelectedActivityParameter, getSelectedActivityTeamRole, selectedActivityTeamRole, selectedActivityParameter, getAvailablePermissions, rolePermission, getAssignedPermissions, assignedPermissions, getActivityStats, activityStats, getActivityReports, activityReports, getServices, services }
}

export default useActivities