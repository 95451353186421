import React from 'react'

interface ICardListItemProps {
  title: string;
  value: string;
}

const CardListItem = ({ title, value }: ICardListItemProps) => {
  return (
    <div className="col-sm-12">
      <div className="row align-items-center m-0">
        <h6 className='mr-3'>{title}</h6>
        <p>{value}</p>
      </div>
    </div>
  )
}

export default CardListItem