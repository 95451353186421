import React, { useEffect, useState } from 'react'
import Input from '../../global/Input'
import ImagePicker from "../../global/ImagePicker";
import "./profile.css";
import Card from '../../../parts/Card';
import MyModal from "../../global/MyModal";
import ChangePassword from "./ChangePassword";
import { editProfileDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import Validator from '../../../utils/Validator';
import { doCreate } from '../../../api';
import toast from 'react-hot-toast';
import { formatErrorMessage } from '../../../helpers';
import { updateSession } from '../../../utils/helpers';
import { baseURLImage } from '../../../utils/axios';

const Profile = () => {
  const [user] = useState(JSON.parse(sessionStorage.user)?.user);
  const [selectedUser, setSelectedUser] = useState(editProfileDefaultData);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const validator = new Validator();

  useEffect(() => {
    const formNames = Object.keys(user).map((data) => data);

    let profileData = editProfileDefaultData;
    for (let x of formNames) {
      profileData = { ...profileData, ...{ [x]: { ...profileData[x], ...{ error: false, data: user[x] } } } };
    }
    setSelectedUser(profileData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setSelectedUser({ ...selectedUser, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setSelectedUser({ ...selectedUser, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleImageChange = (e: any) => {
    setSelectedUser({ ...selectedUser, ...{ profile_pic: { error: false, data: e } } });

    // update session
    updateSession({ profile_pic: baseURLImage + "" + e });

  }


  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(selectedUser, setSelectedUser)) {
      setLoading(true);

      const data = {
        first_name: selectedUser.first_name.data,
        last_name: selectedUser.last_name.data,
        middle_name: selectedUser.middle_name.data,
        mobile_phone: selectedUser.mobile_phone.data,
        email: selectedUser.email.data,
        profile_pic: selectedUser.profile_pic.data
      }

      try {
        const response = await doCreate('users/' + user?.id + '/update_account', data);
        if (response.data.success === true) {
          toast.success('Your details have been updated successfully', successToastOptions);
          //refresh(); // refresh the hook store

        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <>
      <MyModal modalOpen={isOpen} setModalOpen={setIsOpen} id="change-password" title="Change Password">
        <ChangePassword onCancel={handleModalClick} />
      </MyModal>
      <Card>
        <>
          <div className="p-6 border-b border-slate-200">

            <section>
              <ImagePicker handleChange={handleImageChange} imageUrl={selectedUser.profile_pic.data} />
            </section>

            <section>
              <h5 className="text-slate-800 font-bold text-xl mb-1">Details</h5>
              <p className="text-sm">
                From here you can change and update your own personal information.{" "}
              </p>
              <div className="row mx-0 mt-5">
                {/* inputs */}
                <div className="col-6">
                  <Input handleChange={handleChange} label="First Name" name="first_name" value={selectedUser.first_name.data} error={selectedUser.first_name.error} errorMessage="Please enter the first name" noLabel />
                </div>
                <div className="col-6">
                  <Input handleChange={handleChange} label="Last Name" name="last_name" noLabel value={selectedUser.last_name.data} error={selectedUser.last_name.error} errorMessage="Please enter the last name" />
                </div>
                <div className="col-6">
                  <Input handleChange={handleChange} label="Middle Name" name="middle_name" value={selectedUser?.middle_name.data} noLabel />
                </div>
                <div className="col-6">
                  <Input handleChange={handleChange} label="Mobile number" name="mobile_phone" noLabel value={selectedUser.mobile_phone.data} error={selectedUser.mobile_phone.error} errorMessage="Please enter the telephone number" />
                </div>
                {/* end inputs */}
              </div>
            </section>

            <section>
              <h5 className="text-slate-800 font-bold text-xl mb-1">Email</h5>
              <p className="text-sm">
                This is the email address where we send Notifications, so if you
                change it make you that you have access to the new email address
              </p>
              <div className="w-12/12 md:w-3/5">
                <div className="row align-items-center mx-0 mt-2">
                  {/* inputs */}
                  <div className="col-9">
                    <Input handleChange={handleChange} label="" name="email" noLabel value={selectedUser.email.data} error={selectedUser.email.error} errorMessage="Please enter the email address" />
                  </div>
                  <div className="col-3">
                    {/* <button className="btn btn-sm btn-info">
                    Change
                  </button> */}

                  </div>
                </div>
                {/* end inputs */}
              </div>
            </section>

            <section>
              <h5 className="text-slate-800 font-bold text-xl mb-1">Password</h5>
              <p className="text-sm">
                A password must be kept as a secret and should never be shared with
                anyone
              </p>
              <div className="mt-5">
                <button className="btn btn-sm btn-danger" onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(true);
                }}>
                  Change Password
                </button>
              </div>
            </section>
          </div>
          <hr />

          <div className="px-3 py-4">
            <div className="row mx-0">
              <button className="btn btn-sm btn-outline-danger mr-4">
                Cancel
              </button>
              <button className="btn btn-sm btn-primary" disabled={loading} onClick={handleSubmit}>
                {loading ? "Processing..." : "Save changes"}
              </button>
            </div>
          </div>
        </>
      </Card>
    </>
  )
}

export default Profile