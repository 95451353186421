import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { stockCreate } from '../../../api/stocks';
import { ERRORMESSAGE, errorToastOptions, outGoingStockDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useDepartments from '../../../hooks/useDepartments';
import useOutGoingStocks from '../../../hooks/useOutGoingStocks';
import useStocks from '../../../hooks/useStocks';
import { formatDepartmentSelectData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputDate from '../../global/InputDate';
import InputSelect from '../../global/InputSelect';
import { EllipsisLoader } from '../../global/MyLoaders';
import Textarea from '../../global/Textarea';

interface IAddOutGoingProps {
  closeModal: () => void
}

const AddOutGoing = ({ closeModal }: IAddOutGoingProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [outGoingStock, setOutGoingStock] = useState(outGoingStockDefaultData);

  const { formattedDepartments } = useDepartments();

  const { refresh } = useOutGoingStocks();
  const { stocks, loading } = useStocks();

  const validator = new Validator();

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setOutGoingStock({ ...outGoingStock, ...{ [name]: { ...outGoingStock[name], error: false, data: value } } });
    } else {
      // indicate an error
      setOutGoingStock({ ...outGoingStock, ...{ [name]: { ...outGoingStock[name], error: true, data: value } } });
    }
  }

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setOutGoingStock({ ...outGoingStock, ...{ [name]: { ...outGoingStock[name], error: false, data: value } } });
    } else {
      // indicate an error
      setOutGoingStock({ ...outGoingStock, ...{ [name]: { ...outGoingStock[name], error: true, data: value } } });
    }
  }


  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(outGoingStock, setOutGoingStock)) {
      setFormLoading(true);

      const data = {
        description: outGoingStock.description.data,
        stock_id: outGoingStock.stock_id.data,
        department_id: outGoingStock.department_id.data,
        quantity: outGoingStock.quantity.data,
        taken_at: outGoingStock.taken_at.data,
      }

      try {
        const newProject = await stockCreate(data, 'outgoing_stock/create');
        if (newProject.data.success === true) {
          toast.success('New out going stock registered successfully', successToastOptions);
          refresh(); // refresh the hook store
          setOutGoingStock(outGoingStockDefaultData);
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(newProject.data.data), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setFormLoading(false)
      }

    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect data={formatDepartmentSelectData(stocks)} id='stock_id' name='stock_id' label='Product' handleChange={handleSelectChange} required value={outGoingStock.stock_id.data} error={outGoingStock.stock_id.error} errorMessage="Please select the product" />
        </div>
        <div className="col-sm-6">
          <InputSelect data={formattedDepartments} id='department_id' name='department_id' label='Department' handleChange={handleSelectChange} required value={outGoingStock.department_id.data} error={outGoingStock.department_id.error} errorMessage="Please select the department" />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='quantity' label='Quantity' handleChange={handleChange} type='number' value={outGoingStock.quantity.data} error={outGoingStock.quantity.error} errorMessage="Please enter the quantity taken" required />
        </div>
        <div className="col-sm-6">
          <InputDate name='taken_at' label='Date taken' handleChange={handleSelectChange} required value={outGoingStock.taken_at.data} error={outGoingStock.taken_at.error} errorMessage="Please select the date taken" />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Textarea handleChange={handleChange} label="Description" required name='description' value={outGoingStock.description.data} error={outGoingStock.description.error} errorMessage="Please enter the description" />
        </div>
      </div>
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>{formLoading ? "Creating..." : "Create"}</button>
      </div>
    </form>
  )
}

export default AddOutGoing