import React, { useEffect } from "react";
import useStocks from "../../../hooks/useStocks";
import { EllipsisLoader } from "../../global/MyLoaders";
import { formatCurrency } from "../../../utils/helpers";

const ListStockReports = () => {
  const { loading, getStockReport, stockReport } = useStocks();
  console.log(stockReport);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getStockReport(), []);
  return loading ? (
    <EllipsisLoader />
  ) : (
    <div>
      {stockReport?.available?.length > 0 && (
        <>
          <h5>Available</h5>
          <table className="table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Last refill</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {stockReport.available?.map((item: any) => (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td>{item.bought_on}</td>
                  <td>{formatCurrency(item.total_price)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {stockReport?.almost?.length > 0 && (
        <>
          <h5>Almost Out</h5>
          <table className="table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Last refill</th>
                <th>Amount</th>
              </tr>
            </thead>

            <tbody>
              {stockReport.almost?.map((item: any) => (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td>{item.bought_on}</td>
                  <td>{formatCurrency(item.total_price)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {stockReport?.out?.length > 0 && (
        <>
          <h5>Out of order</h5>
          <table className="table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Last refill</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {stockReport.out?.map((item: any) => (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td>{item.bought_on}</td>
                  <td>{formatCurrency(item.total_price)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default ListStockReports;
