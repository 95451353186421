import React from "react";
import DashboardWrapper from "../../components/DashboardWrapper";
import Breadcrumbs from "../../parts/Breadcrumbs";
import ListTrailBalance from "../../components/accounting/trail_balance/ListTrailBalance";
import PayrollReport from "../../components/reports/PayrollReport";
import BudgetComparisionReport from "../../components/accounting/budget_comparision/BudgetComparisionReport";

const BudgetReport = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title="Budget Comparision Report" />
        {/* body */}
        <BudgetComparisionReport />
      </>
    </DashboardWrapper>
  );
};

export default BudgetReport;
