import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper';
import ListPatients from '../../components/doctor/patients/ListPatients';
import Breadcrumbs from '../../parts/Breadcrumbs'

const Patients = () => {

  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Patients' />
        {/* body */}


        <ListPatients />
      </>
    </DashboardWrapper>

  )
}

export default Patients