/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/state-in-constructor */
import { useEffect, useState } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import { baseURLImage } from "../../utils/axios";

interface textareaProps {
  label?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  error?: boolean;
  errorMessage?: string;
  sm?: boolean;
  light?: boolean;
  required?: boolean;
  inputCustomClass?: string;
  withNoLabel?: boolean;
  rows?: number;
  handleChange: (e: any) => void;
}

/*
export class MyTextEditor extends Component<textareaProps> {


  getInitialState = (defaultValue: any) => {
    if (defaultValue) {
      const blocksFromHtml = htmlToDraft(defaultValue);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  state = {
    editorState: this.getInitialState(this.props.value),
  }


  onEditorStateChange = (editorState: any): void => {
    this.setState({
      editorState,
    });

    const data = { name: this.props.name, value: draftToHtml(convertToRaw(editorState.getCurrentContent())) } as unknown as HTMLInputElement;

    this.props.handleChange(data);
  };

  render() {
    const { editorState } = this.state;
    const {
      id, label, withNoLabel, required, name, handleChange,
    } = this.props;
    return (
      <div>
        {label && (
          <label htmlFor={id} className="py-2 font-semibold text-sm">
            {label}
            {!withNoLabel ? required ? <span className="text-danger text-sm font-medium mb-3">*</span> : <span className="text-gray-500 ml-3 text-xs">(Optional)</span> : null}
          </label>
        )}
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper normalise "
          wrapperStyle={{ border: '1px solid #eee' }}
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          editorStyle={{ background: '#fff', padding: '0.5rem 1rem', overflow: 'auto' }}

        />
        <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
          className="input w-full hidden"
          name={name}
          id={id}
          onChange={handleChange}
          style={{ display: 'none' }}
        />
      </div>
    );
  }
}

*/

function uploadImageCallBack(file: any) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", baseURLImage + "/upload/image");
    xhr.setRequestHeader("Authorization", "Client-ID XXXXX");
    const data = new FormData();
    data.append("file", file);
    xhr.send(data);
    xhr.addEventListener("load", () => {
      const response = xhr.responseText;
      const obj = {
        data: {
          link: response,
        },
      };
      resolve(obj);
    });
    xhr.addEventListener("error", () => {
      const error = JSON.parse(xhr.responseText);
      reject(error);
    });
  });
}

const TextEditor = ({
  value,
  handleChange,
  name,
  label,
  error,
  errorMessage,
  id,
  inputCustomClass,
  light,
  placeholder,
  required,
  rows,
  sm,
  withNoLabel,
}: textareaProps) => {
  const [loaded, setLoaded] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    // const blocksFromHTML = convertFromHTML(data)
    if (value) {
      const blocksFromHtml = htmlToDraft(value);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  });
  useEffect(() => {
    setEditorState(() => {
      // const blocksFromHTML = convertFromHTML(data)
      if (value) {
        const blocksFromHtml = htmlToDraft(value);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        return EditorState.createWithContent(contentState);
      }
      return EditorState.createEmpty();
    });
    setLoaded(true);
  }, [loaded]);

  const onEditorStateChange = (editorState: EditorState): void => {
    setEditorState(editorState);

    const data = {
      name: name,
      value: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    } as unknown as HTMLInputElement;

    handleChange(data);
  };

  return (
    <div>
      {label && (
        <label htmlFor={id} className="py-2 font-semibold text-sm">
          {label}
          {!withNoLabel ? (
            required ? (
              <span className="text-danger text-sm font-medium mb-3">*</span>
            ) : (
              <span className="text-gray-500 ml-3 text-xs">(Optional)</span>
            )
          ) : null}
        </label>
      )}
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper normalise "
        wrapperStyle={{ border: "1px solid #eee" }}
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        editorStyle={{
          background: "#fff",
          padding: "0.5rem 1rem",
          overflow: "auto",
        }}
        toolbar={{
          image: {
            uploadCallback: uploadImageCallBack,
            alt: { present: true, mandatory: true },
          },
        }}
      />
      <textarea
        disabled
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        className="input w-full hidden"
        name={name}
        id={id}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default TextEditor;
