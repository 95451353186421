import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doGet } from "../api";
import { RootState } from "../redux/app/store";
import {
  setCashRequisition,
  setSelectedCashRequisition,
} from "../redux/reducer/accounting";

const useCashRequisition = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { cashRequisition, selectedCashRequisition } = useSelector(
    (state: RootState) => state.accounting
  );

  useEffect(() => {
    if (cashRequisition.length > 0) {
      setLoading(false);
      return;
    }

    doGet("cash-requisitions")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setCashRequisition(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    setLoading(true);
    doGet("cash-requisitions")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setCashRequisition(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCashRequisition = (id: number) => {
    setLoading(true);
    doGet(`cash-requisitions/${id}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setSelectedCashRequisition(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    cashRequisition,
    loading,
    refresh,
    getCashRequisition,
    selectedCashRequisition,
  };
};

export default useCashRequisition;
