import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { doGet } from '../api';
import { getAllPayrolls, getPayrollStats, getStaffPayroll } from '../api/hr';
import { RootState } from '../redux/app/store';
import { resetPayroll, setGeneratedPayroll, setPayrolls, setPayrollStats, setSelectedPayroll, setSelectedPayrollStats, setSelectedStaffPayroll } from '../redux/reducer/hr';

const usePayrolls = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    payrolls, selectedStaffPayroll, selectedStaffPayrollStats, payrollStats, generatedPayroll
  } = useSelector(
    (state: RootState) => state.hr,
  );


  useEffect(() => {

    if (Object.keys(payrolls).length > 0) {
      setLoading(false);
      return;
    }

    getAllPayrolls().then((response) => {
      if (response.status === 200) {
        dispatch(setPayrolls(response.data.data));
      }
    }).catch(() => {
      dispatch(setPayrolls([...payrolls]));
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payrolls]);

  const getSelectedPayroll = (id: number) => {
    setLoading(true);
    getAllPayrolls(Number(id)).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedPayroll(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getSelectedPayrollStats = (id: number) => {
    setLoading(true);
    getPayrollStats(Number(id)).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedPayrollStats(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getAllStats = () => {
    setLoading(true);
    doGet('payrolls/stats').then((response) => {
      if (response.status === 200) {
        dispatch(setPayrollStats(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getAllStatSearch = (startDate: string, endDate: string) => {
    setLoading(true);
    const url = `payrolls/stats?start_date=${startDate}&end_date=${endDate}`;
    console.log(url);
    doGet(url).then((response) => {
      if (response.status === 200) {
        dispatch(setPayrollStats(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }


  const getSelectedStaffPayroll = (id: number) => {
    setLoading(true);
    getStaffPayroll(Number(id)).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedStaffPayroll(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const refresh = () => {
    getAllPayrolls().then((response) => {
      if (response.status === 200) {
        dispatch(setPayrolls(response.data.data));
      }
    }).catch(() => {
      dispatch(setPayrolls([...payrolls]));
    }).finally(() => {
      setLoading(false);
    })
  }

  const resetPayrollForm = () => {
    dispatch(resetPayroll());
  }

  const refreshGeneratedPayroll = () => {

    setLoading(true);
    doGet('payrolls/generate/list').then((response) => {
      if (response.status === 200) {
        dispatch(setGeneratedPayroll(response.data.data));
      }
    }).catch(() => {
      dispatch(setGeneratedPayroll([]));
    }).finally(() => {
      setLoading(false);
    })
  }

  const getGeneratedPayroll = () => {
    if (generatedPayroll.length > 0) {
      return;
    }
    setLoading(true);
    doGet('payrolls/generate/list').then((response) => {
      if (response.status === 200) {
        dispatch(setGeneratedPayroll(response.data.data));
      }
    }).catch(() => {
      dispatch(setGeneratedPayroll([]));
    }).finally(() => {
      setLoading(false);
    })
  }

  return { loading, refresh, payrolls, getSelectedPayroll, getSelectedPayrollStats, getSelectedStaffPayroll, selectedStaffPayroll, selectedStaffPayrollStats, resetPayrollForm, getAllStats, payrollStats, getAllStatSearch, getGeneratedPayroll, generatedPayroll, refreshGeneratedPayroll }
}

export default usePayrolls