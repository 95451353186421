import React, { useEffect } from 'react'
import DashboardWrapper from '../../../components/DashboardWrapper'
import { EllipsisLoader } from '../../../components/global/MyLoaders'
import ViewDetails from '../../../components/hr/employees/ViewDetails'
import { useLastUrlSegment } from '../../../hooks/useQuery'
import useStaff from '../../../hooks/useStaff'
import Breadcrumbs from '../../../parts/Breadcrumbs'
import { ROUTES } from '../../../routes/routes'

const ViewEmployeeDetails = () => {
  const { loading, getStaff } = useStaff();
  const id = useLastUrlSegment();
  useEffect(() => {
    return getStaff(Number(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='View Employee details' parents={[{ link: ROUTES.EMPLOYEES, title: "Employees" }]} />
        {loading ? <EllipsisLoader /> : <ViewDetails />}

      </>
    </DashboardWrapper>
  )
}

export default ViewEmployeeDetails