import React from 'react'
import Card from '../../parts/Card'

interface IDashboardProps {
  title: string;
  description?: string;
  stats?: string | number
  bgColor?: string
}

const DashboardCard = ({ title, description, stats, bgColor }: IDashboardProps) => {
  return (
    <Card>
      <div className={`row ${bgColor ? 'bg-gray' : bgColor}`}>
        <div className="col-sm-8">
          <p>{title}</p>
          <p>{description}</p>
        </div>
        {stats && (
          <div className="col-sm-4">
            <p>{stats}</p>
          </div>

        )}
      </div>
    </Card>
  )
}

export default DashboardCard