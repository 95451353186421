import React, { useState } from 'react'
import maleImage from "../../../assets/images/male.jpg";
import useActivities from '../../../hooks/useActivities';
import { useLastUrlSegment } from '../../../hooks/useQuery';
import { isImage } from '../../../utils/helpers';
import ConfirmDelete from '../../global/ConfirmDelete';
import MyModal from '../../global/MyModal';

interface ITeamMemberProps {
  firstname: string;
  lastname: string;
  role: string;
  teamMemberid: number;
  image: string;
  status?: boolean;
}

export const TeamMember = ({ firstname, lastname, role, teamMemberid, image, status }: ITeamMemberProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const id = useLastUrlSegment();
  const { getActivity } = useActivities();
  const dp = isImage(image);
  return (
    <>
      <MyModal modalOpen={isOpen} setModalOpen={setIsOpen} id="remove-team-member" title="Remove team member from project">
        <ConfirmDelete closeModal={() => setIsOpen(false)} path={`projects/activity-team/${teamMemberid}/delete`} refresh={() => getActivity(id)} />
      </MyModal>
      <li className="mb-10">
        <div className="media">
          <div className="position-relative">
            <img className="img-fluid mr-15 avatar-small" src={dp ? image : maleImage} alt="" />
            <i className="avatar-online bg-secondary"></i>
          </div>
          <div className="media-body">
            <div className="row m-0">
              <div className="col-sm-10">
                <h6 className="mt-0 text-info">{`${firstname} ${lastname}`}</h6>
                <p className='text-muted'>{role}</p>

              </div>
              <div className="col-sm-2">
                {status && (
                  <button className='btn btn-sm btn-danger' onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(true);
                  }}><i className='fa fa-trash'></i></button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="divider mt-10"></div>
      </li>
    </>
  )
}
