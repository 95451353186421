import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../../api';
import { addActivityRoleDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../../constants';
import { formatErrorMessage } from '../../../../helpers';
import useActivities from '../../../../hooks/useActivities';
import Validator from '../../../../utils/Validator';
import Input from '../../../global/Input';
import { EllipsisLoader } from '../../../global/MyLoaders';
import Textarea from '../../../global/Textarea';

interface IAddAcivityRoleProps {
  closeModal: () => void;
  refresh: () => void;
  id: number;
}

const EditActivityRole = ({ closeModal, refresh, id }: IAddAcivityRoleProps) => {
  const [activityRole, setActivityRole] = useState(addActivityRoleDefaultData);
  const [formLoading, setLoading] = useState(false);
  const validator = new Validator();
  const { loading, selectedActivityTeamRole, getSelectedActivityTeamRole } = useActivities();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedActivityTeamRole(id), []);
  const formNames = ['description', 'name'];

  useEffect(() => {
    let role = addActivityRoleDefaultData;
    if (!loading) {
      for (let x of formNames) {
        role = { ...role, ...{ [x]: { ...role[x], ...{ error: false, data: selectedActivityTeamRole[x] } } } };
      }
      setActivityRole(role);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setActivityRole({ ...activityRole, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setActivityRole({ ...activityRole, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(activityRole, setActivityRole)) {
      setLoading(true);

      const data = {
        name: activityRole.name.data,
        description: activityRole.description.data,
      }

      try {
        const response = await doCreate('activity-roles/' + id + '/update', data);
        if (response.data.success === true) {
          toast.success('Activity role updated successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }
    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>

      <div className="form-row">
        <div className="col-sm-6">
          <Input name='name' label='Role Name' handleChange={handleChange} error={activityRole?.name.error} errorMessage="Please provide the role name" value={activityRole?.name.data} required />
        </div>

      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Textarea handleChange={handleChange} name='description' label='Description' error={activityRole?.description.error} errorMessage="Please provide a role description" value={activityRole?.description.data} required />
        </div>

      </div>


      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn btn-primary' onClick={handleSubmit}>{formLoading ? "Updating..." : "Update"}</button>
      </div>
    </form>
  )
}

export default EditActivityRole