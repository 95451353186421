import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { consolidatedDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../../constants';
import { formatErrorMessage } from '../../../../helpers';
import Input, { InputNoLabel } from '../../../global/Input';
import { doCreate } from '../../../../api';
import AddServiceName from './AddServiceName';
import useActivities from '../../../../hooks/useActivities';
import { EllipsisLoader } from '../../../global/MyLoaders';
import { formatConsolidatedFormData, formatDepartmentSelectData, getFormData, getItemData } from '../../../../utils/helpers';
import InputSelect from '../../../global/InputSelect';
import InputDate from '../../../global/InputDate';
import useGeoData from '../../../../hooks/useGeoData';
import useClinics from '../../../../hooks/useClinics';
import Validator from '../../../../utils/Validator';

interface ICreateProjectProps {
  onCancel: () => void;
  refresh: () => void;
}

const AddConsolidatedReport = ({ onCancel, refresh }: ICreateProjectProps) => {
  const [formLoading, setLoading] = useState(false);
  const [addName, setAddName] = useState(false);
  const [values, setValues] = useState(consolidatedDefaultData);

  const [columns, setColumns] = useState<Array<any>>([]);

  const { services, getServices, loading } = useActivities();
  const { _districts } = useGeoData();
  const { clinics } = useClinics();

  const validator = new Validator();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getServices(), []);

  useEffect(() => {
    setColumns(formatConsolidatedFormData(services))
  }, [services]);

  const handleColumns = (item: any) => {
    setColumns([...columns, { ...item, name: item }]);
  }

  const handleItemChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, data: any) => {
    const { name, value } = e.target as HTMLInputElement;
    const newState = columns.filter((item: any) => item?.name !== data?.name);
    // console.log(newState);
    data.data[name] = value;
    // console.log(data.data[name]);
    setColumns([...newState, data]);
  }

  // console.log(columns);

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setValues({ ...values, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setValues({ ...values, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setValues({ ...values, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setValues({ ...values, ...{ [name]: { error: true, data: value } } });
    }
  }


  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(values, setValues)) {
      setLoading(true);

      const others = getFormData(values);

      const data = { ...others, items: getItemData(columns) };
      try {
        setLoading(true);
        const newProject = await doCreate('reports/create', data);
        if (newProject.data.success === true) {
          toast.success('New Project report created successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            onCancel();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(newProject.data.data), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }
    }
  }

  return loading ? <EllipsisLoader /> : addName ? (
    <AddServiceName setAddName={setAddName} setColumns={handleColumns} />
  ) : (

    <form >
      <div className="form-row">
        <div className="col-sm-5">
          <InputSelect data={formatDepartmentSelectData(clinics)} name='clinic_id' id='health_facility' required label='Health Facility' handleChange={handleSelectChange} error={values.clinic_id.error} errorMessage="Please select the health facility" />
        </div>
        <div className="col-sm-3">
          <Input handleChange={handleChange} label="Level" name='level' required error={values.level.error} errorMessage="Please provide the level" />
        </div>
        <div className="col-sm-4">
          <InputSelect data={formatDepartmentSelectData(_districts)} name='district_id' id='district_id' required label='District' handleChange={handleSelectChange} error={values.district_id.error} errorMessage="Please select the district" />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputDate label='Start date' name='start_date' required handleChange={handleSelectChange} error={values.start_date.error} errorMessage="Please select the start date" />
        </div>
        <div className="col-sm-6">
          <InputDate label='End date' name='end_date' required handleChange={handleSelectChange} error={values.end_date.error} errorMessage="Please select the end date" />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input handleChange={handleChange} label="Program officer" name='program_officer' required error={values.level.error} errorMessage="Please enter the program officer name" />
        </div>
        <div className="col-sm-6">
          <Input handleChange={handleChange} label="Client name" name='client_name' required error={values.client_name.error} errorMessage="Please provide the client name" />
        </div>
      </div>

      <table className='table table-bordered'>
        <thead>

          <tr>
            <th rowSpan={2} colSpan={1}>Service Provided</th>
            <th rowSpan={2} colSpan={1}>No. of session</th>
            <th colSpan={2}>5-9</th>
            <th colSpan={2}>10-14</th>
            <th colSpan={2}>Others</th>
            <th colSpan={2}>Total</th>
            <th colSpan={1}>Comments</th>
            <th colSpan={1}>Action</th>
          </tr>

          <tr>
            <th>F</th>
            <th>M</th>
            <th>F</th>
            <th>M</th>
            <th>F</th>
            <th>M</th>
            <th>F</th>
            <th>M</th>
            <th></th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {columns.map((column, id) => (
            <tr key={column.name}>
              <td>
                {column.name}
              </td>
              <td>
                <InputNoLabel
                  name={`no_of_session`} onChange={(e: any) => handleItemChange(e, column)} />
              </td>
              <td>
                <InputNoLabel
                  name={`female_9`} onChange={(e: any) => handleItemChange(e, column)} />
              </td>
              <td>
                <InputNoLabel
                  name={`male_9`} onChange={(e: any) => handleItemChange(e, column)} />
              </td>
              <td>
                <InputNoLabel
                  name={`female_14`} onChange={(e: any) => handleItemChange(e, column)} />
              </td>
              <td>
                <InputNoLabel
                  name={`male_14`} onChange={(e: any) => handleItemChange(e, column)} />
              </td>
              <td>
                <InputNoLabel
                  name={`female_others`} onChange={(e: any) => handleItemChange(e, column)} />
              </td>
              <td>
                <InputNoLabel
                  name={`male_others`} onChange={(e: any) => handleItemChange(e, column)} />
              </td>
              <td>
                <InputNoLabel
                  name={`female_total`} onChange={(e: any) => handleItemChange(e, column)} />
              </td>
              <td>
                <InputNoLabel
                  name={`male_total`} onChange={(e: any) => handleItemChange(e, column)} />
              </td>
              <td>
                <InputNoLabel
                  name={`comment`} onChange={(e: any) => handleItemChange(e, column)} />
              </td>
              <td>
              </td>
            </tr>

          ))}
        </tbody>
      </table>
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit} >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  )
}

export default AddConsolidatedReport