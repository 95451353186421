import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { ERRORMESSAGE, errorToastOptions, prchaseOrderDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useCommittees from '../../../hooks/useCommittees';
import useRequisition from '../../../hooks/useRequisition';
import { formatbidSelectData, formatStaffSelectData, getFormData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import InputDate from '../../global/InputDate';
import InputSelect from '../../global/InputSelect';
import { EllipsisLoader } from '../../global/MyLoaders';
import Textarea from '../../global/Textarea';

interface IAddPurchaseOrderProps {
  closeModal: () => void;
  refresh: () => void;
}

const AddPurchaseOrder = ({ closeModal, refresh }: IAddPurchaseOrderProps) => {
  const [formLoading, setLoading] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState(prchaseOrderDefaultData);
  const [items, setItems] = useState<any>([]);
  const { loading, getBidAnalysis, bidAnalysis } = useRequisition();
  const { approvingCommitte, getApprovingCommitteeMembers } = useCommittees();

  const validator = new Validator();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getApprovingCommitteeMembers(), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getBidAnalysis(), []);

  const handleSelected = (e: any) => {
    const { value, name } = e;
    if (value !== '') {
      if (name === 'bid_analysis_id') {
        setItems(bidAnalysis.filter((item: any) => item?.id === value)[0].items);
      }
      // update the state
      setPurchaseOrder({ ...purchaseOrder, ...{ [name]: { ...purchaseOrder[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setPurchaseOrder({ ...purchaseOrder, ...{ [name]: { ...purchaseOrder[name], ...{ error: true, data: value } } } });
    }
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    if (value !== '') {
      // update the state
      setPurchaseOrder({ ...purchaseOrder, ...{ [name]: { ...purchaseOrder[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setPurchaseOrder({ ...purchaseOrder, ...{ [name]: { ...purchaseOrder[name], ...{ error: true, data: value } } } });
    }
  };



  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(purchaseOrder, setPurchaseOrder)) {
      setLoading(true);
      const data = getFormData(purchaseOrder);

      doCreate('purchase-order/create', data).then((response) => {
        if (response.data.success === true) {
          toast.success('New Purchase Order has been successfully submitted', successToastOptions);
          refresh();

          setTimeout(() => {
            closeModal();
          }, 1000);

        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).catch(() => {
        toast.error(ERRORMESSAGE, errorToastOptions);
      }).finally(() => {
        setLoading(false);
      })
    }
  }
  return loading ? <EllipsisLoader /> : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect data={formatbidSelectData(bidAnalysis)} id="bid_analysis_id" label="Bid Analysis" name='bid_analysis_id' required handleChange={handleSelected} errorMessage='Please select the bid analysis' error={purchaseOrder.bid_analysis_id.error} />
        </div>
        <div className="col-sm-6">
          {/* <Input handleChange={() => ""} label="Supplier" name='supplire' required /> */}
        </div>
      </div>

      <div className="table-responsive">
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>Supplier</th>
              <th>Description</th>
              <th>Remarks</th>
              <th>Unit</th>
              <th>Quantity</th>
              <th>Price/Unit</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {
              items?.map((item: any, index: number) => (
                <tr key={item}>
                  <td>
                    {item?.supplier_id}
                  </td>
                  <td>{item?.description}</td>
                  <td>
                    {item?.remarks}
                  </td>
                  <td>{item?.units}</td>
                  <td>{item?.quantity}</td>
                  <td> {item?.price}</td>
                  <td>{item?.quantity * item?.price}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div className="form-row">
        <div className="col-sm-12">
          <Textarea label='Notes' handleChange={handleChange} name='notes' error={purchaseOrder.notes.error} errorMessage='Please enter some notes about this action' required />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect data={formatStaffSelectData(approvingCommitte)} id="authorised_by" label="Authorised by" name='authorised_by' required errorMessage='Please the personal who authorised the action' error={purchaseOrder.authorised_by.error} handleChange={handleSelected} />
        </div>
        <div className="col-sm-6">
          <InputDate label='Date' name='date' required error={purchaseOrder.date.error} errorMessage='Please select date' value={purchaseOrder.date.data} handleChange={handleSelected} />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form >
  )
}

export default AddPurchaseOrder