import React from 'react'
import ListProducts from '../../components/stocks/product/ListProducts'
import StocksWrapper from '../../components/StocksWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const Products = () => {
  return (
    <StocksWrapper>
      <>
        <Breadcrumbs title='Products' parents={[{ link: ROUTES.STOCKS, title: 'Stock Management' }]} />
        <ListProducts />
      </>
    </StocksWrapper>
  )

}

export default Products