
import { createSlice } from '@reduxjs/toolkit';

export interface ITreatment {
  treatment: any;
  selectedTreatment: any;
}

const initialState: ITreatment = {
  treatment: {},
  selectedTreatment: {}
};

export const patientsReducer = createSlice({
  name: 'treatment',
  initialState,
  reducers: {
    setTreatments: (state, { payload }) => {
      state.treatment = payload;
    },
    setSelectedTreatment: (state, { payload }) => {
      state.selectedTreatment = payload;
    }
  },
});

const { reducer, actions } = patientsReducer;

export const {
  setTreatments, setSelectedTreatment
} = actions;

export default reducer;