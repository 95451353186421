import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllFunFacts, getFunFact } from '../api/mobile_app';
import { RootState } from '../redux/app/store';
import { setFunFacts, setSelectedFunFact } from '../redux/reducer/mobile_app';

const useFunFacts = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    funFacts, selectedFunFact
  } = useSelector(
    (state: RootState) => state.mobleApp,
  );

  useEffect(() => {

    if (Object.keys(funFacts).length > 0) {
      setLoading(false);
      return;
    }

    getAllFunFacts().then((response) => {
      if (response.status === 200) {
        dispatch(setFunFacts(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funFacts]);

  const refresh = () => {
    getAllFunFacts().then((response) => {
      if (response.status === 200) {
        dispatch(setFunFacts(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getSelectedFunFact = (id: number) => {
    setLoading(true);
    getFunFact(id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedFunFact(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return { funFacts, loading, refresh, getSelectedFunFact, selectedFunFact }
}

export default useFunFacts