import React, { useEffect } from 'react'
import { useLastUrlSegment } from '../../hooks/useQuery';
import GenerateReportsViewHeader from './GenerateReportsViewHeader';
import GenerateReportsViewTable from './GenerateReportsViewTable';

const GenerateReportsView = () => {
  const id = useLastUrlSegment();
  // const { fetchSingleProject, loading } = useProjects();

  useEffect(() => {
    // return fetchSingleProject(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <GenerateReportsViewHeader />
      <GenerateReportsViewTable />
    </>
  )
}

export default GenerateReportsView