import DashboardWrapper from '../../components/DashboardWrapper'
import ComingSoon from '../../components/global/ComingSoon'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Volunteers = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Donors' />
        {/* body */}
        <ComingSoon />
      </>
    </DashboardWrapper>

  )
}

export default Volunteers