import React, { useState } from 'react'
import { TableColumn } from 'react-data-table-component';
import usePatients from '../../../hooks/usePatients'
import { useLastUrlSegment } from '../../../hooks/useQuery';
import ConfirmDelete from '../../global/ConfirmDelete';
import ModalSwitcher from '../../global/ModalSwitcher';
import MyDataTable from '../../global/MyDataTable';
import { EllipsisLoader } from '../../global/MyLoaders';

const PatientTable = () => {
  const id = useLastUrlSegment();
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const { patients, loading, refresh } = usePatients(id);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    default:
      modalId = 'delete-patient';
      modalTitle = '';
      ModalToRender = ConfirmDelete;
      break;
  }

  const patientColumnData: TableColumn<any>[] = [
    {
      id: 1,
      name: "First Name",
      selector: (row) => row.first_name,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 2,
      name: "Last Name",
      selector: (row) => row.last_name,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 3,
      name: "DOB",
      selector: (row) => row.date_of_birth,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 4,
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true
    }, {
      id: 5,
      name: "Telephone",
      selector: (row) => row.telephone,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 6,
      name: "Address",
      selector: (row) => row.address,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      button: true,
      cell: (row) => (
        <div className='row'>
          {/* <button className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></button> */}
          {/* <button className='btn btn-sm btn-primary'><i className='fa fa-edit'></i></button> */}

          <button onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(row.id);
          }} className='btn btn-sm btn-danger mx-1'><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  return (
    <>
      <ModalSwitcher isOpen={isOpen} setIsOpen={setIsOpen} id={modalId} title={modalTitle} component={<ModalToRender closeModal={handleModalClick} path={`patients/${selectedId}/delete`} refresh={() => refresh()} />} />
      <div className="table-responsive mt-15">

        {loading ? (<EllipsisLoader />) : (

          <MyDataTable columns={patientColumnData} data={patients.data} title="" />
        )}
      </div>
    </>
  )
}

export default PatientTable