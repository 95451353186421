import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllTreatmentReminders, getTreatmentReminder } from '../api/doctor';
import { RootState } from '../redux/app/store';
import { setSelectedTreatment, setTreatments } from '../redux/reducer/doctor/treatmentReducer';

const useTreatments = (clinic_id?: number) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    treatment, selectedTreatment
  } = useSelector(
    (state: RootState) => state.treatments,
  );

  useEffect(() => {

    getAllTreatmentReminders(clinic_id).then((response) => {
      if (response.status === 200) {
        dispatch(setTreatments(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    getAllTreatmentReminders(clinic_id).then((response) => {
      if (response.status === 200) {
        dispatch(setTreatments(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getSelectedTreatment = (id: number) => {
    if (id) {
      setLoading(true);
      getTreatmentReminder(id).then((response) => {
        if (response.status === 200) {
          dispatch(setSelectedTreatment(response.data.data));
        }
      }).finally(() => {
        setLoading(false);
      })
    }
  }

  return { treatment, loading, refresh, getSelectedTreatment, selectedTreatment }
}

export default useTreatments