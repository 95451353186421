import React, { useEffect, useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import { useLastUrlSegment } from '../../../hooks/useQuery';
import useReports from '../../../hooks/useReports'
import { calculateAge } from '../../../utils/helpers';
import { EllipsisLoader } from '../../global/MyLoaders';

const FullClinicDetails = () => {
  const { getClinicReportDetails, clinicReportDetails, loading } = useReports();
  const id = useLastUrlSegment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getClinicReportDetails(id), [id]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return loading ? <EllipsisLoader /> : (
    <div className="row" ref={componentRef}>
      <div className="col-md-12 mb-30">
        <div className="card">
          <div className="card-body py-4">
            <div className="user-bg" >
              <div className="user-info">
                <div className="row">
                  <div className="col-md-10 align-self-center row mx-0">
                    <div className="user-dp mr-3">
                      <i className='fa fa-3x fa-hospital-o'></i>
                    </div>
                    <div className="user-detail">
                      <h2 className="name">{clinicReportDetails?.name}</h2>
                      <p className="designation mb-1"><span className='font-bold'>Email:</span> <span>{clinicReportDetails?.email}</span></p>
                      <p className="designation mb-1"><span className='font-bold'>Tel:</span> <span>{clinicReportDetails?.telephone}</span></p>
                      <p className="designation mb-1"><span className='font-bold'>Location:</span> <span>{clinicReportDetails?.location}</span></p>
                    </div>
                  </div>
                  <div className="col-md-2 text-right align-self-center">
                    <button onClick={handlePrint} type="button" className="btn d-print-none btn-sm btn-info"><i className="ti-printer pr-1"></i>Print</button>
                  </div>
                </div>

                <div className="row mt-3 mx-0">
                  <div className="table-responsive">
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>No. of patients</th>
                          <th>No. of appointments</th>
                          <th>No. of treatment reminders</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{clinicReportDetails?.patients_count}</td>
                          <td>{clinicReportDetails?.appointments_count}</td>
                          <td>0</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row mt-3 mx-0">
                  <div className="col-sm-12">
                    <h5>Patients</h5>

                    <div className="table-responsive">
                      <table className='table table-bordered'>
                        <thead>
                          <tr>
                            <th>Name(s)</th>
                            <th>Age</th>
                            <th>Gender</th>
                          </tr>
                        </thead>

                        <tbody>
                          {clinicReportDetails?.patients?.map(({ id, first_name, last_name, gender, date_of_birth }: any) => (
                            <tr key={id}>
                              <td>{`${first_name} ${last_name}`}</td>
                              <td>{`${calculateAge(date_of_birth, true)} `}</td>
                              <td>{`${gender}`}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>


                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullClinicDetails