import React from 'react'
import ListReviewingTeam from '../../components/settings/committees/reviewing/ListReviewingTeam'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const ReviewingCommittee = () => {
  return (
    <SettingsWrapper>
      <>
        <Breadcrumbs title='Reviewing committee' />
        <ListReviewingTeam />

      </>
    </SettingsWrapper>
  )
}

export default ReviewingCommittee