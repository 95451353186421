import React from 'react'
import ListAssets from '../../components/assetManagement/ListAssets'
import DashboardWrapper from '../../components/DashboardWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Assets = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Asset Management' />
        {/* body */}
        <ListAssets />
      </>
    </DashboardWrapper>
  )
}

export default Assets