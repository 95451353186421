import React, { useEffect, useState } from 'react'
import { TableColumn } from 'react-data-table-component';
import useAnnouncements from '../../hooks/useAnnouncements';
import { padString } from '../../utils/helpers';
import ConfirmDelete from '../global/ConfirmDelete';
import ModalSwitcher from '../global/ModalSwitcher';
import MyDataTable from '../global/MyDataTable';
import { EllipsisLoader } from '../global/MyLoaders';
import AddAnnouncement from './AddAnnouncement';
import EditAnnouncement from './EditAnnouncement';

const ListAnnouncements = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const { refresh, anouncements, getAnnouncement, loading } = useAnnouncements();
  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      reorder: true,
      sortable: true
    }, {
      id: 2,
      name: "Title",
      selector: (row) => row.title,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 3,
      name: "Content",
      selector: (row) => padString(row.information),
      reorder: true,
      sortable: true,
      grow: 3,
      wrap: true,
    },

    {
      button: true,
      cell: (row) => (
        <div className='row'>
          {/* <button className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></button> */}
          <button className='btn btn-sm btn-primary'><i className='fa fa-edit' onClick={(e) => {
            e.stopPropagation();
            handleEditClick(row.id);
          }}></i></button>

          <button className='btn btn-sm btn-danger mx-1' onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(row.id);
          }}><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAnnouncement(), []);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = 'edit-announcement';
      modalTitle = 'Edit Announcement';
      ModalToRender = EditAnnouncement

      break;
    case 3:
      modalId = 'delete-announcement';
      modalTitle = '';
      ModalToRender = ConfirmDelete
      break;
    default:
      modalId = 'add-new-announcement';
      modalTitle = 'Add New Announcement';
      ModalToRender = AddAnnouncement
      break;
  }

  return loading ? <EllipsisLoader /> : (
    <>
      <ModalSwitcher isOpen={isOpen} setIsOpen={setIsOpen} id={modalId} title={modalTitle} component={<ModalToRender id={selectedId} closeModal={handleModalClick} path={`notice/${selectedId}/delete`} refresh={refresh} />} />

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className='pl-3 row align-items-end'>
                  <button className='btn btn-primary ml-auto mr-3'
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                    }}><i className='fa fa-plus'></i>Add new</button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input type="search" className="form-control" placeholder="Search...." />
                </div>
              </div>
            </div>
            {/* table */}
            <MyDataTable columns={columns} data={anouncements} title="" />
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ListAnnouncements