import React, { useEffect } from 'react'
import { EllipsisLoader } from '../../components/global/MyLoaders'
import ViewCategories from '../../components/project/categories/ViewCategories'
import SettingsWrapper from '../../components/SettingsWrapper'
import useCategories from '../../hooks/useCategories'
import { useLastUrlSegment } from '../../hooks/useQuery'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const ViewCategory = () => {
  const id = useLastUrlSegment();
  const { getSelectedCategory, loading } = useCategories();
  useEffect(() => {
    return getSelectedCategory(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <SettingsWrapper >
      <>
        <Breadcrumbs title='View Category' parents={[{ link: ROUTES.PROJECTS, title: 'Projects' }, { link: ROUTES.CATEGORIES, title: 'Categories' }]} />
        {loading ? <EllipsisLoader /> : <ViewCategories />}


      </>
    </SettingsWrapper >
  )
}

export default ViewCategory