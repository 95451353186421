import React, { useState } from 'react'
import { useLastUrlSegment } from '../../../hooks/useQuery';
import useStaff from '../../../hooks/useStaff';
import Card from '../../../parts/Card'
import CardContentWrapper from '../../global/CardContentWrapper';
import CardHeaderWithBottomBorder from '../../global/CardHeaderWithBottomBorder'
import CardListItem from '../../global/CardListItem';
import { EllipsisLoader } from '../../global/MyLoaders';
import MyModal from '../../global/MyModal';
import EditPersonalDetails from './edit/EditPersonalDetails';

const EmployeeDetails = () => {
  const { selectedEmployee, loading } = useStaff();
  const id = useLastUrlSegment();
  const [isOpen, setIsOpen] = useState(false);

  return loading && Object.keys(selectedEmployee).length > 0 ? <EllipsisLoader /> : (
    <>
      <MyModal title={`Edit ${selectedEmployee?.user?.first_name} ${selectedEmployee?.user?.last_name} Details`} id='edit-employee' modalOpen={isOpen} setModalOpen={setIsOpen}>
        <EditPersonalDetails onCancel={() => setIsOpen(false)} id={Number(id)} />
      </MyModal>
      <Card>
        <>
          <CardHeaderWithBottomBorder title={`${selectedEmployee?.user?.first_name} ${selectedEmployee?.user?.last_name} Details`} isButton >
            <button type='button' className='btn btn-sm btn-info' onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
            }} >Edit</button>
          </CardHeaderWithBottomBorder>
          <CardContentWrapper>
            <div className='row'>
              <CardListItem title='Full Names' value={`${selectedEmployee.user?.first_name} ${selectedEmployee.user?.middle_name && selectedEmployee.user?.middle_name} ${selectedEmployee?.user?.last_name}`} />
              <CardListItem value={`${selectedEmployee?.job_title}`} title='Job Title' />
              <CardListItem value={`${selectedEmployee?.salary}`} title='Salary' />
              <CardListItem value={`${selectedEmployee?.duty_station}`} title='Duty Station' />
              <CardListItem value={`${selectedEmployee?.work_id}`} title='Work Id' />
              <CardListItem value={`${selectedEmployee?.national_id_no}`} title='National Id' />
            </div>
          </CardContentWrapper>
        </>
      </Card>
    </>
  )
}

export default EmployeeDetails