import React from 'react'
import DashboardWrapper from '../../../components/DashboardWrapper'
import ManagePayrollAddPage from '../../../components/hr/payrollManagement/ManagePayrollAddPage'
import Breadcrumbs from '../../../parts/Breadcrumbs'
import { ROUTES } from '../../../routes/routes'

const AddPayroll = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Add New Payroll' parents={[{ title: "Payroll Management", link: ROUTES.PAYROLLMANAGEMENT }]} />
        <ManagePayrollAddPage />
      </>
    </DashboardWrapper>

  )
}

export default AddPayroll