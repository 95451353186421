import React, { useState } from "react";
import { TableColumn } from "react-data-table-component";
import useBills from "../../../hooks/useBills";
import { apiConfig } from "../../../utils/axios";
import { formatBillStatus, formatCurrency } from "../../../utils/helpers";
import ModalSwitcher from "../../global/ModalSwitcher";
import MyDataTable from "../../global/MyDataTable";
import { EllipsisLoader } from "../../global/MyLoaders";
import AddBilling from "./AddBilling";
import AddPayment from "./AddPayment";

const ListBillings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [paidTo, setPaidTo] = useState(0);
  const { refresh, loading, bills } = useBills();

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      reorder: true,
      sortable: true,
      width: "40px",
    },
    {
      id: 2,
      name: "Name",
      selector: (row) => row.name,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 3,
      name: "Invoice No.",
      selector: (row) => row.invoice_no,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 4,
      name: "Payee",
      selector: (row) =>
        row.supplier_id
          ? row.suppliers.name
          : row?.user?.first_name + " " + row?.user?.last_name,
      reorder: true,
      sortable: true,
      grow: 3,
      wrap: true,
    },
    {
      id: 5,
      name: "Est. Amount",
      selector: (row) => formatCurrency(row.bill_amount),
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 6,
      name: "Status",
      selector: (row) => (
        <span className={`badge ${formatBillStatus(row.status_id).className}`}>
          {" "}
          <i className={`fa ${formatBillStatus(row.status_id).icon}`}></i>{" "}
          {formatBillStatus(row.status_id).title}
        </span>
      ),
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },

    {
      button: true,
      cell: (row) => (
        <div className="row">
          {/* <button className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></button> */}
          {row.status_id === 2 ? (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={`${apiConfig.baseURL}bills/${row.id}/print/`}
              className="btn btn-sm btn-success"
            >
              <i className="fa fa-print"></i> Receipt
            </a>
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setSelectedAmount(row.bill_amount);
                setPaidTo(row.supplier_id ? row.suppliers.id : row.user?.id);
                handleEditClick(row.id);
              }}
              className="btn btn-sm btn-info"
            >
              <i className="fa fa-money"></i> Pay
            </button>
          )}
        </div>
      ),
    },
  ];
  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  //joel_adomati@yahoo.com

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = "add-new-payment";
      modalTitle = "Payout the bill";
      ModalToRender = AddPayment;
      break;
    default:
      modalId = "add-new-bill";
      modalTitle = "Add new bill";
      ModalToRender = AddBilling;
      break;
  }

  return (
    <>
      <ModalSwitcher
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={modalId}
        title={modalTitle}
        component={
          <ModalToRender
            id={selectedId}
            amount={selectedAmount}
            closeModal={handleModalClick}
            refresh={refresh}
            paid_to={paidTo}
          />
        }
      />

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className="pl-3 row align-items-end">
                  <button
                    className="btn btn-primary ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal(0);
                      setIsOpen(true);
                    }}
                  >
                    <i className="fa fa-plus"></i>Add New Bill
                  </button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search...."
                  />
                </div>
              </div>
            </div>
            {/* table */}
            {loading ? (
              <EllipsisLoader />
            ) : (
              <MyDataTable columns={columns} data={bills} title="" />
            )}
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListBillings;
