import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import View from '../../components/mobile_app/stories/View'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const ViewStory = () => {
  return (
    <>

      <DashboardWrapper>
        <>
          <Breadcrumbs title='View story' parents={[{ link: ROUTES.STORIES, title: 'Inspirational stories' }]} />
          {/* <!-- main body --> */}

          <View />
        </>

      </DashboardWrapper>
    </>
  )
}

export default ViewStory