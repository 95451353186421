import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import useAccounts from "../../../hooks/useAccounts";
import { EllipsisLoader } from "../../global/MyLoaders";
import useSchedules from "../../../hooks/useSchedules";
import moment from "moment";
import { formatCurrency } from "../../../utils/helpers";

const NewIncomeStatement = () => {
  const { comparisonReport, getComparisonReport, loading } = useSchedules();

  useEffect(() => getComparisonReport(5), []);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return loading ? (
    <EllipsisLoader />
  ) : (
    <>
      <div className="col-xl-12 mb-30">
        <div className="row mx-0 mb-3 justify-content-end">
          <div className="col-xs-12 col-md-3">
            <div className="row justify-content-end">
              <label>Export</label>
              <button
                onClick={handlePrint}
                className="ml-3 btn btn-sm btn-secondary"
              >
                <i className="fa fa-print" />
              </button>
            </div>
          </div>
        </div>
        <table className="table" ref={componentRef}>
          <thead>
            <tr className="mb-2">
              <th className="bg-info text-center text-white py-2" colSpan={4}>
                Balance Sheet
              </th>
            </tr>
            <tr>
              <th></th>
              <th>Notes</th>
              <th>
                {moment(comparisonReport["date"]?.start_date).format("ll")} -{" "}
                {moment(comparisonReport["date"]?.end_date).format("ll")}
              </th>
              <th>
                {" "}
                {moment(comparisonReport["date"]?.prev_start_date).format(
                  "ll"
                )}{" "}
                - {moment(comparisonReport["date"]?.prev_end_date).format("ll")}
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(comparisonReport["data"])?.map(
              (item: any, id: any) => (
                <React.Fragment key={item.id}>
                  <tr>
                    <td className="font-medium">{item}</td>
                    <td className="font-medium">
                      {comparisonReport["data"][item].code}
                    </td>
                    <td className="font-medium">
                      {formatCurrency(
                        comparisonReport["data"][item].amount1 || 0
                      )}
                    </td>
                    <td className="font-medium">
                      {formatCurrency(
                        comparisonReport["data"][item].amount2 || 0
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              )
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default NewIncomeStatement;
