/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import { ReactElement, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import "./date.css";
import useReports from "../../hooks/useReports";
import { useLastUrlSegment } from "../../hooks/useQuery";
import useAccounts from "../../hooks/useAccounts";

interface inputProps {
  label?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  sm?: boolean;
  light?: boolean;
  required?: boolean;
  inputCustomClass?: string;
  withNoLabel?: boolean;
  withTime?: boolean;
  value?: string;
  noLimit?: boolean;
  disabled?: boolean;
  handleChange?: (date: unknown) => void;
  maxData?: any;
}

const InputDate = ({
  label,
  id,
  name,
  placeholder,
  error,
  errorMessage,
  sm,
  light,
  required,
  inputCustomClass,
  withNoLabel,
  withTime,
  handleChange,
  value,
  noLimit,
  disabled,
  maxData,
}: inputProps): ReactElement => {
  const input = classNames(
    `${inputCustomClass} form-control`,
    { "border-danger": error },
    { "input-sm": sm },
    { "input-white": light }
  );
  const [startDate, setStartDate] = useState(
    value ? new Date(value) : undefined
  );
  const handleDateChange = (date: Date | null) => {
    let newDate;
    if (withTime) {
      newDate = moment(date).format("YYYY-MM-DD h:mm");
    } else {
      newDate = moment(date).format("YYYY-MM-DD ");
    }
    setStartDate(date!);
    const d = { value: newDate as unknown, name };

    if (handleChange) {
      handleChange(d);
    }
  };

  return (
    <div className="row flex-column m-0 mb-4">
      {label && (
        <label htmlFor={id} className="font-semibold text-sm">
          {label}
          {!withNoLabel ? (
            required ? (
              <span className="text-danger text-sm font-medium mb-3">*</span>
            ) : (
              <span className="text-gray-500 ml-3 text-xs">(Optional)</span>
            )
          ) : null}
        </label>
      )}
      {withTime ? (
        <DatePicker
          selected={startDate}
          // closeOnScroll
          onChange={handleDateChange}
          // locale="en-GB"
          showTimeSelect
          timeFormat="p"
          timeIntervals={15}
          dateFormat="yyyy-MM-dd h:mm aa"
          name={name}
          placeholderText={placeholder ? placeholder : "Please select a date"}
          className={input}
          maxDate={noLimit ? null : new Date()}
          showMonthDropdown
          showYearDropdown
          // showTimeSelectOnly
          // timeCaption="Time"
          // dateFormat="h:mm aa"
          scrollableYearDropdown
          popperClassName="some-custom-class"
          autoComplete="off"
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
          readOnly={disabled}
        />
      ) : (
        <DatePicker
          selected={startDate}
          // closeOnScroll
          onChange={handleDateChange}
          showWeekNumbers
          name={name}
          placeholderText={placeholder}
          className={input}
          dateFormat="yyyy-MM-dd"
          maxDate={
            noLimit
              ? null
              : maxData
              ? new Date(
                  new Date().setFullYear(new Date().getFullYear() - maxData)
                )
              : new Date()
          }
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          popperClassName="some-custom-class"
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
          readOnly={disabled}
        />
      )}
      {error && <small className="text-danger">{errorMessage}</small>}
    </div>
  );
};

export const InputTime = ({
  label,
  id,
  name,
  placeholder,
  error,
  errorMessage,
  sm,
  light,
  required,
  inputCustomClass,
  withNoLabel,
  withTime,
  handleChange,
  value,
  noLimit,
}: inputProps): ReactElement => {
  const input = classNames(
    `${inputCustomClass} form-control`,
    { "border-danger": error },
    { "input-sm": sm },
    { "input-white": light }
  );
  const [startDate, setStartDate] = useState(
    value ? new Date(value) : new Date()
  );

  const handleDateChange = (date: Date | null) => {
    const newDate = moment(date).format("YYYY-MM-DD");
    setStartDate(date!);
    const d = { value: newDate as unknown, name };

    if (handleChange) {
      handleChange(d);
    }
  };

  return (
    <div className="row flex-column m-0 mb-4">
      {label && (
        <label htmlFor={id} className="font-semibold text-sm">
          {label}
          {!withNoLabel ? (
            required ? (
              <span className="text-danger text-sm font-medium mb-3">*</span>
            ) : (
              <span className="text-gray-500 ml-3 text-xs">(Optional)</span>
            )
          ) : null}
        </label>
      )}
      <DatePicker
        selected={startDate}
        // closeOnScroll
        onChange={handleDateChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        name={name}
        placeholderText={placeholder}
        className={input}
        popperClassName="some-custom-class"
        popperPlacement="top-end"
        popperModifiers={[
          {
            name: "offset",
            options: {
              offset: [5, 10],
            },
          },
          {
            name: "preventOverflow",
            options: {
              rootBoundary: "viewport",
              tether: false,
              altAxis: true,
            },
          },
        ]}
      />

      {error && <small className="text-danger">{errorMessage}</small>}
    </div>
  );
};

export const SingleInputDateRange = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const id = useLastUrlSegment();
  const { getAccountTransactionDetails } = useAccounts();
  return (
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update: any) => {
        const [start_date, end_date] = update;
        // console.log("start_date", start_date?.toLocaleDateString());
        // console.log("end_date", end_date);
        // console.log(update);
        getAccountTransactionDetails(
          id,
          start_date?.toLocaleDateString(),
          end_date?.toLocaleDateString()
        );
        setDateRange(update);
      }}
      isClearable={true}
      placeholderText="Select date range"
      className="form-control"
    />
  );
};

export const InputDateRange = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const input = classNames(`form-control`);

  const { setReportStartDate, setReportEndDate } = useReports();

  // useEffect(() => setReportStartDate(startDate), [startDate]);
  // useEffect(() => setReportEndDate(endDate), [endDate]);

  return (
    <>
      <div className="row flex-column">
        <label>Start Date</label>
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            setReportStartDate(date?.toLocaleDateString());

            setStartDate(date!);
          }}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          className={input}
          dateFormat="yyyy-MM-dd"
        />
      </div>

      <div className="row flex-column">
        <label>End Date</label>
        <DatePicker
          selected={endDate}
          onChange={(date) => {
            setReportEndDate(date?.toLocaleDateString());
            setEndDate(date!);
          }}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          className={input}
          dateFormat="yyyy-MM-dd"
        />
      </div>
    </>
  );
};

export default InputDate;
