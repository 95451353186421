import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doGet } from "../api";
import { RootState } from "../redux/app/store";
import { setInvoices, setPaymentTypes, setSelectedInvoice } from "../redux/reducer/accounting";

const useInvoices = () => {

  const { invoices, selectedInvoice, paymentTypes } = useSelector((state: RootState) => state.accounting);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (invoices.length > 0) {
      return;
    }
    setLoading(true);
    doGet('invoices').then((response) => {
      if (response.data.success) {
        dispatch(setInvoices(response.data.data));
      }
    }).catch(() => {
      //error
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectedInvoice = (id: number) => {
    setLoading(true);
    doGet('invoices/' + id).then((response) => {
      if (response.data.success) {
        dispatch(setSelectedInvoice(response.data.data));
      }
    }).catch(() => {
      //error
    }).finally(() => {
      setLoading(false);
    })
  }

  const getPaymentTypes = () => {
    if (paymentTypes.length > 0) {
      setLoading(false);
      return;
    }
    setLoading(true);
    doGet('payment_types').then((response) => {
      if (response.data.success) {
        dispatch(setPaymentTypes(response.data.data));
      }
    }).catch(() => {
      //error
    }).finally(() => {
      setLoading(false);
    });
  }

  const refresh = () => {
    doGet('invoices').then((response) => {
      if (response.data.success) {
        dispatch(setInvoices(response.data.data));
      }
    }).catch(() => {
      //error
    }).finally(() => {
      setLoading(false);
    })
  }

  return { loading, invoices, refresh, getSelectedInvoice, selectedInvoice, paymentTypes, getPaymentTypes }
}

export default useInvoices;