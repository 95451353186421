import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { stockGet } from '../api/stocks';
import { RootState } from '../redux/app/store';
import { setDailyActivities, setReportsStats } from '../redux/reducer/generalReducers';

const useReportStats = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    reportsStats, dailyActivity
  } = useSelector(
    (state: RootState) => state.general,
  );

  useEffect(() => {

    if (Object.keys(reportsStats).length > 0) {
      setLoading(false);
      return;
    }

    stockGet('stats/report').then((response) => {
      if (response.status === 200) {
        dispatch(setReportsStats(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDailyActivities = () => {
    setLoading(true);
    stockGet('stats/daily_work').then((response) => {
      if (response.status === 200) {
        dispatch(setDailyActivities(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const refresh = () => {
    setLoading(true);
    stockGet('stats/report').then((response) => {
      if (response.status === 200) {
        dispatch(setReportsStats(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return { loading, refresh, reportsStats, getDailyActivities, dailyActivity }
}

export default useReportStats