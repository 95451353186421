import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { createLeave } from '../../../api/hr';
import { ERRORMESSAGE, errorToastOptions, leaveDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useEmployeeLeaves from '../../../hooks/useEmployeeLeaves';
import useSessionStorage from '../../../hooks/useSessionStorage';
import useStaff from '../../../hooks/useStaff';
import { formatMultipleSelectData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputDate from '../../global/InputDate';
import InputSelect from '../../global/InputSelect';
import { EllipsisLoader } from '../../global/MyLoaders';
import Textarea from '../../global/Textarea';

interface ICreateLeaveProps {
  closeModal: () => void;
}

const CreateLeave = ({ closeModal }: ICreateLeaveProps) => {
  const [formLoading, setLoading] = useState(false);
  const [leave, setLeave] = useState(leaveDefaultData);
  const { isAdmin, userId } = useSessionStorage();
  const { formSelectEmployeeData } = useStaff();
  const { refresh, getLeaveTypes, leaveTypes, loading } = useEmployeeLeaves();
  const validator = new Validator();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getLeaveTypes(), []);

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setLeave({ ...leave, ...{ [name]: { ...leave[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setLeave({ ...leave, ...{ [name]: { ...leave[name], ...{ error: true, data: value } } } });
    }
  };


  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // // check if there is something in the text box
    // if (value !== '') {
    //   let newStatData = {};
    //   if (name === 'start_date') {
    //     newStatData = { ...leave, ...{ end_date: { ...leave.end_date, error: false, data: addDays(value, Number(leave.days_applied_for.data)) } } }
    //   }
    //   // update the state

    //   newStatData = { ...newStatData, ...{ [name]: { ...leave[name], ...{ error: false, data: value } } } };

    //   setLeave(newStatData);
    // } else {
    //   // indicate an error
    //   setLeave({ ...leave, ...{ [name]: { ...leave[name], ...{ error: true, data: value } } } });
    // }

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setLeave({ ...leave, ...{ [name]: { ...leave[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setLeave({ ...leave, ...{ [name]: { ...leave[name], ...{ error: true, data: value } } } });
    }
  }

  console.log(leave);


  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(leave, setLeave)) {
      setLoading(true);
      const data = {
        staff_id: isAdmin() ? leave.staff_id.data : userId(),
        start_date: leave.start_date.data,
        end_date: leave.end_date.data,
        leave_type: leave.leave_type.data,
        days_applied_for: leave.days_applied_for.data,
        resumption_date: leave.resumption_date.data,
        contact_address: leave.contact_address.data,
        comment: leave.comment.data,
      };
      createLeave(data).then((response) => {
        if (response.data.success) {
          toast.success("Application for a leave successfully recieved", successToastOptions);
          refresh();
          setTimeout(() => {
            closeModal();
          }, 1000);


        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }

      }).catch(() => {
        toast.error(ERRORMESSAGE, errorToastOptions);
      }).finally(() => {
        setLoading(false);
      })
    }
  }


  return loading ? <EllipsisLoader /> : (
    <form>
      <div className="form-row">
        {isAdmin() && (
          <div className="col-sm-6">
            <InputSelect data={formSelectEmployeeData} id='staff_id' label='Staff Member' name='staff_id' handleChange={handleSelectChange} value={leave.staff_id?.data} error={leave.staff_id?.error} errorMessage='please select the worker' required />
          </div>
        )}
        <div className="col-sm-6">
          <InputSelect id='leave_type' data={formatMultipleSelectData(leaveTypes)} label='Leave type' name='leave_type' handleChange={handleSelectChange} value={leave?.leave_type?.data} error={leave.leave_type?.error} errorMessage='please select the leave type' required />
        </div>
        <div className="col-sm-6">
          <Input label='How long(days)' type='number' name='days_applied_for' handleChange={handleChange} value={leave.days_applied_for?.data} error={leave.days_applied_for?.error} errorMessage='please enter number of days' required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-4">
          <InputDate handleChange={handleSelectChange} name='start_date' label='Start Date' value={leave.start_date?.data} error={leave.start_date?.error} errorMessage='please select the start date' noLimit required />
        </div>
        <div className="col-sm-4">
          <InputDate name='end_date' handleChange={handleSelectChange} label='End Date' value={leave.end_date?.data} error={leave.end_date?.error} errorMessage='please select the end date' noLimit required />
        </div>
        <div className="col-sm-4">
          <InputDate handleChange={handleSelectChange} name='resumption_date' label='Back on' value={leave.resumption_date?.data} error={leave.resumption_date?.error} errorMessage='please select the resumption date' noLimit required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input label='Emergency contact' name='contact_address' handleChange={handleChange} value={leave.contact_address?.data} error={leave.contact_address?.error} errorMessage='please enter the contact' required />
        </div>
        <div className="col-sm-12">
          <Textarea label='Comment' name='comment' handleChange={handleChange} value={leave.comment?.data} error={leave.comment?.error} errorMessage='please enter the remarks here' required />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  )
}

export default CreateLeave