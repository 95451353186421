import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  errorToastOptions,
  successToastOptions,
  transactionDefaultData,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useAccounts from "../../../hooks/useAccounts";
import useBudget from "../../../hooks/useBudget";
import {
  formatAcountSelectData,
  formatDepartmentSelectData,
  getFormData,
} from "../../../utils/helpers";
import Validator from "../../../utils/Validator";
import Input from "../../global/Input";
import InputDate from "../../global/InputDate";
import InputSelect from "../../global/InputSelect";
import { EllipsisLoader } from "../../global/MyLoaders";
import Textarea from "../../global/Textarea";

interface IAddTransactionProps {
  closeModal: () => void;
}

const AddCashFlow = ({ closeModal }: IAddTransactionProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [transaction, setTransaction] = useState(transactionDefaultData);
  const { refreshTransactions, loading, cashAccounts, getCashAccount } =
    useAccounts();
  const { budgets } = useBudget();

  const validator = new Validator();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getCashAccount(), []);

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;

    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setTransaction({
        ...transaction,
        ...{
          [name]: { ...transaction[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setTransaction({
        ...transaction,
        ...{
          [name]: { ...transaction[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setTransaction({
        ...transaction,
        ...{
          [name]: { ...transaction[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setTransaction({
        ...transaction,
        ...{
          [name]: { ...transaction[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(transaction, setTransaction)) {
      setFormLoading(true);

      const data = { ...getFormData(transaction), journal_type_id: 1 };

      doCreate("transactions/add-expense", data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success(
              "New transaction created successfully",
              successToastOptions
            );
            refreshTransactions();

            setTimeout(() => {
              closeModal();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .finally(() => {
          setFormLoading(false);
        });
    } else {
      console.log(transaction);
    }
  };

  return loading ? (
    <EllipsisLoader />
  ) : (
    <>
      <form>
        <div className="form-row">
          <div className="col-sm-6">
            <InputDate
              label="Transaction date"
              id="transaction_date"
              name="transaction_date"
              required
              handleChange={handleSelectChange}
              value={transaction.transaction_date.data}
              error={transaction.transaction_date.error}
              errorMessage="Please select the transaction date"
            />
          </div>
          <div className="col-sm-6">
            <Input
              handleChange={handleChange}
              name="ref_no"
              label="Ref No"
              required
              value={transaction.ref_no.data}
              error={transaction.ref_no.error}
              errorMessage="Please enter the ref no"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-sm-6">
            <Textarea
              handleChange={handleChange}
              name="description"
              label="Description"
              required
              value={transaction.description.data}
              error={transaction.description.error}
              errorMessage="Please enter the description"
            />
          </div>
          <div className="col-sm-6">
            <InputSelect
              data={formatDepartmentSelectData(budgets)}
              name="budget_id"
              label="Budget"
              handleChange={handleSelectChange}
              error={transaction?.budget_id.error}
              errorMessage="Please select the budget"
              value={transaction?.budget_id.data}
              id="budget_id"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-sm-6">
            <InputSelect
              data={formatAcountSelectData(cashAccounts)}
              label="Credit Account"
              id="account_chart_id"
              name="account_chart_id"
              required
              handleChange={handleSelectChange}
              value={transaction.account_chart_id.data}
              error={transaction.account_chart_id.error}
              errorMessage="Please select the account"
            />
          </div>

          <div className="col-sm-6">
            <InputSelect
              data={formatAcountSelectData(cashAccounts)}
              label="Debit Account"
              id="debit_account_chart_id"
              name="debit_account_chart_id"
              required
              handleChange={handleSelectChange}
              value={transaction.debit_account_chart_id.data}
              error={transaction.debit_account_chart_id.error}
              errorMessage="Please select the account"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-sm-6">
            <Input
              handleChange={handleChange}
              name="amount"
              label="Amount"
              type="number"
              value={transaction.amount.data}
              required
            />
          </div>
        </div>

        <div className="form-row m-0 pb-4 pt-3 justify-content-end">
          <button
            type="reset"
            className="btn btn-outline-danger mr-3"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={formLoading}
            className="btn  btn-info"
            onClick={handleSubmit}
          >
            {formLoading ? "Creating..." : "Create"}
          </button>
        </div>
      </form>
    </>
  );
};

export default AddCashFlow;
