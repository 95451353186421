import React from "react";
import toast from "react-hot-toast";
import { errorToastOptions } from "../../constants";
import Input from "./Input";
import { EllipsisLoader } from "./MyLoaders";

interface IDynamicFormProps {
  defaultData: Array<any>;
  setValues: (item: Array<any>) => void;
  values: Array<any>;
}

const DynamicCashRequisitionForm = ({
  defaultData,
  values,
  setValues,
}: IDynamicFormProps) => {
  const handleAddClick = () => {
    setValues([...values, ...defaultData]);
  };

  const handleMinusClick = (index: number) => {
    const newState = values;
    if (newState.length === 1) {
      toast.error("You can't remove this column", errorToastOptions);
      return;
    }
    newState.splice(index, 1);
    setValues([...newState]);
  };

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, id } = e.target as HTMLInputElement;
    const newState = values;
    newState[Number(id)][name] = value;
    setValues(newState);
  };

  const handleSelectChange = (e: any, id: number) => {
    const { name, value } = e;
    const newState = values;
    newState[Number(id)][name] = value;
    setValues(newState);
  };

  return (
    <div className="col-sm-12">
      <p>Please breakdown the items here</p>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan={3}>Name</th>
            <th>Amount</th>
            <th>action</th>
          </tr>
        </thead>
        <tbody>
          {values &&
            values.length > 0 &&
            values.map((item, index) => (
              <tr key={index}>
                <td colSpan={3}>
                  <Input
                    label=""
                    handleChange={handleChange}
                    id={index}
                    name={`name`}
                    noLabel
                  />
                </td>

                <td width={"30%"}>
                  <Input
                    label=""
                    handleChange={handleChange}
                    id={index}
                    name={`amount`}
                    type="number"
                    noLabel
                  />
                </td>
                <td>
                  <div className="form-row flex-nowrap">
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={() => handleMinusClick(index)} // remove a friend from the list
                      >
                        -
                      </button>
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-sm btn-info"
                        onClick={() => handleAddClick()} // insert an empty string at a position
                      >
                        +
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicCashRequisitionForm;
