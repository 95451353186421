
import { AuthenticatedAPIInstance } from "../../utils/axios";

export const clinicGet = async (url: string) => {
  const response = await AuthenticatedAPIInstance.get(url).then((response) => response)
    .catch((error) => error);
  return response;
}

export const clinicCreate = async (data: any, url: string) => {
  const response = await AuthenticatedAPIInstance.post(url, data).then((response) => response)
    .catch((error) => error);
  return response;
}

