

import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { doCreate } from '../../../../../api'
import { employeeEmploymentDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../../../constants'
import { formatErrorMessage } from '../../../../../helpers'
import useEmployees from '../../../../../hooks/useEmployees'
import useStaff from '../../../../../hooks/useStaff'
import Validator from '../../../../../utils/Validator'
import Input from '../../../../global/Input'
import InputDate from '../../../../global/InputDate'

interface IAddEducationProps {
  onCancel: () => void
  shouldSubmit?: boolean;
  id?: number;
}

const EmploymentDetailsModal = ({ onCancel, shouldSubmit, id }: IAddEducationProps) => {

  const [employmentDetails, setEmploymentDetails] = useState(employeeEmploymentDefaultData);
  const [loading, setLoading] = useState(false);
  const validator = new Validator();
  const { storeEmployeeEmploymentDetails } = useEmployees();
  const { getStaff } = useStaff();

  const handleSubmit = () => {
    if (validator.checkForEmptyFields(employmentDetails, setEmploymentDetails)) {
      const data = {
        employer: employmentDetails.employer.data,
        position: employmentDetails.position.data,
        start_date: employmentDetails.start_date.data,
        end_date: employmentDetails.end_date.data,
      }

      if (shouldSubmit) {
        setLoading(true);
        doCreate('/staff/' + id + '/add_employment', data).then((response) => {
          if (response.data.error) {
            toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
          } else {
            toast.success("Successfully added employment details", successToastOptions);
            getStaff(Number(id));
            setTimeout(() => {
              onCancel();
            }, 1000);
          }
        }).catch(() => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        }).finally(() => {
          setLoading(false);
        })

      } else {
        storeEmployeeEmploymentDetails(data);
        setEmploymentDetails(employeeEmploymentDefaultData);
        onCancel();

      }

    }

  }

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setEmploymentDetails({ ...employmentDetails, ...{ [name]: { ...employmentDetails[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setEmploymentDetails({ ...employmentDetails, ...{ [name]: { ...employmentDetails[name], ...{ error: true, data: value } } } });
    }
  };
  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setEmploymentDetails({ ...employmentDetails, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setEmploymentDetails({ ...employmentDetails, ...{ [name]: { error: true, data: value } } });
    }
  }

  return (
    <div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='employer' label='Work place' handleChange={handleChange} value={employmentDetails.employer.data} error={employmentDetails.employer.error} errorMessage="Please enter the workplace name" required />
        </div>
        <div className="col-sm-6">
          <Input name='position' label='Position' handleChange={handleChange} value={employmentDetails.position.data} error={employmentDetails.position.error} errorMessage="Please enter the award name" required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputDate name='start_date' label='Start Date' handleChange={handleSelectChange} required value={employmentDetails.start_date.data} error={employmentDetails.start_date.error} errorMessage="Please enter the start date" />
        </div>
        <div className="col-sm-6">
          <InputDate name='end_date' label='End Date' handleChange={handleSelectChange} required value={employmentDetails.end_date.data} error={employmentDetails.end_date.error} errorMessage="Please enter the end date" />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='button' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='button' className='btn  btn-info' onClick={handleSubmit}>
          {loading ? "Adding..." : "Add"}
        </button>
      </div>
    </div>
  )
}

export default EmploymentDetailsModal