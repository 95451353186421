/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */

import axios from "axios";
import { accessTokenKey } from "../api/helper";

const isBrowser = () => typeof window !== "undefined";

function getItem() {
  if (isBrowser()) return sessionStorage.getItem(accessTokenKey);
}
// export const apiConfig = { baseURL: "https://mos-api.platinalfred.com/" };
export const apiConfig = { baseURL: "https://tfc-api.efinanci.co.tz/" };
// https://api.mosmiles.org/
// const baseURLImage = "http://127.0.0.1:8000";
// const baseURLImage = "https://mos-api.platinalfred.com";
const baseURLImage = "https://tfc-api.efinanci.co.tz/";
// export const apiConfig = { baseURL: "http://127.0.0.1:8000/" };

const APIInstance = axios.create({
  ...apiConfig,
  validateStatus: (status) => {
    return status >= 200 && status < 500;
  },
});

const APIInstanceUpload = axios.create({
  ...apiConfig,
  validateStatus: (status) => {
    return status >= 200 && status < 500;
  },
});

APIInstanceUpload.interceptors.request.use((config) => {
  const authToken = getItem();
  config.headers = {
    "content-type": "multipart/form-data",
  };
  config.headers.Authorization = `Bearer ${authToken}`;
  return config;
});

const AuthenticatedAPIInstance = axios.create({ ...apiConfig });

AuthenticatedAPIInstance.interceptors.request.use((config) => {
  const authToken = getItem();
  config.headers.Authorization = `Bearer ${authToken}`;
  return config;
});

export default APIInstance;
export { AuthenticatedAPIInstance, baseURLImage, APIInstanceUpload };
