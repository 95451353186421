import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { doCreate } from '../../../../../api'
import { employeeRefereeDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../../../constants'
import { formatErrorMessage } from '../../../../../helpers'
import useEmployees from '../../../../../hooks/useEmployees'
import useStaff from '../../../../../hooks/useStaff'
import Validator from '../../../../../utils/Validator'
import Input from '../../../../global/Input'

interface IAddEducationProps {
  onCancel: () => void;
  shouldSubmit?: boolean;
  id?: number;
}

const AddReferees = ({ onCancel, id, shouldSubmit }: IAddEducationProps) => {

  const [referee, setReferee] = useState(employeeRefereeDefaultData);
  const [loading, setLoading] = useState(false);
  const validator = new Validator();
  const { storeEmployeeRefereeDetails } = useEmployees();
  const { getStaff } = useStaff();

  const handleSubmit = () => {
    if (validator.checkForEmptyFields(referee, setReferee)) {
      const data = {
        names: referee.names.data,
        email: referee.email.data,
        occupation: referee.occupation.data,
        telephone: referee.telephone.data,
      }

      if (shouldSubmit) {
        setLoading(true);
        doCreate('/staff/' + id + '/add_referee', data).then((response) => {
          if (response.data.error) {
            toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
          } else {
            toast.success("Successfully added referee details", successToastOptions);
            getStaff(Number(id));
            setTimeout(() => {
              onCancel();
            }, 1000);
          }
        }).catch(() => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        }).finally(() => {
          setLoading(false);
        })

      } else {
        storeEmployeeRefereeDetails(data);
        setReferee(employeeRefereeDefaultData);
        onCancel();
      }

    }

  }

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setReferee({ ...referee, ...{ [name]: { ...referee[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setReferee({ ...referee, ...{ [name]: { ...referee[name], ...{ error: true, data: value } } } });
    }
  };


  return (
    <div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='names' label='Full Names' handleChange={handleChange} value={referee.names.data} error={referee.names.error} errorMessage="Please enter full names" required />
        </div>
        <div className="col-sm-6">
          <Input name='email' label='Email Address' type='email' handleChange={handleChange} value={referee.email.data} error={referee.email.error} errorMessage="Please enter the email adress" required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input name='occupation' label='Occupation' handleChange={handleChange} value={referee.occupation.data} error={referee.occupation.error} errorMessage="Please enter referee's occupation" required />
        </div>
        <div className="col-sm-6">
          <Input name='telephone' label='Telephone' type='tel' handleChange={handleChange} value={referee.telephone.data} error={referee.telephone.error} errorMessage="Please enter the referee's telephone number" required />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='button' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='button' className='btn  btn-info' onClick={handleSubmit}>
          {loading ? "Adding..." : "Add"}
        </button>
      </div>
    </div>
  )
}

export default AddReferees