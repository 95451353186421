import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllDepartments, getDepartment } from '../api/settings';
import { RootState } from '../redux/app/store';
import { setDepartments, setFormattedDepartment, setSelectedDepartment } from '../redux/reducer/settings';
import { formatDepartmentSelectData } from '../utils/helpers';

const useDepartments = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    departments, selectedDepartment, formattedDepartments
  } = useSelector(
    (state: RootState) => state.settings,
  );

  useEffect(() => {

    if (Object.keys(departments).length > 0) {
      setLoading(false);
      return;
    }

    getAllDepartments().then((response) => {
      if (response.status === 200) {
        dispatch(setDepartments(response.data.data));
        dispatch(setFormattedDepartment(formatDepartmentSelectData(response.data.data)));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments]);

  const refresh = () => {
    getAllDepartments().then((response) => {
      if (response.status === 200) {
        dispatch(setDepartments(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getSelectedDepartment = (id: number) => {
    setLoading(true);
    getDepartment(id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedDepartment(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }


  return { departments, loading, refresh, getSelectedDepartment, selectedDepartment, formattedDepartments }
}

export default useDepartments