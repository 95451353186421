import React from 'react'

interface IEditPurchaseOrderProps {
  id: number;
  closeModal: () => void;
  refresh: () => void;
}

const EditPurchaseOrder = ({ closeModal, id, refresh }: IEditPurchaseOrderProps) => {
  return (
    <div>EditPurchaseOrder {id} </div>
  )
}

export default EditPurchaseOrder