import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { createHealthTip } from '../../../api/mobile_app';
import { errorToastOptions, healthTipDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useHealthTips from '../../../hooks/useHealthTips';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input'
import TextEditor from '../../global/TextEditor';

interface IAddHealthTipProp {
  closeModal: () => void
}

const AddHealthTip = ({ closeModal }: IAddHealthTipProp) => {
  const [loading, setLoading] = useState(false);
  const [healthTip, setHealthTip] = useState(healthTipDefaultData);
  const validator = new Validator();
  const { refresh } = useHealthTips();

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setHealthTip({ ...healthTip, ...{ [name]: { ...healthTip[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setHealthTip({ ...healthTip, ...{ [name]: { ...healthTip[name], ...{ error: true, data: value } } } });
    }
  };

  const handleEditorChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setHealthTip({ ...healthTip, ...{ [name]: { ...healthTip[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setHealthTip({ ...healthTip, ...{ [name]: { ...healthTip[name], ...{ error: true, data: value } } } });
    }
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(healthTip, setHealthTip)) {
      setLoading(true);
      const data = {
        title: healthTip.title.data,
        description: healthTip.description.data,
      }

      createHealthTip(data).then((response) => {
        if (response.data.success === true) {
          toast.success('Health tip successfully created', successToastOptions);
          refresh();

          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).finally(() => {
        setLoading(false);
      })
    }

  }

  return (
    <form>
      <Input label="Title" name="title" handleChange={handleChange} value={healthTip.title.data} error={healthTip.title.error} required />

      <div className="form-group">
        <label htmlFor="title">Description</label>
        <TextEditor handleChange={handleEditorChange} name="description" id="description" required />
        <small id="description" className="form-text text-muted">Please enter the description here</small>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={loading} className='btn  btn-info' onClick={handleSubmit}>
          {loading ? "Creating..." : "Create"}
        </button>
      </div>


    </form>
  )
}

export default AddHealthTip