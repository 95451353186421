import React from 'react'
import ListClinicDoctors from '../../components/clinic/doctor/ListClinicDoctors'
import ClinicWrapper from '../../components/ClinicWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const ClinicDoctors = () => {
  return (
    <ClinicWrapper>
      <>
        <Breadcrumbs title='Clinic Doctors' parents={[{ link: ROUTES.CLINICS, title: 'Clinics' }]} />
        <ListClinicDoctors />

      </>
    </ClinicWrapper>
  )
}

export default ClinicDoctors