import React from 'react'
import DashboardWrapper from '../../../components/DashboardWrapper'
import Breadcrumbs from '../../../parts/Breadcrumbs'
import { ROUTES } from '../../../routes/routes'

const FullEmployeeReport = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='John Doe' parents={[{ link: ROUTES.REPORTS, title: 'Reports' }, { link: ROUTES.REPORTS, title: 'Employee report' }]} />


      </>
    </DashboardWrapper>
  )
}

export default FullEmployeeReport;