import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllAssets, getAllAssetsTypeCategories, getAllAssetTypes, getAsset } from '../api/assetManagement';
import { RootState } from '../redux/app/store';
import { setAssets, setAssetTypeCategories, setAssetTypes, setAssetTypesFormData, setSelectedAsset } from '../redux/reducer/asset_management';
import { formatDepartmentSelectData } from '../utils/helpers';

const useAssetManagement = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    assets, assetTypeCategories, assetTypes, assetTypesFormData, selectedAsset
  } = useSelector(
    (state: RootState) => state.assets,
  );

  useEffect(() => {
    if (Object.keys(assets).length > 0) {
      setLoading(false);
      return;
    }

    getAllAssets().then((response) => {
      if (response.status === 200) {
        dispatch(setAssets(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    });

    getAllAssetsTypeCategories().then((response) => {
      if (response.status === 200) {
        dispatch(setAssetTypeCategories(formatDepartmentSelectData(response.data.data)));
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    getAllAssets().then((response) => {
      if (response.status === 200) {
        dispatch(setAssets(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getSelectedAsset = (id: number) => {
    setLoading(true);
    getAsset(id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedAsset(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getAssetTypes = () => {
    if (Object.keys(assets).length > 0) {
      setLoading(false);
      return;
    }

    getAllAssetTypes().then((response) => {
      if (response.status === 200) {
        dispatch(setAssetTypes(response.data.data));
        dispatch(setAssetTypesFormData(formatDepartmentSelectData(response.data.data)));
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  const refreshTypes = () => {
    getAllAssetTypes().then((response) => {
      if (response.status === 200) {
        dispatch(setAssetTypes(response.data.data));
        dispatch(setAssetTypesFormData(formatDepartmentSelectData(response.data.data)));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return { assets, loading, refresh, assetTypeCategories, refreshTypes, getAssetTypes, assetTypes, assetTypesFormData, getSelectedAsset, selectedAsset }
}

export default useAssetManagement