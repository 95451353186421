import React, { useEffect } from "react";
import useActivities from "../../../hooks/useActivities";
import StatCard from "../../dashboard/StatCard";
import { EllipsisLoader } from "../../global/MyLoaders";
import { formatCurrency } from "../../../utils/helpers";

interface IActivityDashboardProps {
  id: number;
}

const ActivityDashboard = ({ id }: IActivityDashboardProps) => {
  const { getActivityStats, activityStats, loading } = useActivities();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getActivityStats(id), [id]);

  return loading ? (
    <EllipsisLoader />
  ) : (
    <div className="row">
      <StatCard
        title="Parameters"
        icon="fa-thumb-tack"
        value={activityStats?.parameters}
        iconBg="bg-warning"
      />
      <StatCard
        title="Completed"
        icon="fa-check"
        value={activityStats?.completed}
        iconBg="bg-success"
      />
      <StatCard
        title="Team"
        icon="fa-users"
        value={activityStats?.team}
        iconBg="bg-secondary"
      />
      <StatCard
        title="Est. Budget(ug.shs)"
        icon="fa-money"
        value={formatCurrency(activityStats?.amount)}
        iconBg="bg-info"
      />
    </div>
  );
};

export default ActivityDashboard;
