import { formatParameterStatus } from '../../../../utils/helpers';

interface IActivityParametersProps {
  parameters: Array<any>;
  handleParameterClick: (item: any, num: number) => void;

}

const ActivityParameters = ({ parameters, handleParameterClick }: IActivityParametersProps) => {


  return (
    <div>
      <div className="table-responsive">
        <table className='table table-bordeblue'>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {parameters?.map((parameter, id) => (
              <tr key={parameter?.id}>
                <td>{id + 1}</td>
                <td>{parameter?.general?.name}</td>
                <td><span className={`badge ${formatParameterStatus(parameter?.status).className}`}>{formatParameterStatus(parameter?.status).title}</span></td>
                <td>
                  <div className='row'>
                    {parameter?.status !== 3 && (
                      <button className='btn btn-sm btn-primary' onClick={(e) => {
                        e.stopPropagation();
                        handleParameterClick(parameter, 5);
                      }}><i className='fa fa-check'></i> submit</button>

                    )}
                    {parameter?.status === 3 && (
                      <button className='btn btn-sm btn-info mx-1' onClick={(e) => {
                        e.stopPropagation();
                        handleParameterClick(parameter, 6);
                      }}><i className='fa fa-eye'></i> view</button>

                    )}


                  </div>
                </td>
              </tr>

            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ActivityParameters