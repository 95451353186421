import React, { useEffect, useState } from "react";
import { DiCss3, DiJavascript, DiNpm } from "react-icons/di";
import { FaList, FaRegFolder, FaRegFolderOpen } from "react-icons/fa";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import TreeView, { flattenTree } from "react-accessible-treeview";
import "./styles.css";
import useSubCategories from "../../../hooks/useSubCategories";
import { EllipsisLoader } from "../../global/MyLoaders";
import AddAccountChart from "./AddAccountChart";
import EditAccountChartNew from "./EditAccountChartNew";

function NewChartOfAccount() {
  const { getFolderView, loading, folderView } = useSubCategories();
  const data = flattenTree(folderView);

  const [selectedAccount, setSelectedAccount] = useState<any>(0);

  useEffect(() => getFolderView(), []);

  return loading ? (
    <EllipsisLoader />
  ) : (
    <div className="row">
      <div className="directory col-sm-6">
        <TreeView
          data={data}
          aria-label="directory tree"
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            getNodeProps,
            level,
          }) => (
            <div
              {...getNodeProps()}
              style={{
                paddingLeft: 20 * (level - 1),
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <div style={{ paddingLeft: "0.5rem" }}>
                {isBranch ? (
                  <FolderIcon isOpen={isExpanded} />
                ) : (
                  <FileIcon filename={element} />
                )}
              </div>

              <p
                onClick={() =>
                  Object.keys(getNodeProps()).length > 3
                    ? setSelectedAccount(element.id)
                    : setSelectedAccount(0)
                }
                className="text-sm"
                style={{
                  flex: 1,
                  fontSize:
                    isBranch || Number.isNaN(Number(element.id))
                      ? "0.9rem"
                      : "0.75rem",
                }}
              >
                {element.name}
              </p>
            </div>
          )}
        />
      </div>

      <div className="col-sm-6">
        {selectedAccount !== 0 &&
          (!Number.isNaN(Number(selectedAccount)) ? (
            <EditAccountChartNew id={selectedAccount} />
          ) : null)}
      </div>
    </div>
  );
}

const FolderIcon = ({ isOpen }: any) =>
  isOpen ? (
    <FaRegFolderOpen color="e8a87c" className="icon" />
  ) : (
    <FaRegFolder color="e8a87c" className="icon" />
  );

const FileIcon = ({ filename }: any) => {
  const isLedger = !Number.isNaN(Number(filename.id));

  return isLedger ? (
    <MdOutlineAccountBalanceWallet color="gray" className="icon" />
  ) : (
    <FaRegFolder color="e8a87c" className="icon" />
  );
};

export default NewChartOfAccount;
