import React from 'react'
import ListClinics from '../../components/clinic/ListClinics'
import DashboardWrapper from '../../components/DashboardWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Clinics = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='All Clinics' />
        {/* body */}
        <ListClinics />
      </>
    </DashboardWrapper>
  )
}

export default Clinics