import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { stockCreate } from '../../../api/stocks';
import { brandDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useBrands from '../../../hooks/useBrands';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import { EllipsisLoader } from '../../global/MyLoaders';
import Textarea from '../../global/Textarea';

interface IEditBrandProps {
  id: number;
  closeModal: () => void
}

const EditBrand = ({ id, closeModal }: IEditBrandProps) => {

  const [formLoading, setFormLoading] = useState(false);
  const [brand, setBrand] = useState(brandDefaultData);
  const { refresh, getBrand, selectedBrand, loading } = useBrands();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getBrand(id), [id]);
  const formNames = ['description', 'name'];

  useEffect(() => {
    let brandData = brandDefaultData;
    if (!loading) {
      for (let x of formNames) {
        brandData = { ...brandData, ...{ [x]: { ...brandData[x], ...{ error: false, data: selectedBrand[x] } } } };
      }
      setBrand(brandData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const validator = new Validator();

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setBrand({ ...brand, ...{ [name]: { ...brand[name], error: false, data: value } } });
    } else {
      // indicate an error
      setBrand({ ...brand, ...{ [name]: { ...brand[name], error: true, data: value } } });
    }
  };



  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(brand, setBrand)) {
      setFormLoading(true);

      const data = {
        name: brand.name.data,
        description: brand.description.data,
      }

      try {
        const newProject = await stockCreate(data, 'brand/' + id + '/update');
        if (newProject.data.success === true) {
          toast.success('Brand updated successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(newProject.data.data, newProject.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setFormLoading(false)
      }

    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>

      <div className="form-row">
        <div className="col-sm-6">
          <Input name='name' label='Brand Name' handleChange={handleChange} value={brand.name.data} error={brand.name.error} errorMessage="Please provide the brand name" required />
        </div>

      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Textarea handleChange={handleChange} label="Description" required name='description' value={brand.description.data} error={brand.description.error} errorMessage="Please provide the brand description" />
        </div>
      </div>
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>{formLoading ? "Updating..." : "Update"}</button>
      </div>
    </form>
  )

}

export default EditBrand