import React, { useEffect, useState } from 'react'
import MyDocViewer from '../../../global/MyDocViewer';
import parse from "html-react-parser";
import { htmlParseOptions } from '../../../../constants';

interface IViewCompletionProps {
  files: any;
  selectedParameter: any;
  closeModal: () => void;
}

const ViewCompletion = ({ files, selectedParameter, closeModal }: IViewCompletionProps) => {
  const [file, setFile] = useState([]);

  useEffect(() => {
    if (files.length > 0 && selectedParameter.general.parameter_type === 2) {
      const fi = files.filter((f: any) => f.activity_id === selectedParameter.activity_id && f.general_activity_parameter_id === selectedParameter.general_activity_parameter_id);

      setFile(fi.map((item: any) => ({ uri: item.file })));
    }
  }, [selectedParameter, files]);

  return (
    <div>
      {selectedParameter.general.parameter_type === 1 ?
        parse(selectedParameter?.parameter, htmlParseOptions)
        : (
          <MyDocViewer docs={file} />
        )
      }

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Close</button>
      </div>

    </div>
  )
}

export default ViewCompletion