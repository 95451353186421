import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { doGet } from '../api';
import { RootState } from '../redux/app/store';
import { setCounties, setCountries, setDistricts, setParishes, setSubCounties } from '../redux/reducer/geoData';

const useGeoData = () => {
  const dispatch = useDispatch();
  const {
    districts, villages, subCounties, counties, parishes, countries, _districts
  } = useSelector(
    (state: RootState) => state.geoData,
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getAll = async () => {

      if (districts.length === 0) {
        try {
          setLoading(true);
          // const allVillages = await doGet('location/villages');
          const allSubCounties = await doGet('location/sub_counties');
          const allCounties = await doGet('location/counties');
          const allParishes = await doGet('location/parishes');
          const allDistricts = await doGet('location/districts');
          const allCountries = await doGet('location/countries');


          // dispatch(setVillages(allVillages?.data?.data));
          dispatch(setSubCounties(allSubCounties?.data?.data));
          dispatch(setCounties(allCounties?.data?.data));
          dispatch(setParishes(allParishes?.data?.data));
          dispatch(setDistricts(allDistricts?.data?.data));
          dispatch(setCountries(allCountries?.data?.data));
        } catch (e) {
          // console.log('An Error occured: ', e);
        } finally {
          setLoading(false);
        }
      }
    };
    getAll();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { villages, subCounties, counties, parishes, countries, districts, loading, _districts }
}

export default useGeoData