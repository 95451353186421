import React from 'react'
import ClinicDetail from '../../components/clinic/ClinicDetail'
import ClinicWrapper from '../../components/ClinicWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const ClinicDetails = () => {
  return (
    <ClinicWrapper>
      <>
        <Breadcrumbs title='Clinic details' parents={[{ link: ROUTES.CLINICS, title: 'Clinics' }]} />
        <ClinicDetail />
      </>
    </ClinicWrapper>
  )
}

export default ClinicDetails