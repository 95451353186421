import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllOtherPrograms, getAllPrograms, getProgram } from '../api/projects';
import { RootState } from '../redux/app/store';
import { setOtherPrograms, setPrograms, setSelectedProgram } from '../redux/reducer/projects';

const usePrograms = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    programs, otherPrograms, selectedProgram
  } = useSelector(
    (state: RootState) => state.projects,
  );

  useEffect(() => {

    if (Object.keys(programs).length > 0) {
      setLoading(false);
      return;
    }

    getAllPrograms().then((response) => {
      if (response.status === 200) {
        dispatch(setPrograms(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programs]);

  const refresh = () => {
    getAllPrograms().then((response) => {
      if (response.status === 200) {
        dispatch(setPrograms(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }


  const getSelectedProgram = (id: number) => {
    setLoading(true);
    getProgram(id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedProgram(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getOtherPrograms = (id: number) => {
    setLoading(true);
    getAllOtherPrograms(id).then((response) => {
      if (response.status === 200) {
        dispatch(setOtherPrograms(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return {
    programs, loading, refresh, getOtherPrograms, otherPrograms, getSelectedProgram, selectedProgram
  }
}

export default usePrograms