import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import useAssetManagement from "../../hooks/useAssetManagement";
import MyDataTable from "../global/MyDataTable";
import { EllipsisLoader } from "../global/MyLoaders";
import MyModal from "../global/MyModal"
import CreateAssetType from "./CreateAssetType";


const ListAssetTypes = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { assetTypes, loading, getAssetTypes } = useAssetManagement();

  useEffect(() => {
    return getAssetTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "Id",
      selector: (row) => row.id,
      reorder: true,
      sortable: true
    }, {
      id: 2,
      name: "Name",
      selector: (row) => row.name,
      reorder: true,
      sortable: true
    }, {
      id: 3,
      name: "Category",
      selector: (row) => row.asset_category.name,
      reorder: true,
      sortable: true
    },
    {
      button: true,
      cell: (row) => (
        <div className='row'>
          <button className='btn btn-sm btn-primary' ><i className='fa fa-edit'></i></button>

          <button className='btn btn-sm btn-danger mx-1' ><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];

  return (
    <>
      <MyModal modalOpen={isOpen} setModalOpen={setIsOpen} id="add-new-asset" title="Add New Asset Type">
        <CreateAssetType onCancel={handleModalClick} />
      </MyModal>

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className='pl-3 row align-items-end'>
                  <button className='btn btn-primary ml-auto mr-3' onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(true);
                  }}>Add new  asset category</button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input type="search" className="form-control" placeholder="Search...." />
                </div>
              </div>
            </div>
            {/* table */}
            {loading ? <EllipsisLoader /> : (
              <MyDataTable columns={columns} data={assetTypes} title="" />
            )}
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ListAssetTypes