import React from 'react'
import ListApprovingTeam from '../../components/settings/committees/approving/ListApprovingTeam'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const ApprovingCommittee = () => {
  return (
    <SettingsWrapper>
      <>
        <Breadcrumbs title='Approving committee' />
        <ListApprovingTeam />
      </>
    </SettingsWrapper>
  )
}

export default ApprovingCommittee