import { useState } from 'react'
import LeaveTable from './LeaveTable';
import MyModal from '../../global/MyModal';
import CreateLeave from './CreateLeave';

const ListLeaves = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <MyModal modalOpen={open} setModalOpen={setOpen} id='add-leave' title='Apply for a leave'>
        <CreateLeave closeModal={() => setOpen(false)} />
      </MyModal>

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className='pl-3 row align-items-end'>
                  <button className='btn btn-primary ml-auto mr-3' onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                  }}> <i className='fa fa-plus'></i> Add new</button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input type="search" className="form-control" placeholder="Search...." />
                </div>
              </div>
            </div>
            {/* table */}
            <LeaveTable />
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ListLeaves