import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { doGet } from '../api';
import { getAllAppointments, getAllAppointmentTypes, getAllPatients, getAppointment } from '../api/doctor';
import { RootState } from '../redux/app/store';
import { setAppointments, setAppointmentTypes, setArvs, setPatientsSelectData, setSelectedAppointment } from '../redux/reducer/doctor/appointmentReducer';
import { formatMultipleSelectData, formatPatientData } from '../utils/helpers';

const useAppointments = (clinic_id?: number) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    appointments, appointmentTypes, patients, selectedAppointment, arvs
  } = useSelector(
    (state: RootState) => state.appointments,
  );

  useEffect(() => {

    getAllAppointments(clinic_id).then((response) => {
      if (response.status === 200) {
        dispatch(setAppointments(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAppointmentTypes = () => {
    if (appointmentTypes.length > 0) return;
    getAllAppointmentTypes().then((response) => {
      if (response.status === 200) {
        dispatch(setAppointmentTypes(formatMultipleSelectData(response.data.data)));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getSelectedAppointment = (id: number) => {
    setLoading(true);
    getAppointment(id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedAppointment(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getPatientsSelectData = () => {
    if (patients.length > 0) return;
    getAllPatients(clinic_id).then((response) => {
      if (response.status === 200) {
        dispatch(setPatientsSelectData(formatPatientData(response.data.data.data)));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const refresh = () => {
    getAllAppointments(clinic_id).then((response) => {
      if (response.status === 200) {
        dispatch(setAppointments(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getArvs = () => {
    setLoading(true);
    doGet('arvs').then((response) => {
      if (response.status === 200) {
        dispatch(setArvs(response.data.data));
      }
    }).catch((e) => {
      dispatch(setArvs([]));
    }).finally(() => {
      setLoading(false);
    })
  }

  return { appointments, loading, refresh, getAppointmentTypes, appointmentTypes, getPatientsSelectData, patients, getSelectedAppointment, selectedAppointment, getArvs, arvs }
}

export default useAppointments