import React, { useEffect } from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import { EllipsisLoader } from '../../components/global/MyLoaders'
import ViewLeaveDetails from '../../components/hr/leave_management/ViewLeaveDetails'
import useEmployeeLeaves from '../../hooks/useEmployeeLeaves'
import { useLastUrlSegment } from '../../hooks/useQuery'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const LeaveManagementView = () => {
  const { getSelectedLeave, loading } = useEmployeeLeaves();
  const id = useLastUrlSegment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedLeave(id), [id]);

  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='View Leave Details' parents={[{ link: ROUTES.LEAVEMANAGEMENT, title: "Staff Leaves" }]} />
        {loading ? <EllipsisLoader /> : <ViewLeaveDetails />}

      </>
    </DashboardWrapper>
  )
}

export default LeaveManagementView