import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useActivities from '../../../hooks/useActivities';
import { EllipsisLoader } from '../../global/MyLoaders';
interface IAddActivityParametersProps {
  closeModal: () => void;
  refresh: () => void;
  id: number;
}
const AddActivityParameters = ({ closeModal, refresh, id }: IAddActivityParametersProps) => {
  const [formLoading, setLoading] = useState(false);
  const [selectedParameters, setSelectedParameters] = useState<Array<any>>([]);
  const { loading, getActivityParameters, activityParameters } = useActivities();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getActivityParameters(), []);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, checked } = e.target as HTMLInputElement;
    if (checked) {
      let newArray = [...selectedParameters, value];
      setSelectedParameters(newArray);
    } else {
      let newArray = selectedParameters.filter((item: any) => Number(item) !== Number(value));
      setSelectedParameters(newArray);
    }
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (selectedParameters.length === 0) {
      toast.error('Please select some parameters', errorToastOptions);
      return;
    }

    const data = {
      parameters: selectedParameters,
      activity_id: id
    }

    try {
      setLoading(true);
      const response = await doCreate('projects/activity-parameters/create', data);
      if (response.data.success === true) {
        toast.success('New permission(s) attached successfully', successToastOptions);
        refresh(); // refresh the hook store
        setTimeout(() => {
          closeModal();
        }, 1000);
      } else {
        toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
      }
    } catch (err) {
      toast.error(ERRORMESSAGE, errorToastOptions);
    } finally {
      setLoading(false)
    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>
      {activityParameters.map((item: any) => <div key={item.id} className="remember-checkbox p-10">
        <input type="checkbox" className="form-control" name="assign" id={item.name} value={item.id} onChange={handleChange} />
        <label htmlFor={item.name} className="m-0"> {item.name}</label>
      </div>)}
      <div className="form-row m-0 pb-4 pt-3 justify-content-start">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>{formLoading ? "Assigning..." : "Assign"}</button>
      </div>
    </form>
  )
}

export default AddActivityParameters