import React, { useEffect, useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import useReportStats from '../../hooks/useReportStats';
import useSessionStorage from '../../hooks/useSessionStorage';
import { activityAction } from '../../utils/helpers';
import { EllipsisLoader } from '../global/MyLoaders';

const DailyReport = () => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { getDailyActivities, dailyActivity, loading } = useReportStats();
  const { isAdmin } = useSessionStorage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getDailyActivities(), []);
  return loading ? <EllipsisLoader /> : (
    <>
      <div className='row mx-0 mb-3 justify-content-end'>
        <div className='col-xs-12 col-md-3'>
          <div className="row justify-content-end">
            <label>Export</label>
            <button onClick={handlePrint} className="ml-3 btn btn-sm btn-secondary">
              <i className='fa fa-print' />
            </button>
          </div>
        </div>
      </div>


      <div className='table-responsive' ref={componentRef}>
        <table className='table table-bordeblue'>
          <thead>
            <tr>
              <th>#</th>
              {isAdmin() && (<th>Name(s)</th>)}
              <th>Action</th>
              <th>Activity</th>
            </tr>
          </thead>
          <tbody>
            {dailyActivity.length > 0 ? dailyActivity?.map(({ id, action, action_id, activity, user }: any, idx: any) => isAdmin ? (
              <tr key={id} className={activityAction(action_id)}>
                <td>{idx}</td>
                {isAdmin() && <td>{`${user?.first_name} ${user?.last_name}`}</td>}
                <td>{action}</td>
                <td>{activity}</td>
              </tr>
            ) : (
              <tr key={id} className={activityAction(action_id)}>
                <td>{action}</td>
                <td>{activity}</td>
              </tr>
            )) : (
              <tr>
                <td colSpan={3} className='text-sm text-muted py-2 text-center'>No Activity today</td>

              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default DailyReport