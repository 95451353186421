import React from "react";
import DashboardWrapper from "../../components/DashboardWrapper";
import Breadcrumbs from "../../parts/Breadcrumbs";
import ListSubCategories from "../../components/accounting/sub_categories/ListSubCategories";

const SubCategories = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title="Sub Categories" />
        {/* body */}
        <ListSubCategories />
      </>
    </DashboardWrapper>
  );
};

export default SubCategories;
