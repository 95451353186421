import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { stockCreate } from '../../../api/stocks';
import { ERRORMESSAGE, errorToastOptions, stockProductDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useBrands from '../../../hooks/useBrands';
import useStockCategories from '../../../hooks/useStockCategories';
import useStockProduct from '../../../hooks/useStockProducts';
import useSuppliers from '../../../hooks/useSuppliers';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputSelect from '../../global/InputSelect';
import { EllipsisLoader } from '../../global/MyLoaders';

interface IEditProductProps {
  id: number;
  closeModal: () => void;
}

const EditProduct = ({ id, closeModal }: IEditProductProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [product, setProduct] = useState(stockProductDefaultData);

  const { formattedBrand } = useBrands();
  const { formattedProductCategories } = useStockCategories();
  const { refresh, loading, getProduct, selectedProduct } = useStockProduct();
  const { formattedSupplier } = useSuppliers();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getProduct(id), []);

  const formNames = ['name', 'reference', 'price', 'vat', 'project_category_id', 'brand_id', 'supplier_id'];

  useEffect(() => {
    let productData = selectedProduct;
    if (!loading) {
      for (let x of formNames) {
        productData = { ...productData, ...{ [x]: { ...productData[x], ...{ error: false, data: selectedProduct[x] } } } };
      }
      setProduct(productData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const validator = new Validator();

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setProduct({ ...product, ...{ [name]: { ...product[name], error: false, data: value } } });
    } else {
      // indicate an error
      setProduct({ ...product, ...{ [name]: { ...product[name], error: true, data: value } } });
    }
  }

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setProduct({ ...product, ...{ [name]: { ...product[name], error: false, data: value } } });
    } else {
      // indicate an error
      setProduct({ ...product, ...{ [name]: { ...product[name], error: true, data: value } } });
    }
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(product, setProduct)) {
      setFormLoading(true);

      const data = {
        name: product.name.data,
        reference: product.reference.data,
        price: product.price.data,
        vat: product.vat.data,
        project_category_id: product.project_category_id.data,
        brand_id: product.brand_id.data,
        supplier_id: product.supplier_id.data
      }

      try {
        const newProject = await stockCreate(data, 'products/' + id + '/update');
        if (newProject.data.success === true) {
          toast.success('Product updated successfully', successToastOptions);
          refresh(); // refresh the hook store
          setProduct(stockProductDefaultData);
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(newProject.data.data, newProject.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setFormLoading(false)
      }

    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='name' label='Name' handleChange={handleChange} value={product.name.data} error={product.name.error} errorMessage="Please enter the product name" required />
        </div>
        <div className="col-sm-6">
          <Input name='reference' label='Reference' handleChange={handleChange} value={product.reference.data} error={product.reference.error} errorMessage="Please enter the product reference" required />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='price' label='Price' type='number' handleChange={handleChange} value={product.price.data} error={product.price.error} errorMessage="Please enter the product price" required />
        </div>

        <div className="col-sm-6">
          <Input name='vat' label='VAT' type='number' handleChange={handleChange} value={product.vat.data} error={product.vat.error} errorMessage="Please enter the vat value" required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect data={formattedSupplier} id="supplier_id" name="supplier_id" label="Supplier" handleChange={() => ""} required value={product.supplier_id.data} error={product.supplier_id.error} errorMessage="Please select the product supplier" />
        </div>
        <div className="col-sm-6"></div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect data={formattedProductCategories} id='project_category_id' label='Category' name='project_category_id' required handleChange={handleSelectChange} value={[{ value: product.project_category_id.data, label: product.project_category_id.data }]} error={product.project_category_id.error} errorMessage="Please select the category" />
        </div>
        <div className="col-sm-6">
          <InputSelect data={formattedBrand} id='brand_id' label='Brand' name='brand_id' required handleChange={handleSelectChange} value={[{ value: product.brand_id.data, label: product.brand_id.data }]} error={product.brand_id.error} errorMessage="Please select the brand" />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>{formLoading ? "Creating..." : "Create"}</button>
      </div>
    </form>
  )
}

export default EditProduct