
import { createSlice } from '@reduxjs/toolkit';

interface IAssets {
  assets: any;
  assetTypeCategories: any;
  assetTypes: any;
  assetTypesFormData: any;
  selectedAsset: any;
}

const initialState: IAssets = {
  assets: [],
  assetTypeCategories: [],
  assetTypes: [],
  assetTypesFormData: [],
  selectedAsset: {},
}

export const assetReducer = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setAssets: (state, { payload }) => {
      state.assets = payload
    },
    setAssetTypeCategories: (state, { payload }) => {
      state.assetTypeCategories = payload
    },
    setAssetTypes: (state, { payload }) => {
      state.assetTypes = payload
    },
    setAssetTypesFormData: (state, { payload }) => {
      state.assetTypesFormData = payload
    },
    setSelectedAsset: (state, { payload }) => {
      state.selectedAsset = payload
    },
  }
});


const { reducer, actions } = assetReducer;
export const { setAssets, setAssetTypeCategories, setAssetTypes, setAssetTypesFormData, setSelectedAsset } = actions;
export default reducer;
