import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import mobileAppReducer from "../reducer/mobile_app/index";
import userReducer from "../reducer/userReducer";
import appointmentReducer from '../reducer/doctor/appointmentReducer';
import patientsReducer from '../reducer/doctor/patientsReducer';
import treatmentsReducer from '../reducer/doctor/treatmentReducer';
import projectsReducer from '../reducer/projects/index';
import hrReducer from '../reducer/hr/index';
import generalReducer from '../reducer/generalReducers';
import settingsReducer from '../reducer/settings';
import assetReducer from '../reducer/asset_management';
import stockReducer from '../reducer/stocks';
import clinicReducer from '../reducer/clinic';
import accountingReducer from '../reducer/accounting';
import geoDataReducer from '../reducer/geoData';

export const store = configureStore({
  reducer: {
    mobleApp: mobileAppReducer,
    user: userReducer,
    appointments: appointmentReducer,
    patients: patientsReducer,
    treatments: treatmentsReducer,
    projects: projectsReducer,
    hr: hrReducer,
    general: generalReducer,
    settings: settingsReducer,
    assets: assetReducer,
    stocks: stockReducer,
    clinics: clinicReducer,
    accounting: accountingReducer,
    geoData: geoDataReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
