import React from 'react'

interface IViewInvoiceProps {
  id: number;
  refresh: () => void;
}

const ViewInvoice = ({ id, refresh }: IViewInvoiceProps) => {
  return (
    <div>ViewInvoice</div>
  )
}

export default ViewInvoice