import React, { useState } from 'react'
import AppModal from '../../components/AppModal';
import DashboardWrapper from '../../components/DashboardWrapper';
import AddTreatment from '../../components/doctor/treatments/AddTreatment';
import ListTreatments from '../../components/doctor/treatments/ListTreatments';
import Breadcrumbs from '../../parts/Breadcrumbs'

const Treatments = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }
  return (
    <>
      <AppModal isOpen={isOpen} closeModal={handleModalClick} title="Add new treatment reminder">
        <AddTreatment closeModal={handleModalClick} />
      </AppModal>
      <DashboardWrapper>
        <>
          <Breadcrumbs title='Treatments' />
          {/* body */}
          <ListTreatments />
        </>
      </DashboardWrapper>
    </>
  )
}

export default Treatments