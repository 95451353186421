import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { bidDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useCommittees from '../../../hooks/useCommittees';
import useRequisition from '../../../hooks/useRequisition';
import useSuppliers from '../../../hooks/useSuppliers';
import { formatDepartmentSelectData, formatQoutationSelectData, getFormData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputDate from '../../global/InputDate';
import InputSelect from '../../global/InputSelect';
import { EllipsisLoader } from '../../global/MyLoaders';

interface IAddBidAnalysisProps {
  closeModal: () => void;
  refresh: () => void;
}

const AddBidAnalysis = ({ closeModal, refresh }: IAddBidAnalysisProps) => {
  const [formLoading, setLoading] = useState(false);
  const [bidAnalysis, setBidAnalysis] = useState(bidDefaultData);
  const [items, setItems] = useState<any>([]);
  const defaultData = { description: "", quantity: "", units: "", remarks: "", supplier_id: "", price: "" };
  const [values, setValues] = useState<Array<any>>([]);
  const { loading, requisitions } = useRequisition(3);
  const { suppliers } = useSuppliers();
  const { approvingCommitte, getApprovingCommitteeMembers } = useCommittees();

  const validator = new Validator();

  useEffect(() => {
    setValues(items.map((item: any) => defaultData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getApprovingCommitteeMembers(), []);

  const handleSelectedQuotation = (e: any) => {
    const { value, name } = e;
    if (value !== '') {
      if (name === 'requisition_id') {
        setItems(requisitions.filter((item: any) => item?.id === value)[0].products);
      }
      // update the state
      setBidAnalysis({ ...bidAnalysis, ...{ [name]: { ...bidAnalysis[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setBidAnalysis({ ...bidAnalysis, ...{ [name]: { ...bidAnalysis[name], ...{ error: true, data: value } } } });
    }

  }

  const handleValuesChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    const { name, value } = e.target as HTMLInputElement;
    let tempState = [...values];
    let tempElement = { ...tempState[id] };
    tempElement[name] = value;
    tempState[id] = tempElement;
    setValues(tempState);
  }

  const handleSelectChange = (e: any, id: number, item: any) => {
    const { name, value } = e;
    let tempState = [...values];
    let tempElement = { ...tempState[id] };
    tempElement[name] = value;
    tempElement.description = item?.description;
    tempElement.quantity = item?.quantity;
    tempElement.units = item?.units;
    tempState[id] = tempElement;
    setValues(tempState);

  }


  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setBidAnalysis({ ...bidAnalysis, ...{ [name]: { ...bidAnalysis[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setBidAnalysis({ ...bidAnalysis, ...{ [name]: { ...bidAnalysis[name], ...{ error: true, data: value } } } });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(bidAnalysis, setBidAnalysis)) {
      setLoading(true);
      const data = { ...getFormData(bidAnalysis), ...{ items: values } };
      // console.log(data); return;
      doCreate('bid-analysis/create', data).then((response) => {
        if (response.data.success === true) {
          toast.success('New Bid Analysis has been successfully submitted', successToastOptions);
          refresh();

          setTimeout(() => {
            closeModal();
          }, 1000);

        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).catch(() => {
        toast.error(ERRORMESSAGE, errorToastOptions);
      }).finally(() => {
        setLoading(false);
      })
    }
  }
  return loading ? <EllipsisLoader /> : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect data={formatQoutationSelectData(requisitions)} id="requisition_id" label="Quotation" name='requisition_id' required handleChange={handleSelectedQuotation} error={bidAnalysis.requisition_id.error} errorMessage='Please select the quotation' />
        </div>
        <div className="col-sm-6">
          <InputDate label='Date' name='date' value={bidAnalysis.date.data} error={bidAnalysis.date.error} errorMessage='Please select the bid date' required handleChange={handleSelectedQuotation} />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input type='file' handleChange={handleChange} label="Attachments" name='attachment' />
        </div>

      </div>

      <div className="table-responsive">
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>Supplier</th>
              <th>Description</th>
              <th>Remarks</th>
              <th>Unit</th>
              <th>Quantity</th>
              <th>Price/Unit</th>
            </tr>
          </thead>
          <tbody>
            {
              items?.map((item: any, index: number) => (
                <tr key={index}>
                  <td>
                    <InputSelect id={index} data={formatDepartmentSelectData(suppliers)} label="" name='supplier_id' required handleChange={(e) => handleSelectChange(e, index, item)} withNoLabel errorMessage='Please select the supplier' />
                  </td>
                  <td>{item?.description}</td>
                  <td>
                    <Input label='' name='remarks' handleChange={(e) => handleValuesChange(e, index)} noLabel errorMessage='Please leave a simple remark' />
                  </td>
                  <td>{item?.units}</td>
                  <td>{item?.quantity}</td>
                  <td><Input id={index} label='' name='price' handleChange={(e) => handleValuesChange(e, index)} noLabel errorMessage='Please provide the price per unit' /></td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <h6>Approving committee</h6>
          <ol>
            {approvingCommitte?.map((item: any) => (
              <li key={item?.id}>{`${item?.user?.first_name} ${item?.user?.last_name}`}</li>
            ))}
          </ol>
        </div>
        <div className="col-sm-6">

        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form >
  )
}

export default AddBidAnalysis