import React, { ReactElement } from 'react'

interface ICardContentWrapperProps {
  children: ReactElement;
}

const CardContentWrapper = ({ children }: ICardContentWrapperProps) => {
  return (
    <div className="row">
      <div className="col-xl-12 mb-4">
        {children}
      </div>
    </div>
  )
}

export default CardContentWrapper