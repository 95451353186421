import React from "react";
import ListIncomeStatement from "../../components/accounting/incomeStatement/ListIncomeStatement";
import DashboardWrapper from "../../components/DashboardWrapper";
import Breadcrumbs from "../../parts/Breadcrumbs";
import NewIncomeStatement from "../../components/accounting/incomeStatement/NewIncomeStatement";

const Incomestatement = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title="Income statement" />
        {/* body */}
        <NewIncomeStatement />
        {/* <ListIncomeStatement /> */}
      </>
    </DashboardWrapper>
  );
};

export default Incomestatement;
