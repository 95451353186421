import DashboardWrapper from "../../components/DashboardWrapper"
import ComingSoon from "../../components/global/ComingSoon"
import Breadcrumbs from "../../parts/Breadcrumbs"


const Procurement = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Procurement' />
        {/* body */}
        <ComingSoon />
      </>
    </DashboardWrapper>

  )
}

export default Procurement