/* eslint-disable array-callback-return */
import React, { useState } from 'react'
import AppModal from '../../components/AppModal'
import DashboardWrapper from '../../components/DashboardWrapper'
import AddStory from '../../components/mobile_app/stories/AddStory'
import StoriesTable from '../../components/mobile_app/stories/StoriesTable'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Stories = () => {

  const [isOpen, setIsOpen] = useState(false);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  return (
    <>
      <AppModal isOpen={isOpen} closeModal={handleModalClick} title="Add new story">
        <AddStory closeModal={handleModalClick} />
      </AppModal>
      <DashboardWrapper>
        <>
          <Breadcrumbs title='Inspirational stories' />
          {/* <!-- main body --> */}
          <StoriesTable />

        </>

      </DashboardWrapper>
    </>
  )
}

export default Stories