import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { addActivityPlanDefaultData, ERRORMESSAGE, errorToastOptions, priorityData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import { getFormData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputDate from '../../global/InputDate';
import InputSelect from '../../global/InputSelect';
import Textarea from '../../global/Textarea';

interface IAddActivityPlanProps {
  onCancel: () => void;
  refresh: () => void;
  id: number;
}

const AddActivityPlan = ({ onCancel, refresh, id }: IAddActivityPlanProps) => {
  const [activityPlan, setActivityPlan] = useState(addActivityPlanDefaultData);
  const [formLoading, setLoading] = useState(false);
  const validator = new Validator();

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setActivityPlan({ ...activityPlan, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setActivityPlan({ ...activityPlan, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setActivityPlan({ ...activityPlan, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setActivityPlan({ ...activityPlan, ...{ [name]: { error: true, data: value } } });
    }
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(activityPlan, setActivityPlan)) {
      setLoading(true);

      const data = getFormData(activityPlan);
      const newData = { ...data, ...{ activity_id: id } };

      // console.log(data); return;

      try {
        const newProject = await doCreate('projects/activity-plans/create', newData);
        if (newProject.data.success === true) {
          toast.success('New Activity plan created successfully', successToastOptions);

          refresh(); // refresh the hook store


          setTimeout(() => {
            onCancel();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(newProject.data.data, newProject.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }

    }
  }

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='title' label='Plan Tilte' handleChange={handleChange} value={activityPlan.title.data} error={activityPlan.title.error} errorMessage="Please provide the plan title" required />
        </div>
        <div className="col-sm-6">
          <InputSelect data={priorityData} label="Priority" name='priority' id='priority' required handleChange={handleSelectChange} value={activityPlan.priority.data} error={activityPlan.priority.error} errorMessage="Please select the plan priority" />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputDate name='start_date' label='Start Date' handleChange={handleSelectChange} value={activityPlan.start_date.data} error={activityPlan.start_date.error} errorMessage="Please select the activity start date" required noLimit />
        </div>

        <div className="col-sm-6">
          <InputDate name='end_date' label='End Date' handleChange={handleSelectChange} value={activityPlan.end_date.data} error={activityPlan.end_date.error} errorMessage="Please select the activity end date" required noLimit />
        </div>

      </div>


      <div className="form-row">
        <div className="col-sm-6">
          <Textarea handleChange={handleChange} value={activityPlan.description.data} error={activityPlan.description.error} errorMessage="Please provide the plan description" name='description' required label='Description' />
        </div>
      </div>


      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  )
}

export default AddActivityPlan