import React from 'react'
import { Link } from 'react-router-dom'

interface IParents {
  link: string;
  title: string;
}

interface IBreadcrumbsProps {
  title: string;
  parents?: Array<IParents>
}

const Breadcrumbs = ({ title, parents }: IBreadcrumbsProps) => {
  return title === '' ? null : (
    <div className="page-title">
      <div className="row">
        <div className="col">
          <h4 className="mb-0">{title}</h4>
        </div>
        <div className="col">
          <ol className="breadcrumb pt-0 pr-0 float-left float-sm-right">
            <li className="breadcrumb-item">
              <Link to="/" className="default-color">Home</Link>
            </li>

            {parents?.map(({ title, link }) => <li key={title} className="breadcrumb-item">
              <Link to={link} className="default-color">{title}</Link>
            </li>)}


            <li className="breadcrumb-item active">{title}</li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumbs