import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { doGet } from '../api'
import { RootState } from '../redux/app/store';
import { setActiveAnnouncements, setAnnouncements, setSelectedAnnouncemt } from '../redux/reducer/generalReducers';

const useAnnouncements = () => {
  const [loading, setLoading] = useState(false);
  const {
    activeAnnouncement, anouncements, selectedAnnouncement
  } = useSelector(
    (state: RootState) => state.general,
  );

  const dispatch = useDispatch();

  const getAnnouncement = () => {
    if (anouncements.length > 0) return;
    setLoading(true);
    doGet('notice').then((response) => {
      if (response.data.data !== null) {
        dispatch(setAnnouncements(response.data.data));
      }
    }).catch((err) => {

    }).finally(() => {
      setLoading(false);
    })
  }

  const refresh = () => {
    setLoading(true);
    doGet('notice').then((response) => {
      if (response.data.data !== null) {
        dispatch(setAnnouncements(response.data.data));
      }
    }).catch((err) => {

    }).finally(() => {
      setLoading(false);
    })
  }

  const getSelectedAnnouncement = (id: number) => {
    setLoading(true);
    doGet(`notice/${id}`).then((response) => {
      if (response.data.data !== null) {
        dispatch(setSelectedAnnouncemt(response.data.data));
      }
    }).catch((err) => {

    }).finally(() => {
      setLoading(false);
    })
  }

  const getActive = () => {
    if (Object.keys(activeAnnouncement).length > 0) return;
    setLoading(true);
    doGet('notice/active').then((response) => {
      if (response.data.data !== null) {
        dispatch(setActiveAnnouncements(response.data.data));
      }
    }).catch((err) => {

    }).finally(() => {
      setLoading(false);
    })
  }
  return { loading, getActive, activeAnnouncement, anouncements, getAnnouncement, refresh, selectedAnnouncement, getSelectedAnnouncement }
}

export default useAnnouncements