import React from 'react'
import ListActivityTeamRoles from '../../components/settings/activities/roles/ListActivityTeamRoles'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const ActivityTeamRoles = () => {
  return (
    <SettingsWrapper>
      <>
        <Breadcrumbs title='Team roles' />
        <ListActivityTeamRoles />

      </>
    </SettingsWrapper>
  )
}

export default ActivityTeamRoles