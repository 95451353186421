import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { ROUTES } from '../routes/routes'

const PayrollSideBar = () => {
  const location = useLocation();

  // const isDev = process.env.NODE_ENV === 'development';
  return (
    <div className='card card-statistics h-100 col-xs-12 col-md-3 px-2 py-3 mb-10'>
      <div className='card-body'>
        <p className='text-muted'>Payroll Management</p>
        <ul className="nav navbar-nav settings_links">
          <li className={`${location.pathname === ROUTES.PAYROLLMANAGEMENT && 'active'}`}>
            <NavLink to={ROUTES.PAYROLLMANAGEMENT}><i className="fa fa-list-ul"></i><span className="ml-2">List Payroll </span></NavLink>
          </li>
          <li className={`${location.pathname === ROUTES.GENERATEPAYROLL && 'active'}`}>
            <NavLink to={ROUTES.GENERATEPAYROLL}><i className="fa fa-database"></i><span className="ml-2">Generate Payroll </span></NavLink>
          </li>

        </ul>
      </div>
    </div>
  )
}

export default PayrollSideBar