import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes/routes";
import { InputDateRange } from "../global/InputDate";

const GenerateReportsViewHeader = () => {
  return (
    <div className="px-4">
      <div className="row align-items-center mx-0">
        {/* datepicker with generate button */}
        <div className="col-xs-12 col-md-8">
          <div className="row justify-content-between align-items-center">
            <InputDateRange />
            <div className="row flex-column">
              {/* <label style={{ visibility: 'hidden' }}>Button</label>
              <button type='button' className='btn btn-sm btn-primary'>Generate</button> */}
            </div>
          </div>
        </div>
        {/* export buttons */}

        {/* <div className='col-xs-12 col-md-3'>
          <div className="row align-items-end flex-column">
            <label>Export</label>
            <div className="row justify-content-end align-items-center">
              <Link to={ROUTES.DASHBOARD} className="btn btn-sm btn-primary mr-2">
                <i className='fa fa-file-excel-o' />
              </Link>
              <Link to={ROUTES.DASHBOARD} className="btn btn-sm btn-secondary">
                <i className='fa fa-print' />
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default GenerateReportsViewHeader;
