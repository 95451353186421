import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { stockGet } from '../api/stocks';
import { RootState } from '../redux/app/store';
import { setBrands, setFormattedBrand, setSelectedBrand } from '../redux/reducer/stocks';
import { formatDepartmentSelectData } from '../utils/helpers';

const useBrands = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    brands, formattedBrand, selectedBrand
  } = useSelector(
    (state: RootState) => state.stocks,
  );

  useEffect(() => {

    if (brands.length > 0) {
      setLoading(false);
      return;
    }

    stockGet('brand').then((response) => {
      if (response.status === 200) {
        dispatch(setBrands(response.data.data));
        dispatch(setFormattedBrand(formatDepartmentSelectData(response.data.data)));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    setLoading(true);
    stockGet('brand').then((response) => {
      if (response.status === 200) {
        dispatch(setBrands(response.data.data));
        dispatch(setFormattedBrand(formatDepartmentSelectData(response.data.data)));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getBrand = (id: number) => {
    setLoading(true);
    stockGet('brand/' + id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedBrand(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return { brands, loading, refresh, formattedBrand, getBrand, selectedBrand }
}

export default useBrands