import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../../api';
import { assetPaymentDefaultData, errorToastOptions, successToastOptions } from '../../../../constants';
import { formatErrorMessage } from '../../../../helpers';
import useAccounts from '../../../../hooks/useAccounts';
import Validator from '../../../../utils/Validator';
import Input from '../../../global/Input';
import InputDate from '../../../global/InputDate';
import InputSelect from '../../../global/InputSelect';
import Textarea from '../../../global/Textarea';

interface IEditPaymentProps {
  closeModal: () => void;
  id: number;
}

const EditPayment = ({ closeModal, id }: IEditPaymentProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [payment, setPayment] = useState(assetPaymentDefaultData);
  const { formattedIncomeAccounts } = useAccounts();

  const validator = new Validator();

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setPayment({ ...payment, ...{ [name]: { ...payment[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setPayment({ ...payment, ...{ [name]: { ...payment[name], ...{ error: true, data: value } } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setPayment({ ...payment, ...{ [name]: { ...payment[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setPayment({ ...payment, ...{ [name]: { ...payment[name], ...{ error: true, data: value } } } });
    }
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(payment, setPayment)) {
      setFormLoading(true);

      const data = {
        account_name: payment.account_name.data,
        // accode_code: account.account_code.data,
        account_no: payment.account_no.data,
        description: payment.description.data,
        account_sub_category_id: payment.account_sub_category_id.data,
        opening_balance: payment.opening_balance.data,
      }

      doCreate('account-charts/create', data).then((response) => {
        if (response.data.success === true) {
          toast.success('New asset payment has been recorded', successToastOptions);


          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).finally(() => {
        setFormLoading(false);
      })
    } else {
      console.log(payment);
    }
  }


  return (
    <form>
      <div className="form-row">
        <div className="col-sm-12 col-md-6">
          <InputDate label="Transaction date" id='transaction_date' name='transaction_date' required handleChange={handleSelectChange} />
        </div>
        <div className="col-sm-12 col-md-6">
          <Input handleChange={handleChange} name="amount" label='Amount' type='number' required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-12 col-md-6">
          <InputSelect data={formattedIncomeAccounts} label="Cash account" id='account_chart_id' name='account_chart_id' required handleChange={handleSelectChange} />
        </div>
        <div className="col-sm-12 col-md-6"></div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Textarea name="narrative" handleChange={handleChange} label="Narrative" required />
        </div>

      </div>


      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn btn-primary' onClick={handleSubmit}>{formLoading ? "Updating..." : "Update"}</button>
      </div>
    </form>
  )
}

export default EditPayment