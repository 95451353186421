import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { updateFunFact } from '../../../api/mobile_app';
import { errorToastOptions, funFactDefaultData, successToastOptions } from '../../../constants'
import { formatErrorMessage } from '../../../helpers';
import useFunFacts from '../../../hooks/useFunFacts';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input'
import TextEditor from '../../global/TextEditor';

interface IAddFunFactProps {
  closeModal: () => void
  id: number
}

const EditFunFact = ({ closeModal, id }: IAddFunFactProps) => {
  const { refresh, selectedFunFact, getSelectedFunFact } = useFunFacts();
  const [funFact, setFunFact] = useState(funFactDefaultData);
  const [loading, setLoading] = useState(false);

  const validator = new Validator();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedFunFact(Number(id)), [id]);

  const formNames = ['description', 'url', 'title'];

  useEffect(() => {
    let funFactData = funFactDefaultData;
    if (!loading) {
      for (let x of formNames) {
        funFactData = { ...funFactData, ...{ [x]: { ...funFactData[x], ...{ error: false, data: selectedFunFact[x] } } } };
      }
      setFunFact(funFactData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setFunFact({ ...funFact, ...{ [name]: { ...funFact[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setFunFact({ ...funFact, ...{ [name]: { ...funFact[name], ...{ error: true, data: value } } } });
    }
  };

  const handleEditorChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setFunFact({ ...funFact, ...{ [name]: { ...funFact[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setFunFact({ ...funFact, ...{ [name]: { ...funFact[name], ...{ error: true, data: value } } } });
    }
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(funFact, setFunFact)) {
      setLoading(true);
      const data = {
        title: funFact.title.data,
        url: funFact.url.data,
        description: funFact.description.data,
      }

      updateFunFact(data, id).then((response) => {
        if (response.data.success === true) {
          toast.success('Fun Fact successfully updated', successToastOptions);
          refresh();

          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).finally(() => {
        setLoading(false);
      })
    }

  }

  return (
    <form>
      <Input label="Title" name="title" handleChange={handleChange} value={funFact.title.data} error={funFact.title.error} errorMessage="Please enter the fun fact title" />
      <Input label="Link to funFact" name="url" handleChange={handleChange} value={funFact.url.data} error={funFact.url.error} errorMessage="Please provide the link to the fun fact" />
      <div className="form-group">
        <label htmlFor="title">Description</label>
        <TextEditor handleChange={handleEditorChange} name="description" id="description" value={funFact.description.data} />
        <small id="description" className="form-text text-muted">Please enter the description here</small>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={loading} className='btn  btn-info' onClick={handleSubmit}>
          {loading ? "Updating..." : "Update"}
        </button>
      </div>
    </form>
  )
}

export default EditFunFact