import { useEffect, useState } from 'react';
import { useFilePicker } from 'use-file-picker';
import { uploadAsset } from '../../api';
import UserAvatar from '../../assets/images/male.jpg';
import './imagePicker.css';

interface IMagePickerProps {
  handleChange: (e: any) => void;
  imageUrl?: string;
}

const ImagePicker = ({ handleChange, imageUrl }: IMagePickerProps) => {
  const [selectedFile, setSelectedFile] = useState({});
  const [openFileSelector, { filesContent, loading, plainFiles, }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',

  });


  useEffect(() => {
    return setSelectedFile(plainFiles[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (typeof selectedFile != 'object') {
      const formdata = new FormData();
      formdata.append('file', plainFiles[0]);
      uploadAsset(formdata).then((response) => {
        setSelectedFile({});
        handleChange(response.data.data);
      }).catch((err) => {
        console.log(err);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plainFiles]);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <div className='profile-pic imagePicker-container'>
        <figure onClick={() => openFileSelector()}>
          <img src={filesContent.length > 0 ? filesContent[0]?.content : imageUrl ? imageUrl : UserAvatar} alt='' className='img-thumbnail img-cirle' />
          <figcaption>
            <i className="fa fa-2x fa-camera" style={{ right: '50px' }}></i>
          </figcaption>

        </figure>

      </div>

    </>
  )
}

export default ImagePicker